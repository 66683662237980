import * as React from 'react';
import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';
import { smpAPI } from '../../api/smpAPI';

import '../../css/loginStyle.css';

//Images
import Logo from '../../assets/images/logo.png';
import SideImage from '../../assets/images/RegEdm.jpg';

//Google Login
import {LoginSocialGoogle, IResolveParams} from 'reactjs-social-login';
import {GoogleLoginButton} from 'react-social-login-buttons';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, loginUser, tokenLoginUser } from '../../redux/userActions'; // Import actions

//Onboard Component
import OnBoard from '../userComponent/onboardComponent/onboardComponent';

//Mobile?
import {isMobile} from 'react-device-detect';

//Privacy Policy
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function Register() {

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    //Google Signin responses
    const [name, setName] = React.useState('');
    const [image, setImage] = React.useState('');

    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    //Persistent Login
    useEffect(() => {

        const token = localStorage.getItem('AlliesAccessToken');
        if (token) {
            
            //Check the token
            dispatch(tokenLoginUser(token));
        }
    }, []);

    //Register the user
    const handleRegistration = (access_token, name, email, password, image) => {

        //Register the user
        dispatch(registerUser(access_token, name, email, password, image));

    };

    //When the user clicks on the Login button
    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (email === '') {
            Swal.fire({
                icon: 'error',
                title: 'Email error',
                text: 'Email field is empty, please enter your email',
            });
            return;
        }
        else if (password === '') {
            Swal.fire({
                icon: 'error',
                title: 'Password error',
                text: 'Password field is empty, please enter your password',
            });
            return;
        }
        else if (!privacyPolicyAccepted) {
            Swal.fire({
                icon: 'error',
                title: 'Privacy Policy error',
                text: 'Please accept the Privacy Policy',
            });
            return;
        }
        else {
            handleRegistration("", name, email, password, image);
        }
    };

    if (!currentUser) {

        if (isMobile) {

            //Render the Login page
            return (
                <div className="containerMobileRegister">
                    <div className="right-column">
                        <img className='logo' src={Logo} />
                        <div className='right-column-box'>
                            <div className='titleDiv'>
                                <span>Welcome to CommUnity Owners! 👋🏻</span>
                            </div>
                            <div className='centerText'>
                                <div className='title'>
                                    <span>The Edmonton Live Monopoly Experience</span>
                                </div>
                                <div className='subtitle'>
                                    <span>Accumulate Deeds on Edmonton Businesses</span>
                                </div>
                            </div>
                            <hr />
                            <div className='subtitleDiv'>
                                <span>Sign-up here</span>
                            </div>
                            <LoginSocialGoogle
                                client_id={'970184735303-4q2gjf5jbf26lpei8v11ns5c84fambn3.apps.googleusercontent.com'}
                                onResolve={({ provider, data }) => {

                                    //Register the user
                                    handleRegistration(data.access_token, data.name, data.email, '', data.picture);
                                }}
                                onReject={err => {
                                    console.log("Error");
                                    console.log(err);
                                }}
                                scope="profile email"
                            >
                                <GoogleLoginButton className='googleButton'/>
                            </LoginSocialGoogle>
                            <div className='subtitleDiv'>
                                <span>Or</span>
                            </div>
                            <div className='inputBox'>
                                <TextField
                                    id="email"
                                    label="Email"
                                    color='secondary'
                                    className='input'
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className='inputBox'>
                                <TextField
                                    id="password"
                                    label="Password"
                                    type="password"
                                    color='secondary'
                                    className='input'
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="linksPanel">
                                <div className='forgotPasswordBox'>
                                    <span><a href="/forgotPassword">Forgot Password?</a></span>
                                </div>
                                <div className='forgotPasswordBox'>
                                    <span><a href="/login">Login Here</a></span>
                                </div>
                            </div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="secondary"
                                        onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)}
                                    />
                                }
                                label={<span>I accept the <a href="/privacy-policy">Privacy Policy</a></span>}
                            />
                            <div className='buttonDiv'>
                                <button className='button' onClick={handleSubmit}>Register</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        else {

            //Render the Login page
            return (
                <div className="container">
                    <div className="left-column">
                        <img className='image' src={SideImage} />
                    </div>
                    <div className="right-column">
                        <img className='logo' src={Logo} />
                        <div className='right-column-box'>
                            <div className='titleDiv'>
                                <span>Welcome to CommUnity Owners! 👋🏻</span>
                            </div>
                            <div className='centerText'>
                                <div className='title'>
                                    <span>The Invest in Edmonton Platform</span>
                                </div>
                                <div className='subtitle'>
                                    <span>Own shares of your favorite local business</span>
                                </div>
                            </div>
                            <hr />
                            <div className='subtitleDiv'>
                                <span>Sign-up here</span>
                            </div>
                            <LoginSocialGoogle
                                client_id={'970184735303-4q2gjf5jbf26lpei8v11ns5c84fambn3.apps.googleusercontent.com'}
                                onResolve={({ provider, data }) => {

                                    //Register the user
                                    handleRegistration(data.access_token, data.name, data.email, '', data.picture);
                                }}
                                onReject={err => {
                                    console.log("Error");
                                    console.log(err);
                                }}
                                scope="profile email"

                            >
                                <GoogleLoginButton className='googleButton'/>
                            </LoginSocialGoogle>
                            <div className='subtitleDiv'>
                                <span>Or</span>
                            </div>
                            <div className='inputBox'>
                                <TextField
                                    id="email"
                                    label="Email"
                                    color='secondary'
                                    className='input'
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className='inputBox'>
                                <TextField
                                    id="password"
                                    label="Password"
                                    type="password"
                                    color='secondary'
                                    className='input'
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="secondary"
                                            onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)}
                                        />
                                    }
                                    label={<span>I accept the <a href="https://invest.co-owners.ca/terms_of_service.html">Terms of Service</a></span>}
                                />
                            </div>
                            <div className="linksPanel">
                                <div className='forgotPasswordBox'>
                                    <span><a href="/forgotPassword">Forgot Password?</a></span>
                                </div>
                                <div className='forgotPasswordBox'>
                                    <span><a href="/login">Login Here</a></span>
                                </div>
                            </div>
                            
                            <div className='buttonDiv'>
                                <button className='button' onClick={handleSubmit}>Register</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
    else {
        //Render the Dashboard page
        return (
            <OnBoard />
        );
    }
}

export default Register;