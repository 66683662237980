import { DataGrid } from '@mui/x-data-grid';

import '../../css/dashBoard.css'

function eventAttendance (props) {

    //Get the Scanned QR Count
    const confirmations = props.confirmations;

    const columns = [
        { field: 'name', headerName: 'Attendees', width: 200 },
    ];
    
    const rows = confirmations.map((confirmation) => {
        return { id: confirmation._id, name: confirmation.partnerName };
    });
    
    return (
            <DataGrid 
                columns={columns} 
                rows={rows}
                rowHeight={20}
                pageSizeOptions={[10]}
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                }}
                sx={{
                    border: 0,
                    color: 'white',
                    fontWeight: 'bold',
                    '&, [class^=MuiDataGrid]': { border: 'none' },
                }} 
                localeText={{noRowsLabel: 'No Attendees'}} // Change "No rows" to "No notifications"
           />
    );
}

export default eventAttendance;