import React, { useState, useEffect, useRef } from "react";

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { tokenLoginUser } from '../../redux/userActions'; // Import actions

//Navigate
import { useNavigate, Link } from 'react-router-dom';

//Menu Component
import Menu from '../userComponent/menuComponent/menuComponent';
import Loading from "../userComponent/loadingComponent/loadingComponent";

//Icons
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { FaFile } from "react-icons/fa";

//API
import { smpAPI } from '../../api/smpAPI';

//SweelAlert
import Swal from 'sweetalert2';

//Material UI
import TextField from '@mui/material/TextField';

import '../../css/managers.css';

function Managers() {

    const inputRef = useRef();

    const [managers, setManagers] = useState(null);

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    //Navigate
    const navigate = useNavigate();

    useEffect(() => {
            
            const getUser = async () => {
                const token = localStorage.getItem('AlliesAccessToken');
                if (token) {
                    
                    //Check the token
                    dispatch(tokenLoginUser(token));
                }
                else {
                    navigate('/login');
                }
            }
    
            //If the user is not loaded, load the user
            if (currentUser === null) {
            
                const fetchUser = async () => {
                    await getUser();
                };
    
                fetchUser();
            }
            else {

                const getManagers = async () => {
                    const response = await smpAPI.getManagers(currentUser.ownership);
                    setManagers(response.data.users);
                }
        
                getManagers();
            }
    }, [currentUser]);

    //Navigation Title Panel
    const panelTitle = () => {
        return (
            <div className="dashboard-titlePanel">
                <span>
                    <IoIosArrowDroprightCircle />
                </span>
                <span className="dashboard-title">
                    Managers
                </span>
            </div>
        );
    }

    /**
     * Takes care of Add Manager
     */
    const addManager = async () => {

        const email = inputRef.current.value;
        
        const response = await smpAPI.addManager(currentUser.ownership, email);

        if (response.data.message === "Manager added") {

            Swal.fire({
                title: 'Manager Added!',
                text: 'The manager has been added to the team',
                icon: 'success',
                confirmButtonText: 'Great!'
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });
        }
        else {
            Swal.fire({
                title: 'Error!',
                text: 'Email not found',
                icon: 'error',
                confirmButtonText: 'Try Again'
            });
        }
    }

    //Remove Manager
    const removeManager = async (id) => {

        const response = await smpAPI.removeManager(currentUser.ownership, id);
    
        if (response.data.message === "Manager removed") {
            
            Swal.fire({
                title: 'Manager Removed!',
                text: 'The manager has been removed from the team',
                icon: 'success',
                confirmButtonText: 'Great!'
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });
        }
        else {
            Swal.fire({
                title: 'Error!',
                text: 'Manager not found',
                icon: 'error',
                confirmButtonText: 'Try Again'
            });
        }
    }

    //Managers Panel
    function renderManagers() {
        
        return (
            <div className="managersPanel">
                <h2>Team Members</h2>
                <div className="managersListPanel">
                    { managers.filter(manager => manager.active).map((manager) => {
                        return (
                            <div className="managerDetailPanel" key={manager._id}>
                                <div className="avatar">
                                    <img className="avatar" src={manager.profileImage} alt="Manager" />
                                </div>
                                <div className="name">
                                    { manager.name }
                                </div>
                                <div className="role">
                                    { manager.role.charAt(0).toUpperCase() + manager.role.slice(1) }
                                </div>
                                { manager.role === 'owner' ?
                                    null :
                                    <div className="buttons-panel">
                                        <button onClick={(e) => removeManager(manager._id)}>
                                            Remove Manager
                                        </button>
                                    </div>
                                }
                            </div>
                        );
                    })}
                </div>
                <div className="addManagerPanel">
                    <div className='inputBox'>
                        <TextField
                            inputRef={inputRef}
                            id="Email"
                            label="Enter New Manager's Email"
                            color='success'
                            className='input'
                        />
                        <button onClick={addManager}>
                            Add Manager
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    //Panel to display content depending on if the user prefers the IPO Panel or Dashboard Panel
    function ContentPanel() {

        return (
            <div className="dashboard">
                <Menu />
                <Content /> 
            </div>
        );
    }

    //Content Panels
    function Content() {

        return (
            <div className="dashboard-content">
                { panelTitle() }
                <div className="dashboard-content-panel">
                    { renderManagers() }
                </div>
            </div>            
        );
      }

    
    if ((managers !== null) && (managers !== undefined)) {

        if (currentUser.role === 'admin') {
            return (
                <div>
                    <ContentPanel />
                </div>
            );
        }
        else {
            navigate('/');
        }
    }
    else {
        return (
            <Loading title="Set Managers" />
        )
    }
}

export default Managers;
