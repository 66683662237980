import * as React from 'react';
import { useState, useEffect } from "react";
import Swal from 'sweetalert2';

import '../../css/businessIPO.css';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { tokenLoginUser } from '../../redux/userActions'; // Import actions
import { getBusiness } from '../../redux/businessActions';

//Icons
import { IoIosArrowDroprightCircle, IoMdPerson } from "react-icons/io";

//API
import { smpAPI } from '../../api/smpAPI';

//Components
import Menu from '../userComponent/menuComponent/menuComponent';
import UserRevokeTable from './userLookupComponent/userRevokeTable';

//Navigate
import { useNavigate } from 'react-router-dom';

import Loading from '../userComponent/loadingComponent/loadingComponent'

function RevokeShares() {

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);
    const currentBusiness = useSelector((state) => state.business.currentBusiness);

    const [partners, setPartners] = useState(null);

    //Navigate
    const navigate = useNavigate();

    useEffect(() => {

        const getUser = async () => {
            const token = localStorage.getItem('AlliesAccessToken');
            if (token) {
                
                //Check the token
                dispatch(tokenLoginUser(token));
            }
            else {
                navigate('/login');
            }
        }

        const getPartners = async () => {

            const response = await smpAPI.getPartnerships(currentBusiness._id);

            if (response.status === 200) {
                setPartners(response.data.partnerships);
            }
        }

        //If the user is not loaded, load the user
        if (currentUser === null) {
        
            const fetchUser = async () => {
                await getUser();
            };

            fetchUser();
        } else {

            if (currentUser.ownership !== null) {
                //Get the Business
                dispatch(getBusiness(currentUser.ownership));

                if (currentBusiness !== null) {
                    //Get the Partners
                    getPartners();
                }
            }
            else {
                navigate('/login');
            }
        }
    }, [currentUser, currentBusiness]);

    //Navigation Title Panel
    const panelTitle = () => {
        return (
            <div className="dashboard-titlePanel">
                <span>
                    <IoIosArrowDroprightCircle />
                </span>
                <span className="dashboard-title">
                    <span style={{cursor: 'pointer'}} >
                        Revoke Shares
                    </span>
                </span>
            </div>
        );
    }

    /** User Lookup Panel **/
    const userLookupPanel = () => {

        return (
            <div className="background-panel">
                <div className='background-panel-title'>
                    Current Partners
                </div>
                <div className='ipolist-panel'>
                    <div className='partnerPanel'>
                        <UserRevokeTable business={currentBusiness} partners={partners}/>
                    </div>
                </div>
            </div>
        )
    }


    //Panel to display content depending on if the user prefers the IPO Panel or Dashboard Panel
    function ContentPanel() {

        return (
            <div className="dashboard">
                <Menu />
                <Content /> 
            </div>
        );
    }

    //Content Panels
    function Content() {

        return (
            <div className="dashboard-content">
                { panelTitle() }
                { userLookupPanel() }
            </div>     
        );
    }

    if (partners !== null){

        return (
            <div>
                <ContentPanel />
            </div>
        );
        
    }
    else {
        return (
            <div className="dashboard-content">
                 <Loading title="Loading Business Dashboard" />
            </div>
           
        )
    }
}

export default RevokeShares;