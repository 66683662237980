import { DataGrid } from '@mui/x-data-grid';

import Tooltip from '@mui/material/Tooltip';
import '../../../css/dashBoard.css'


function businessCommentsComponent (props) {

    //Get the comment data
    var data = [];
    
    //Only seed if the data is not null
    if ((props.comments !== undefined) && (props.comments !== null)) {
        data = props.comments;
    }

    const columns = [
        { field: 'user', headerName: 'User', width: 100, renderCell: (params) => <img src={params.value} className="avatarComments"/> },
        { field: 'comment', headerName: 'Comment', width: '100%', renderCell: (params) =>  (
            <Tooltip title={params.value} >
                <span className="table-cell-trucate">{params.value}</span>
            </Tooltip>
           ), 
        },
    ];    

    const rows = data.map((comment) => {

        return {
            id: comment._id,
            user: comment.avatar,
            comment: comment.comment
        }
    });
   
    return (
            <DataGrid 
                columns={columns} 
                rows={rows}
                rowHeight={30}
                initialState={{
                    pagination: { paginationModel: { pageSize: 5 } },
                }}
                pageSizeOptions={[5]}
                sx={{
                    border: 0,
                    color: 'white',
                    fontWeight: 'bold',
                    '&, [class^=MuiDataGrid]': { border: 'none' },
                }} 
                localeText={{noRowsLabel: 'No Comments'}} // Change "No rows" to "No notifications"
           />
    );
}

export default businessCommentsComponent;