import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

//Navigate
import { useNavigate } from 'react-router-dom';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { tokenLoginUser } from '../../redux/userActions'; // Import actions
import { getBusiness } from '../../redux/businessActions';

//Menu Component
import Menu from '../userComponent/menuComponent/menuComponent';
import Loading from '../userComponent/loadingComponent/loadingComponent';

//Icons
import { IoIosArrowDroprightCircle, IoMdPerson } from "react-icons/io";

//QR Reader
import { QrReader } from 'react-qr-reader';

//isMobile
import { isMobile } from 'react-device-detect';

function WebcamScanner () {
    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);
    const currentBusiness = useSelector((state) => state.business.currentBusiness);
  
    const navigate = useNavigate();

    const [data, setData] = useState(null);
    const [deviceId, setDeviceId] = useState(null);


    useEffect(() => {

        const getUser = async () => {
            const token = localStorage.getItem('AlliesAccessToken');
            if (token) {
                
                //Check the token
                dispatch(tokenLoginUser(token));
            }
            else {
                navigate('/login');
            }
        }

        //If the user is not loaded, load the user
        if (currentUser === null) {
        
            const fetchUser = async () => {
                await getUser();
            };

            fetchUser();
        } else {

            if ((currentBusiness === null) || (currentBusiness === undefined)) {
                //Get the Business
                dispatch(getBusiness(currentUser.ownership));
            }
        }
    }, [currentUser, currentBusiness]);

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const backCamera = devices.find(device => device.kind === 'videoinput' && device.label.toLowerCase().includes('back'));
                if (backCamera) {
                    setDeviceId(backCamera.deviceId);
                }
            });
    }, []);

    //Navigation Title Panel
    const panelTitle = () => {
        return (
            <div className="dashboard-titlePanel">
                <span>
                    <IoIosArrowDroprightCircle />
                </span>
                <span className="dashboard-title">
                    Scan Partner QR Code
                </span>
            </div>
        );
    }

    //Panel to display content depending on if the user prefers the IPO Panel or Dashboard Panel
    function ContentPanel() {

        return (
            <div className="dashboard">
                <Menu />
                <Content /> 
            </div>
        );
    }

    //Content Panels
    function Content() {

        if (isMobile) {
            return (
                <div className="dashboard-content">
                    { panelTitle() }
                    <div>
                    <QrReader
                        onResult={(result, error) => {
                        if (!!result) {
                            setData(result?.text);
                        }
                        }}
                        style={{ width: '100%' }}
                        constraints={{
                            facingMode: 'environment'
                        }}
                    />
                    </div>
                    <p>{data}</p>
                </div>            
            );
        }
        else {
            return (
                <div className="dashboard-content">
                    { panelTitle() }
                    <div>
                    <QrReader
                        onResult={(result, error) => {
                        if (!!result) {
                            setData(result?.text);
                        }
                        }}
                        style={{ width: '100%' }}                        
                    />
                    </div>
                    <p>{data}</p>
                </div>            
            );
        }
    }

    if ((currentBusiness !== null) && (data === null)) {

        return (
            <div>
                <ContentPanel />
            </div>
        );
    }
    else if ((currentBusiness !== null) && (data !== null)) {

        //Check to see if data has a 'ScanQR' string in it
        if (data.includes('/scanQR/')) {

            //Extract out the domain
            const scanQR = data.split('/scanQR/');
            
            navigate('/scanQR/' + scanQR[1]);
        }
    }
    else {
        return (
            <Loading title="Loading Business Dashboard" />
        )
    }
}

export default WebcamScanner;