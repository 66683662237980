import { smpAPI } from '../api/smpAPI';

//SweetAlert
import Swal from 'sweetalert2';

//Business Slice
import { setBusiness, setBusinessLoading, setBusinessError } from './reducers/businessSlice';

//User Slice
import { setUser, setLoading, setError } from './reducers/userSlice';

/*********************** Business Redux *************************/
/**
 * Login User
 * @param {*} businessId 
 * @returns 
 */
export const getBusiness = (businessId) => async (dispatch) => {
        
    try {
        dispatch(setBusinessLoading(true)); // Set loading state to true

        smpAPI.getBusiness(businessId).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 200) {
                    
                dispatch(setBusiness(response.data.business)); // Dispatch action to set current user
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Data error',
                text: error.response.data.message,
            });
            //console.log(error.response.data.message);
        });
    } catch (error) {
        dispatch(setBusinessError(error.message)); // Dispatch action to store error
    } finally {
            dispatch(setBusinessLoading(false)); // Set loading state to false (optional)
    }
};

/**
 * Update Business Month End
 * @param {*} businessId 
 * @param {*} revenue
 * @param {*} expenses
 * @param {*} files
 * @returns 
 */
export const updateBusinessMonthEnd = (businessId, revenue, expenses, files) => async (dispatch) => {
        
    try {
        dispatch(setBusinessLoading(true)); // Set loading state to true

        smpAPI.uploadBusinessMonthEnd(businessId, revenue, expenses, files).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 200) {
                    
                dispatch(setBusiness(response.data.business)); // Dispatch action to set current user
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Data error',
                text: error.response.data.message,
            });
            //console.log(error.response.data.message);
        });
    } catch (error) {
        dispatch(setBusinessError(error.message)); // Dispatch action to store error
    } finally {
            dispatch(setBusinessLoading(false)); // Set loading state to false (optional)
    }
};

/**
 * Process an IPO Partner Approval
 */
export const approveIPOPartner = (businessId, partnerId) => async (dispatch) => {
    
    try {
        dispatch(setBusinessLoading(true)); // Set loading state to true

        smpAPI.approveIPOPartner(businessId, partnerId).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 200) {
                    
                //Get the Business object
                const business = response.data.business;

                //Get the partner username
                const username = response.data.username;

                //Get the message
                const message = response.data.message;

                //Save over the _id
                if ((business._id === null) || (business._id === undefined)) {
                    business._id = business.id;
                }

                //Dispatch the Business
                dispatch(setBusiness(business)); 

                if (message === "Payment successful") {
                    Swal.fire({
                        icon: 'success',
                        title: 'Payment successful',
                        text: 'IPO approved and processed for: ' + username,
                    });
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Payment error',
                        text: 'IPO approved for: ' + username + ' but partner has insufficient funds, system will cancel order in 48 hours if user cannot pay',
                    });
                }                
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Data error',
                text: error.response.data.message,
            });
            //console.log(error.response.data.message);
        });
    } catch (error) {
        dispatch(setBusinessError(error.message)); // Dispatch action to store error
    } finally {
            dispatch(setBusinessLoading(false)); // Set loading state to false (optional)
    }
}

/**
 * Make IPO Shares Public
 */
export const sellIPOShares = (businessId, shares) => async (dispatch) => {

    try {
        dispatch(setBusinessLoading(true)); // Set loading state to true

        smpAPI.sellIPOShares(businessId, shares).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 200) {
                    
                //Get the Business object
                const business = response.data.business;

                //Save over the _id
                if ((business._id === null) || (business._id === undefined)) {
                    business._id = business.id;
                }

                console.log(business);

                //Dispatch the Business
                dispatch(setBusiness(business));  
                
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'IPO public amount set to: ' + business.ipo.ipoPublicShares,
                });
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Data error',
                text: error.response.data.message,
            });
            //console.log(error.response.data.message);
        });
    } catch (error) {
        dispatch(setBusinessError(error.message)); // Dispatch action to store error
    } finally {
            dispatch(setBusinessLoading(false)); // Set loading state to false (optional)
    }
}

/**
 * Validate a QR Code Scan
 * @param {*} business 
 * @returns 
 */
export const validateQRCode = (businessId, partnerId, navigateCallback) => async (dispatch) => {

    try {
        dispatch(setBusinessLoading(true)); // Set loading state to true

        smpAPI.scanQR(businessId, partnerId).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 200) {
                    
                console.log(response);

                if (response.data.message === "Success") {
                    //Get the Business object
                    const business = response.data.business;

                    //Save over the _id
                    if ((business._id === null) || (business._id === undefined)) {
                        business._id = business.id;
                    }

                    console.log(business);

                    //Dispatch the Business
                    dispatch(setBusiness(business));  
                }
                else if (response.data.message === "QR Code not found") {

                    console.log("Not Found");
                    console.log(response.data.message);

                    //Get the Business object
                    const business = response.data.business;

                    //Dispatch the Business
                    dispatch(setBusiness(business));  

                    Swal.fire({
                        icon: 'error',
                        title: 'Data error',
                        text: response.data.message,
                    }).then(() => {
                        navigateCallback('/market');
                    });
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Data error',
                        text: response.data.message,
                    }).then(() => {
                        navigateCallback('/market');
                    });
                }
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Data error',
                text: error.response.data.message,
            });
            //console.log(error.response.data.message);
        });
    } catch (error) {
        dispatch(setBusinessError(error.message)); // Dispatch action to store error
    } finally {
            dispatch(setBusinessLoading(false)); // Set loading state to false (optional)
    }
}

/**
 * Buy Back a partners share
 */
export const buyBackShares = (businessId, partnerId) => async (dispatch) => {

    try {
        dispatch(setBusinessLoading(true)); // Set loading state to true

        smpAPI.buyBackShares(businessId, partnerId).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 200) {
                    
            //Get the Business object
            const business = response.data.business;

            //Save over the _id
            if ((business._id === null) || (business._id === undefined)) {
                business._id = business.id;
            }

            console.log(business);

            //Dispatch the Business
            dispatch(setBusiness(business));  
                
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Shares bought back from partner',
            });
        }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response.data.message,
            });
            //console.log(error.response.data.message);
        });
    } catch (error) {
        dispatch(setBusinessError(error.message)); // Dispatch action to store error
    } finally {
            dispatch(setBusinessLoading(false)); // Set loading state to false (optional)
    }
};

//Update Business
export const updateBusiness = (bid, name, address, city, state, country, latitude, longitude, description, story, website, numEmployees, promoExpense, promoDescription, image, navigate) => (dispatch) => {

    try {
        dispatch(setBusinessLoading(true)); // Set loading state to true

        smpAPI.updateBusiness(bid, name, address, city, state, country, latitude, longitude, description, story, website, numEmployees, promoExpense, promoDescription, image).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 200) {
                    
            //Get the Business object
            const business = response.data.business;

            //Save over the _id
            if ((business._id === null) || (business._id === undefined)) {
                business._id = business.id;
            }

            //Dispatch the Business
            dispatch(setBusiness(business));  
                
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Business has been updated',
            }).then(() => {
                navigate('/');
            });
        }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response.data.message,
            });
            //console.log(error.response.data.message);
        });
    } catch (error) {
        dispatch(setBusinessError(error.message)); // Dispatch action to store error
    } finally {
            dispatch(setBusinessLoading(false)); // Set loading state to false (optional)
    }
}

//Set the Business object
export const setBusinessObject = (business) => (dispatch) => {
    dispatch(setBusiness(business));
}



  