import React, { useState, useEffect } from "react";
import { smpAPI } from '../../api/smpAPI';

import '../../css/dashBoard.css';
import '../../css/admin.css';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { tokenLoginUser } from '../../redux/userActions'; // Import actions

//Navigate
import { useNavigate, Link } from 'react-router-dom';

//Menu Component
import Menu from '../userComponent/menuComponent/menuComponent';
import Loading from "../userComponent/loadingComponent/loadingComponent";

//Icons
import { IoIosArrowDroprightCircle } from "react-icons/io";


function Admin() {

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    //Navigate
    const navigate = useNavigate();

    useEffect(() => {

        const getUser = async () => {
            const token = localStorage.getItem('AlliesAccessToken');
            if (token) {
                
                //Check the token
                dispatch(tokenLoginUser(token));
            }
            else {
                navigate('/login');
            }
        }

        //If the user is not loaded, load the user
        if (currentUser === null) {
        
            const fetchUser = async () => {
                await getUser();
            };

            fetchUser();
        }
    }, [currentUser]);

    //Navigation Title Panel
    const panelTitle = () => {
        return (
            <div className="dashboard-titlePanel">
                <span>
                    <IoIosArrowDroprightCircle />
                </span>
                <span className="dashboard-title">
                    Admin Panel
                </span>
            </div>
        );
    }

    function adminFunctions () {
        
        return (
            <div className="adminPanel">
                <div className="adminRow">
                    <Link to="/businessOnboard">
                        <button className="createBusinessButton">
                            Create Business
                        </button>
                    </Link>
                    <Link to="/approveMonthly">
                        <button className="validateReturnButton">
                            Validate Monthly Returns
                        </button>
                    </Link>
                    <Link to="/setIPO">
                        <button className="setIPOButton">
                            Set IPO
                        </button>
                    </Link>
                </div>
            </div>
        );
    };

    //Panel to display content depending on if the user prefers the IPO Panel or Dashboard Panel
    function ContentPanel() {

        return (
            <div className="dashboard">
                <Menu />
                <Content /> 
            </div>
        );
    }

    //Content Panels
    function Content() {

        return (
            <div className="dashboard-content">
                { panelTitle() }
                <div className="dashboard-content-panel">
                    { adminFunctions() }
                </div>
            </div>            
        );
      }

    if ((currentUser !== null) && (currentUser !== undefined)) {

        if (currentUser.role === 'admin') {
            return (
                <div>
                    <ContentPanel />
                </div>
            );
        }
        else {
            navigate('/');
        }
    }
    else {
        return (
            <Loading title="Loading Admin Panel" />
        )
    }
}

export default Admin;