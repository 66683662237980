import React, { useState, useEffect } from "react";
import { smpAPI } from '../../../api/smpAPI';

import '../../../css/dashBoard.css';
import '../../../css/qrCodeList.css';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { tokenLoginUser } from '../../../redux/userActions'; // Import actions

//Navigate
import { useNavigate } from 'react-router-dom';

//Menu Component
import Menu from '../menuComponent/menuComponent';
import Loading from "../loadingComponent/loadingComponent";

//Icons
import { IoIosArrowDroprightCircle } from "react-icons/io";

//Material UI Pagination
import TablePagination from '@material-ui/core/TablePagination';
import Pagination from '@material-ui/lab/Pagination';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

//SweetAlert
import Swal from 'sweetalert2';

//Modal
import Modal from '@material-ui/core/Modal';

//isMobile
import { isMobile } from 'react-device-detect';

//Google Anayltics
import ReactGA from 'react-ga4';

//Sharing for Desktop
import { 
    FacebookShareButton, FacebookIcon, 
    TwitterShareButton, TwitterIcon,
    EmailIcon, EmailShareButton,  
    RedditIcon, RedditShareButton,
    LinkedinIcon, LinkedinShareButton,
} from 'react-share';


function QRCodeList() {

    const [QRCodes, setQRCodes] = useState(null);

    const [open, setOpen] = React.useState(false);
    const [selectedQRCode, setSelectedQRCode] = React.useState(null);

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    //Navigate
    const navigate = useNavigate();

    //Pagination
    const [page, setPage] = React.useState(1);
    let itemsPerPage;

    //Social Sharing
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [shareUrl, setShareUrl] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [promotion, setPromotion] = useState('');

    if (isMobile) {
        itemsPerPage = 1;
    }
    else {
        itemsPerPage = 5;
    }

    const [openSnackbar, setOpenSnackbar] = useState(false);

    useEffect(() => {

        ReactGA.send({
            hitType: 'pageview',
            page: "/QRList",
            title: 'QR Codes',
        })

        const getUser = async () => {
            const token = localStorage.getItem('AlliesAccessToken');
            if (token) {
                
                //Check the token
                dispatch(tokenLoginUser(token));
            }
            else {
                navigate('/login');
            }
        }

        //If the user is not loaded, load the user
        if (currentUser === null) {
        
            const fetchUser = async () => {
                await getUser();
            };

            fetchUser();
        } else {

            //Get the QR Code
            const getQRCode = async () => {
                try {
                    const response = await smpAPI.getQRCodeList(currentUser.id);

                    if (response.status === 200) {
                        setQRCodes(response.data.qrCodeArray);
                    }
                }
                catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Error loading QR Codes'
                    });
                }
            }

            getQRCode();
        }
    }, [currentUser]);

    //Navigation Title Panel
    const panelTitle = () => {
        return (
            <div className="dashboard-titlePanel">
                <span>
                    <IoIosArrowDroprightCircle />
                </span>
                <span className="dashboard-title">
                    Available QR Codes
                </span>
            </div>
        );
    }

    /*************** Sharing on Desktop ***************/
    const handleShare = (url, business, promotion) => {
        setShareUrl(url);
        setBusinessName(business);
        setPromotion(promotion);
        setShareModalOpen(true);
    };
    
    const handleCloseShareModal = () => {
        setShareModalOpen(false);
    };

    /**************** Qr Code List ********************/
    const handleOpen = (qrCode) => {
        setSelectedQRCode(qrCode);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCopy = (url) => {
        navigator.clipboard.writeText(url);
        setOpenSnackbar(true);
    };
    
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpenSnackbar(false);
    };

    function codeList () {

        if (QRCodes.length === 0) {

            return (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                    <Typography>
                        No QR Codes Available
                    </Typography>
                </div>
            );
        }
        else {

            const startIndex = (page - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            const currentQRCodes = QRCodes.slice(startIndex, endIndex);
        
            if (isMobile) {

                return (
                    <div>
                        <div>
                            {currentQRCodes && currentQRCodes.map((qrCode, index) => (
                                <div className="cardLayout">
                                    <Card key={index} style={{ backgroundColor: '#E3E7D3', margin: '1rem', flex: '1' }} onClick={() => handleOpen(qrCode)}>
                                        <CardHeader
                                            title={qrCode.businessName}
                                            className="cardHeaderStyle"
                                        />
                                        <CardContent>
                                            <img src={qrCode.codeUrl} alt={qrCode.businessName} /> 
                                            <Typography style={{ fontSize: '0.8rem' }}>
                                            {qrCode.description}
                                            </Typography>
                                        </CardContent>
                                            
                                    </Card>
                                    <div className="cardButton">
                                        <Button onClick={() => handleCopy(qrCode.codeUrl)}>
                                            Copy URL
                                        </Button>
                                        <Button onClick={() => {
                                                if (navigator.share) {
                                                    navigator.share({ title: qrCode.businessName, url: qrCode.codeUrl })
                                                } else {
                                                    console.log('Web Share API is not supported in your browser.');
                                                }
                                            }}>
                                            Share
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                            <Pagination count={Math.ceil(QRCodes.length / itemsPerPage)} page={page} onChange={(event, value) => setPage(value)} />
                        </div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <div style={{ backgroundColor: 'white', padding: '1rem' }}>
                                <img src={selectedQRCode?.codeUrl} alt={selectedQRCode?.businessName} style={{ width: '80vw', height: 'auto' }} />
                            </div>
                        </Modal>
                    </div>
                );
            }

            else {

                return (
                    <div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                            {currentQRCodes && currentQRCodes.map((qrCode, index) => (
                                <div className="cardLayout">
                                    <Card key={index} style={{ backgroundColor: '#E3E7D3', margin: '1rem', flex: '1' }} onClick={() => handleOpen(qrCode)}>
                                        <CardHeader
                                            title={qrCode.businessName}
                                            className="cardHeaderStyle"
                                        />
                                        <CardContent>
                                            <img src={qrCode.codeUrl} alt={qrCode.businessName} /> 
                                            <Typography style={{ fontSize: '0.8rem' }}>
                                            {qrCode.description}
                                            </Typography>
                                        </CardContent>
                                            
                                    </Card>
                                    <div className="cardButton">
                                        <Button onClick={() => handleCopy(qrCode.codeUrl)}>
                                            Copy URL
                                        </Button>
                                        <Button onClick={() => handleShare(qrCode.codeUrl, qrCode.businessName, qrCode.description)}>
                                            Share
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <div style={{ backgroundColor: 'white', padding: '1rem' }}>
                                <img src={selectedQRCode?.codeUrl} alt={selectedQRCode?.businessName} style={{ width: '40vw', height: 'auto' }} />
                            </div>
                        </Modal>
                        <Modal
                            open={shareModalOpen}
                            onClose={handleCloseShareModal}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <div style={{ backgroundColor: 'white', padding: '1rem' }}>
                                <h2>Share this QR Code with your network!</h2>
                                <FacebookShareButton className="share-button" url={shareUrl} quote={`Use this QR code for ${promotion} at ${businessName}`}>
                                    <FacebookIcon size={40} round={true} />
                                </FacebookShareButton>
                                <TwitterShareButton className="share-button" url={shareUrl} title={`Use this QR code for ${promotion} at ${businessName}`}>
                                    <TwitterIcon size={40} round={true} />
                                </TwitterShareButton>
                                <EmailShareButton className="share-button" url={shareUrl} subject={`${businessName} QR code!`} body={`Use this QR code for ${promotion} at ${businessName}`}>
                                    <EmailIcon size={40} round={true} />
                                </EmailShareButton>
                                <RedditShareButton className="share-button" url={shareUrl} title={`Use this QR code for ${promotion} at ${businessName}`}>
                                    <RedditIcon size={40} round={true} />
                                </RedditShareButton>
                            </div>
                        </Modal>
                        
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                            <Pagination count={Math.ceil(QRCodes.length / itemsPerPage)} page={page} onChange={(event, value) => setPage(value)} />
                        </div>
                        
                    </div>
                );
            }
        }
    };

    //Panel to display content depending on if the user prefers the IPO Panel or Dashboard Panel
    function ContentPanel() {

        return (
            <div className="dashboard">
                <Menu />
                <Content /> 
            </div>
        );
    }

    //Content Panels
    function Content() {

        if (isMobile) {
            return (
                <div className="dashboard-content">
                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                        <MuiAlert onClose={handleCloseSnackbar} severity="success">
                            Content has been copied to clipboard!
                        </MuiAlert>
                    </Snackbar>
                    { panelTitle() }
                    <div className="qrPanel">
                        { codeList() }
                    </div>
                </div>            
            );
        }
        else {
            return (
                <div className="dashboard-content">
                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                        <MuiAlert onClose={handleCloseSnackbar} severity="success">
                            Content has been copied to clipboard!
                        </MuiAlert>
                    </Snackbar>
                    { panelTitle() }
                    <div className="dashboard-content-panel">
                        { codeList() }
                    </div>
                </div>            
            );
        }
      }

    if (QRCodes !== null) {

        console.log(QRCodes);

        return (
            <div>
                <ContentPanel />
            </div>
        );
    }
    else {
        return (
            <Loading title="Loading QR Codes" />
        )
    }
}

export default QRCodeList;
