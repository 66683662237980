import * as React from 'react';
import Chart from "react-apexcharts";

//isMobile
import { isMobile } from 'react-device-detect';

function mixedGraph(props) {

  const valuation = props.investments;
  const orders = props.orders;

  var duplicate_investment = [];

  //Get the sum of all valuations in the valuations array by each given month
  const groupedByMonth = valuation.reduce((acc, investment) => {  
    investment.historical_prices.forEach(priceObj => {
          const month = new Date(priceObj.date).getMonth();
          const year = new Date(priceObj.date).getFullYear();
        
          if (!acc[month + '-' + year]) {
              acc[month + '-' + year] = 0;
          }
          
          //check if the investment id is already in the duplicate_investment array
          if (!duplicate_investment.includes(investment.businessId + "-" + month + '-' + year)) {
            acc[month + '-' + year] += priceObj.price;
            duplicate_investment.push(investment.businessId + "-" + month + '-' + year);
          }
      });
      
    return acc;
  }, {});

  const valuationArray = Object.values(groupedByMonth);

  // Get the month and year from purchaseDate, which is Date object in the valuations array 
  const monthYearArray = valuation.reduce((acc, investment) => {
      investment.historical_prices.forEach(priceObj => {
          const date = new Date(priceObj.date);
          const monthYear = `${date.toLocaleString('default', { month: 'short' })}-${date.getFullYear()}`;
          if (!acc.includes(monthYear)) {
              acc.push(monthYear);
          }
      });
      return acc;
  }, []);

  // Get unique month-year combinations
  const uniqueMonthYearArray = [...new Set(monthYearArray)];

  var options = {
      series: [
          {
            name: "Valuation",
            data: valuationArray
          }
        ],
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight"
        },
        title: {
          text: "Portfolio Valuation",
          align: "left",
          style: {
            color: '#fff',
          }
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.3
          }
        },
        xaxis: {
          categories: uniqueMonthYearArray
        },
        yaxis: {
          title: {
            text: 'Current Price ($)',
            style: {
              color: '#fff'
            }
          },
          labels: {
            show: true,
            formatter: function (val) {
              return "$" + Number(val).toFixed(2);
            },
            style: {
                colors: '#fff',
                fontSize: '0.8rem',
            },
          },
        }
  };

  if (isMobile) {
    return (
      <div className="app">
          <div className="row">
              <div className="mixed-chart">
                  <Chart
                      options={options}
                      series={options.series}
                      type="line"
                      width="100%"
                  />
              </div>
          </div>
      </div>
    );
  }
  else {
    return (
        <div className="app">
            <div className="row">
                <div className="mixed-chart">
                    <Chart
                        options={options}
                        series={options.series}
                        type="line"
                        width="500"
                    />
                </div>
            </div>
        </div>
    );
  }
}

export default mixedGraph;
