import * as React from 'react';
import { useState, useEffect } from "react";

import '../../css/businessMarket.css';

//Navigate
import { useNavigate } from 'react-router-dom';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { tokenLoginUser } from '../../redux/userActions'; // Import actions
import { getBusiness } from '../../redux/businessActions';

//Menu Component
import Menu from '../userComponent/menuComponent/menuComponent';
import ActivationRevenue from '../businessComponent/activationRevenueComponent';
import PartnerBoard from '../businessComponent/partnerBoardComponent';
import Comments from '../userComponent/businessComponent/businessCommentsComponent';
import Reporting from './reportingComponent';
import IPOstatus from './acceptedIPOPartnersComponent'
import EventAttendance from './eventAttendanceComponent';
import Loading from '../userComponent/loadingComponent/loadingComponent'

//Icons
import { IoIosArrowDroprightCircle, IoMdPerson } from "react-icons/io";
import { GiPayMoney, GiReceiveMoney, GiMoneyStack } from "react-icons/gi";
import { FaHandshake } from "react-icons/fa";
import { BsPiggyBank } from "react-icons/bs";
import { BsFillPiggyBankFill } from "react-icons/bs";
import { AiOutlineGlobal } from "react-icons/ai";
import { GrTransaction } from "react-icons/gr";
import { MdAddBusiness } from "react-icons/md";
import { RiArrowGoBackFill } from "react-icons/ri";
import { GrUserManager } from "react-icons/gr";



import { MdOutlineLeaderboard } from "react-icons/md";

//Partnership Leaderboard
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';

//Material UI Pagination
import TablePagination from '@material-ui/core/TablePagination';

//is Mobile
import { isMobile } from 'react-device-detect';

function Market() {

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);
    const currentBusiness = useSelector((state) => state.business.currentBusiness);

    //Leaderboard Toggle
    const [leaderboardToggle, setLeaderboardToggle] = useState("Monthly");

    //Reporting Panel
    const [supportingScan, setSupportingScan] = useState('');

    //IPO Panel Button to switch entire left panel
    const [ipoPanel, setIPOPanel] = useState(false);

    // Add these state variables at the top of your component
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const navigate = useNavigate();

    useEffect(() => {

        const getUser = async () => {
            const token = localStorage.getItem('AlliesAccessToken');
            if (token) {
                
                //Check the token
                dispatch(tokenLoginUser(token));
            }
            else {
                navigate('/login');
            }
        }

        //If the user is not loaded, load the user
        if (currentUser === null) {
        
            const fetchUser = async () => {
                await getUser();
            };

            fetchUser();
        } else {
            //Get the Business
            dispatch(getBusiness(currentUser.ownership));
        }
    }, [currentUser, ipoPanel]);

    //Navigation Title Panel
    const panelTitle = () => {
        return (
            <div className="dashboard-titlePanel">
                <span>
                    <IoIosArrowDroprightCircle />
                </span>
                <span className="dashboard-title">
                    { currentBusiness.name }
                </span>
            </div>
        );
    }


    //Reporting Panel
    const reportingPanel = () => {

        //Get the last reporting period to see if we have reported this month, if not, show this panel
        const lastReportingPeriod = currentBusiness.valuation[currentBusiness.valuation.length - 1].valDate;

        //Check to see if lastReportingPeriod is in the current month
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();

        const lastReportingPeriodDate = new Date(lastReportingPeriod);

        if (lastReportingPeriodDate.getMonth() !== currentMonth || lastReportingPeriodDate.getFullYear() !== currentYear) {
            return (
                <Reporting businessId={currentBusiness._id}/>
            );
        }
        else {
            return null;
        }
    }



    //Dashboard Company Valuation
    const valuationPanel = () => {
    
        return (
            <React.Fragment>
                <div className='dashboard-company-valuation'>
                    { company_valuation() }
                </div>
            </React.Fragment>
        );
    }

    //Dashboard Company Valuation
    const company_valuation = () => {
        
        const lastValidated = findLastValidated(currentBusiness.valuation);

        //Get the valuation from array element just before the lastValidated array element
        const lastValidatedIndex = currentBusiness.valuation.indexOf(lastValidated);
        const previousElement = lastValidatedIndex > 0 ? currentBusiness.valuation[lastValidatedIndex - 1] : lastValidated;

        //Calculate revenue growth and format as a percentage
        const at_rev_growth = ((lastValidated.gross_revenue - currentBusiness.valuation[0].gross_revenue)
                            / (currentBusiness.valuation[0].gross_revenue) * 100).toFixed(2);
    
        //Month to Month growth
        let mtm_rev_growth = 0;

        if (currentBusiness.valuation.length > 1) {
            mtm_rev_growth = ((lastValidated.gross_revenue - previousElement.gross_revenue)
                            / (previousElement.gross_revenue) * 100).toFixed(2);
        } else {
            mtm_rev_growth = at_rev_growth;
        }

        //Calculate the number of QR scans this month
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();

        const promoScannedThisMonth = currentBusiness.promoScanned.filter(promo => {
            const promoDate = new Date(promo.promoDateUsed);
            return promoDate.getMonth() === currentMonth && promoDate.getFullYear() === currentYear;
        });

        const promoScannedCount = promoScannedThisMonth.length;

        //Calculate the number of QR scans all-time
        const promoScannedCountAllTime = currentBusiness.promoScanned.length;

        //Activation Rate
        var activationRate = promoScannedCount / currentBusiness.partnerships.length * 100;

        //Check to see if activationRate is NaN
        if (isNaN(activationRate)) {
            activationRate = "N/A";
        }

        //Calculate Trade Volume this month
        const tradeVolume = currentBusiness.transactions.filter(trade => {
            const tradeDate = new Date(trade.transactionDate);
            return tradeDate.getMonth() === currentMonth && tradeDate.getFullYear() === currentYear;
        });

        const tradeVolumeThisMonth = tradeVolume.length;

        return (
            <>
                <div className='dashboard-company-valuation-title'>
                    Company Valuation
                </div>
                <div className='dashboard-valuation-panels'>
                    <div className='dashboard-valuation-panel-detail'>
                        <div className='dashboard-valuation-panel-title'>
                            Valuation
                        </div>
                        <div className='dashboard-company-valuation-value-curMonth'>
                            ${ lastValidated.valuation.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                        </div>
                        <div className='dashboard-company-valuation-value-alltime'>
                            ${ 
                                (Math.max.apply(Math, currentBusiness.valuation.filter(function(o) { return o.validated; }).map(function(o) { return o.valuation; }))).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})                             
                            } <span className="at_text">All-time</span>
                        </div>
                    </div>
                    <div className='dashboard-valuation-panel-detail'>
                        <div className='dashboard-valuation-panel-title'>
                            Revenue Growth
                        </div>
                        <div className='dashboard-company-valuation-value-curMonth'>
                            { mtm_rev_growth }%  
                        </div>
                        <div className='dashboard-company-valuation-value-alltime'>
                            { at_rev_growth}% <span className="at_text">All-time</span>
                        </div>
                    </div>
                </div>
                <div className='dashboard-valuation-stats-panel'>
                    <div className='dashboard-valuation-stats-panel-detail'>
                        <div className='dashboard-valuation-panel-title'>
                            Deed Price
                        </div>
                        <div className='dashboard-company-valuation-value-curMonth'>
                            ${ lastValidated.pricePerShare.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                        </div>
                        <div className='dashboard-company-valuation-value-alltime'>
                            ${ 
                                (Math.max.apply(Math, currentBusiness.valuation.filter(function(o) { return o.validated; }).map(function(o) { return o.pricePerShare; }))).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})                             
                            } <span className="at_text">All-time</span>                        
                        </div>
                    </div>
                    <div className='dashboard-valuation-stats-panel-detail'>
                        <div className='dashboard-valuation-panel-title'>
                            Visits
                        </div>
                        <div className='dashboard-company-valuation-value-curMonth'>
                            { promoScannedCount }
                        </div>
                        <div className='dashboard-company-valuation-value-alltime'>
                            { promoScannedCountAllTime } <span className="at_text">All-time</span>                        
                        </div>
                    </div>
                    <div className='dashboard-valuation-stats-panel-detail'>
                        <div className='dashboard-valuation-panel-title'>
                            Number of Partners
                        </div>
                        <div className='dashboard-company-valuation-value'>
                            { currentBusiness.partnerships.length }
                        </div>
                    </div>
                    <div className='dashboard-valuation-stats-panel-detail'>
                        <div className='dashboard-valuation-panel-title'>
                            Partner Activation Rate
                        </div>
                        <div className='dashboard-company-valuation-value'>
                            { typeof activationRate === 'number' ? activationRate.toFixed(2) : new Number(activationRate).toFixed(2) }%
                        </div>
                    </div>
                    { isMobile ? null :
                        <div className='dashboard-valuation-stats-panel-detail'>
                            <div className='dashboard-valuation-panel-title'>
                                Balance
                            </div>
                            <div className='dashboard-company-valuation-value'>
                                ${ currentUser.balance.toFixed(2) }
                            </div>
                        </div>
                    }
                </div>
            </>
        );
    }

    function findLastValidated(arr) {
        if (arr.length === 0) {
            return null;
        }
    
        const lastElement = arr[arr.length - 1];
    
        if (lastElement.validated) {
            return lastElement;
        } else {
            return findLastValidated(arr.slice(0, -1));
        }
    }

    //Calulate Changes as a percentage from month to month
    const calculateChange = (current, previous) => {

        const change = ((current - previous) / previous) * 100;

        if (change > 0) {
            return (
                <div className='stats-panel-value-change-positive'>
                    ( + { change.toFixed(2) }% )
                </div>
            );
        } else {
            return (
                <div className='stats-panel-value-change-negative'>
                    ( { change.toFixed(2) }% )
                </div>
            );
        }
    }

    //Dashboard Company Graph Panel
    const graphPanel = () => {
    
        const lastValidated = findLastValidated(currentBusiness.valuation);

        const lastValidatedIndex = currentBusiness.valuation.findIndex(valuation => valuation === lastValidated);
        const priorToLastValidated = lastValidatedIndex > 0 ? currentBusiness.valuation[lastValidatedIndex - 1] : null;

        return (
            <div className='dashboard-company-valuation'>
                { isMobile ? 
                        <div className='chart'>
                            <ActivationRevenue promoScanned={currentBusiness.promoScanned} partnerships={currentBusiness.partnerships}/>
                        </div> :
                        null
                }
                <div className='dashboard-company-valuation-graph-panel'>
                    { isMobile ? null :
                        <div className='chart'>
                            <ActivationRevenue promoScanned={currentBusiness.promoScanned} partnerships={currentBusiness.partnerships}/>
                        </div>
                    }
                    <div className='stats'>
                        <div className='stats-panel'>
                            <div className='stats-panel-value'>
                                ${ 
                                    (Math.max.apply(Math, currentBusiness.valuation.filter(function(o) { return o.validated; }).map(function(o) { return o.valuation; }))).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})                             
                                }
                            </div>
                            <div className='stats-panel-title'>
                                Best Valuation
                            </div>
                        </div>
                        <div className='stats-panel'>
                            <div className='stats-panel-value'>
                                ${ lastValidated.valuation.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                            </div>
                            {
                                priorToLastValidated ? 
                                    calculateChange(lastValidated.valuation, priorToLastValidated.valuation)  :
                                    null
                            }
                            <div className='stats-panel-title'>
                                Current Valuation
                            </div>
                        </div>
                        <div className='stats-panel'>
                            <div className='stats-panel-value'>
                                ${ lastValidated.dividendPerShare.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                            </div>
                            {
                                priorToLastValidated ? 
                                    calculateChange(lastValidated.dividendPerShare, priorToLastValidated.dividendPerShare)  :
                                    null
                            }
                            <div className='stats-panel-title'>
                                Current Dividend
                            </div>
                        </div>
                        <div className='stats-panel'>
                            <div className='stats-panel-value'>
                                ${ lastValidated.pricePerShare.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                            </div>
                            {
                                priorToLastValidated ? 
                                    calculateChange(lastValidated.pricePerShare, priorToLastValidated.pricePerShare)  :
                                    null
                            }
                            <div className='stats-panel-title'>
                                Deed Price
                            </div>
                        </div>
                        <div className='stats-panel'>
                            <div className='stats-panel-value'>
                                { currentBusiness.numEmployees[currentBusiness.numEmployees.length - 1].num }
                            </div>
                            <div className='stats-panel-title'>
                                No. Employees
                            </div>
                        </div>
                        <div className='stats-panel'>
                            <div className='stats-panel-value'>
                                { currentBusiness.valuation.length  }
                            </div>
                            <div className='stats-panel-title'>
                                Months Active
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /******************************* Right Side *********************************/
    /** IPO Status Panel */
    const IPOPEventPanel = () => {

        if (((currentBusiness.ipo.ipoEvent !== null) && (currentBusiness.ipo.ipoEvent !== undefined))
            && (new Date(currentBusiness.ipo.ipoEvent.ipoEventDate) > new Date())) {
            
            return (
                <>
                    <div className="dashboard-company-valuation">
                        <div className='dashboard-company-valuation-title'>
                            Launch Event
                        </div>
                        <div className='ipoConfirmation'>
                            Date: { new Date(currentBusiness.ipo.ipoEvent.ipoEventDate).toLocaleString() }
                        </div>
                        <EventAttendance confirmations={currentBusiness.ipo.ipoEvent.confirmations} />                               
                    </div>
                </>
            );
        }
    }
    
    /** IPO Status Panel */
    const IPOPanel = () => {

        return (
            <>
                <div className="dashboard-company-valuation">
                    <div className='dashboard-company-valuation-title'>
                        Initial Public Offering Status
                    </div>     
                    <div className='stats'>
                        <div className='stats-panel'>
                            <div className='stats-panel-value'>
                                { new Date(currentBusiness.ipo.ipoDate).toDateString() }
                            </div>
                            <div className='stats-panel-title'>
                                IPO Date
                            </div>
                        </div> 
                        <div className='stats-panel'>   
                            <div className='stats-panel-value'>
                                { currentBusiness.availablePartnerships }
                            </div>
                            <div className='stats-panel-title'>
                                Partners Spots Remaining
                            </div>
                        </div> 
                        <button className="ipoButton" onClick={() => setIPOPanel(true)}>
                            Approve IPO Partners
                        </button>
                    </div>
                           
                </div>
            </>
        );
    }

    /** Top Performers */
    const topPerformers = () => {

        return (
            <>
                <div className="dashboard-company-valuation">
                    <div className='dashboard-company-valuation-title'>
                        Top Partners
                    </div>   
                    <div>
                        <div className="buttonGroupContainer">
                            <ButtonGroup 
                                variant="outlined"
                                sx={{ 
                                    color: 'white',
                                    ".MuiButtonGroup-grouped": {
                                        borderColor: "white",
                                        borderWidth: 2,
                                        "&:hover": {
                                          borderColor: "#E97451"
                                        }
                                    },
                                }}>
                                <Button 
                                    sx={{ color: "#FFF", fontWeight: 'bold', fontFamily: '"Roboto", "Arial", "sans-serif"', backgroundColor: leaderboardToggle === "Monthly" ? "#E97451" : "transparent", marginRight: '10px'}}
                                    onClick={ ()=>setLeaderboardToggle("Monthly") }>Monthly</Button>
                                <Button
                                    sx={{ color: "#FFF", fontWeight: 'bold', fontFamily: '"Roboto", "Arial", "sans-serif"', backgroundColor: leaderboardToggle === "AllTime" ? "#E97451" : "transparent"}}
                                    onClick={ ()=>setLeaderboardToggle("AllTime") }>All-Time</Button>
                            </ButtonGroup>
                        </div>
                        <div>
                            <PartnerBoard data={currentBusiness.promoScanned} toggle={leaderboardToggle}/>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    /** Comments section **/
    const commentsPanel = () => {

        return (
            <>
                <div className="dashboard-company-valuation">
                    <div className='dashboard-company-valuation-title'>
                        Comments
                    </div>   
                    <div>
                        <Comments comments={currentBusiness.comments}/>
                    </div>
                </div>
            </>
        );
    }

    //Pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    //Pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 5));
        setPage(0);
    };

    //Dashboard Transaction History
    const transactionHistory = () => {

        //Iterate through currentUser.orders in reverse order
        const history = currentBusiness.transactions.slice().reverse().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, index, array) => {

            var backgroundColor = '';
            var typeLabel = '';

            //Set the background color based on the order type
            if (order.type === 'sell') {
                backgroundColor = 'rgba(194, 234, 189, 0.6)';
                typeLabel = 'Sold Share';
            } 
            else if (order.type === 'buy') {
                backgroundColor = 'rgba(102, 63, 70, 0.2)';
                typeLabel = 'Repurchased Share';
            }
            else if (order.type === 'dividend') {
                backgroundColor = 'rgba(229, 211, 82, 0.4)';
                typeLabel = 'Dividend Issued';
            }
            else if (order.type === 'ipo') {
                backgroundColor = 'rgba(80, 216, 215, 0.4)';
                typeLabel = 'Sold IPO Share';
            }
            else if (order.type === 'fee') {
                backgroundColor = 'rgba(59, 142, 165, 0.35)';
                typeLabel = 'Transaction Fee';
            }
            else if (order.type === 'deposit') {
                backgroundColor = 'rgba(32, 191, 85, 0.35)';
                typeLabel = 'Deposit';
            }
            else if (order.type === 'withdrawal') {
                backgroundColor = 'rgba(146, 20, 12, 0.2)';
                typeLabel = 'Withdrawal';
            }

            //const backgroundColor = order.type === 'sell' ? 'rgb(194, 234, 189, 0.6)' : 'rgb(102, 63, 70, 0.2)'; 

            return (
                <div key={order._id} style={{ backgroundColor: backgroundColor, borderRadius: '1rem', paddingTop: '1px', margin: '0 1rem' }}>
                    <div className='dashboard-history-content-date'>
                        { 
                            //Format Date
                            new Date(order.transactionDate).toDateString()
                        }
                    </div>
                    <div className="dashboard-history-content-item" >
                        <div className="dashboard-history-content-item-org-icon">
                            { 
                                order.type === "sell" ? <GiReceiveMoney /> :
                                order.type === "buy" ? <GiPayMoney /> :
                                order.type === "dividend" ? <GiMoneyStack /> :
                                order.type === "ipo" ? <FaHandshake /> :
                                order.type === "fee" ? <GrTransaction /> :
                                order.type === "deposit" ? <BsFillPiggyBankFill /> :
                                order.type === "withdrawal" ?  <BsPiggyBank /> :
                                <AiOutlineGlobal />
                            }
                        </div>
                        <div className="dashboard-history-content-item-org-col">
                            <div className="dashboard-history-content-item-name">
                                { order.partnerName ? order.partnerName : "N/A" }
                            </div>
                            <div className="dashboard-history-content-item-type">
                                {typeLabel}
                            </div>
                        </div>
                        <div className="dashboard-history-content-item-transaction-col">
                            <div className="dashboard-history-content-transaction-title">
                                Transaction Amount:
                            </div>
                            {
                                (order.type === "buy" || order.type === "dividend" || order.type === "withdrawal" || order.type === "fee") ? 
                                <div className="dashboard-history-content-transaction-amount-debit">
                                    ${ (order.amount).toFixed(2) }
                                </div> : 
                                <div className="dashboard-history-content-transaction-amount-credit">
                                    ${ (order.amount).toFixed(2) }
                                </div>
                            }
                        </div>
                    </div>
                    <hr className='hr-style' />
                </div>
            );
        });

        return (
            <React.Fragment>
                { history }
                <TablePagination
                        component="div"
                        count={currentBusiness.transactions.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]} // Only one option for rows per page
                />
            </React.Fragment>
        );
    }

    //IPO Party acceptances, IPO Status and Open orders
    const miscPanels = () => {
                
        return (
            <div className='top-portion-panel'>
                <div className='managers_settings_panel' onClick={() => navigate('/Managers')}>
                    <GrUserManager className='iconSizing'/>
                    <div className="settings_panel_title">
                        Add Manager
                    </div>
                </div>
                <div className='business_settings_panel' onClick={() => navigate('/businessSettings')}>
                    <MdAddBusiness className='iconSizing'/>
                    <div className="settings_panel_title">
                        Business Settings
                    </div>
                </div>
                <div className='buyback_settings_panel' onClick={() => navigate('/repurchaseShares')}>
                    <RiArrowGoBackFill className='iconSizing'/>
                    <div className="settings_panel_title">
                        Buy Back Shares
                    </div>
                </div>
            </div>
        )
    }

    //Bottom Dashboard with Transaction History and Misc
    const bottomPanel = () => {

        if (isMobile) {
            return (
                <div>
                    <div className="dashboard-misc-section">
                        { miscPanels() }
                    </div>
                    <div className="dashboard-history-section">
                        <div className="dashboard-history-title">
                            Transaction History
                        </div>
                        <div className="dashboard-history-content">
                            { transactionHistory() }
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <React.Fragment>
                    <div className="dashboard-history-section">
                        <div className="dashboard-history-title">
                            Transaction History
                        </div>
                        <div className="dashboard-history-content">
                            { transactionHistory() }
                        </div>
                    </div>
                    <div className="dashboard-misc-section">
                        { miscPanels() }
                    </div>
                </React.Fragment>
            );
        }
    }

    //Panel to display content depending on if the user prefers the IPO Panel or Dashboard Panel
    function ContentPanel() {

        return (
            <div className="dashboard">
                <Menu />
                { ipoPanel ? <IPOstatus contentPanel={setIPOPanel}/> : <Content /> }
            </div>
        );
    }

    //Content Panels
    function Content() {

        if (isMobile) {
            return (
                <div className="dashboard-content">
                    { panelTitle() }
                    <div className="dashboard-content-panel">
                            { reportingPanel() }
                            { valuationPanel() }
                            { graphPanel() }
                            { IPOPEventPanel() }
                            { IPOPanel() }
                            { topPerformers() }
                            { commentsPanel() }
                    </div>
                    <div className="dashboard-footer-section">
                        { bottomPanel()}
                    </div>
                </div>            
            );
        }
        else {
            return (
                <div className="dashboard-content">
                    { panelTitle() }
                    <div className="dashboard-content-panel">
                        <div className="dashboard-left-panel">
                            { reportingPanel() }
                            { valuationPanel() }
                            { graphPanel() }
                        </div>
                        <div className="dashboard-right-panel">
                            { IPOPEventPanel() }
                            { IPOPanel() }
                            { topPerformers() }
                            { commentsPanel() }
                        </div>
                    </div>
                    <div className="dashboard-footer-section">
                        { bottomPanel()}
                    </div>
                </div>            
            );
        }
      }

    if (currentBusiness !== null) {

        return (
            <div>
                <ContentPanel />
            </div>
        );
    }
    else {
        return (
            <Loading title="Loading Business Dashboard" />
        )
    }
}

export default Market;