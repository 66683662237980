import React, { useState, useEffect } from "react";
import { smpAPI } from '../../api/smpAPI';

import '../../css/dashBoard.css';
import '../../css/admin.css';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { tokenLoginUser } from '../../redux/userActions'; // Import actions

//Navigate
import { useNavigate, Link } from 'react-router-dom';

//Menu Component
import Menu from '../userComponent/menuComponent/menuComponent';
import Loading from "../userComponent/loadingComponent/loadingComponent";

//Icons
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { FaFile } from "react-icons/fa";

//API
import { adminAPI } from '../../api/adminAPI';

//SweelAlert
import Swal from 'sweetalert2';

function ApproveMonthly() {

    //Variables
    const [businesses, setBusinesses] = useState([]);

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    //Navigate
    const navigate = useNavigate();

    useEffect(() => {

        const getUser = async () => {
            const token = localStorage.getItem('AlliesAccessToken');
            if (token) {
                
                //Check the token
                dispatch(tokenLoginUser(token));
            }
            else {
                navigate('/login');
            }
        }

        //If the user is not loaded, load the user
        if (currentUser === null) {
        
            const fetchUser = async () => {
                await getUser();
            };

            fetchUser();
        } else {

            //Get the returns
            const getReturns = async () => {
                const response = await adminAPI.getReturns(currentUser.id);
                setBusinesses(response.data.businesses);
            }

            getReturns();
        }
    }, [currentUser]);

    //Navigation Title Panel
    const panelTitle = () => {
        return (
            <div className="dashboard-titlePanel">
                <span>
                    <IoIosArrowDroprightCircle />
                </span>
                <span className="dashboard-title">
                    Approve Monthly Returns
                </span>
            </div>
        );
    }

    //Number Format
    function formatNumber(num) {
        return Number(num).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    //Returns Panel
    function returnsPanel() {

        return (
            <div className="allreturns-panel">
                { businesses.map((business) => {

                    const sufficientAmount = business.totalDividends <= business.balance;

                    return (
                        <div className="individual-returns-panel">
                            <div className="return-business-name">
                                { business.businessName }
                            </div>
                            <div>
                                <span className="return-business-email-label">
                                    Owner Email: &nbsp;
                                </span>
                                <a href={`mailto:${business.ownerEmail}`} className="return-business-email">
                                    {business.ownerEmail}
                                </a>
                            </div>
                            <div className="three-col-panel">
                                <div className="panel-left" style={{ flex: 1 }}>
                                    <div className="financialCols">
                                        <table className="financialTable">
                                            <tbody>
                                                <tr className="financialRow">
                                                    <th className="financialColLabel">Total Sales:</th>
                                                    <td className="financialColValue" style={{color: '#D63AF9'}}>${formatNumber(business.currentValuation.gross_revenue)}</td>
                                                </tr>
                                                <tr className="financialRow">
                                                    <th className="financialColLabel">Total Expenses:</th>
                                                    <td className="financialColValue" style={{color: '#D63AF9'}}>${formatNumber(business.currentValuation.expenses)}</td>
                                                </tr>
                                                <tr className="financialRow">
                                                    <th className="financialColLabel">Net Profit:</th>
                                                    <td className="financialColValue" style={{color: '#D63AF9'}}>${formatNumber(business.currentValuation.net_income)}</td>
                                                </tr>
                                                <tr className="financialRow">
                                                    <th className="financialColLabel">Price Per Share:</th>
                                                    <td className="financialColValue" style={{color: '#D63AF9'}}>${formatNumber(business.currentValuation.pricePerShare)}</td>
                                                </tr>
                                                <tr className="financialRow">
                                                    <th className="financialColLabel">Dividend:</th>
                                                    <td className="financialColValue" style={{color: '#D63AF9'}}>${formatNumber(business.currentValuation.dividendPerShare)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        { 
                                            business.previousValuation !== null && business.previousValuation !== undefined ? (
                                                <table className="financialTable">
                                                    <tbody>
                                                        <tr className="financialRow">
                                                            <th className="financialColLabel">Prev. Sales:</th>
                                                            <td className="financialColValue">${formatNumber(business.previousValuation.gross_revenue)}</td>
                                                        </tr>
                                                        <tr className="financialRow">
                                                            <th className="financialColLabel">Prev. Expenses:</th>
                                                            <td className="financialColValue">${formatNumber(business.previousValuation.expenses)}</td>
                                                        </tr>
                                                        <tr className="financialRow">
                                                            <th className="financialColLabel">Prev. Net Profit:</th>
                                                            <td className="financialColValue">${formatNumber(business.previousValuation.net_income)}</td>
                                                        </tr>
                                                        <tr className="financialRow">
                                                            <th className="financialColLabel">Prev. PPS:</th>
                                                            <td className="financialColValue">${formatNumber(business.previousValuation.pricePerShare)}</td>
                                                        </tr>
                                                        <tr className="financialRow">
                                                            <th className="financialColLabel">Prev. Dividend:</th>
                                                            <td className="financialColValue">${formatNumber(business.previousValuation.dividendPerShare)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            ) : null 
                                        }
                                    </div>
                                </div>
                                <div className="panel-center" style={{ flex: 1 }}>
                                    <div className="filesDiv">
                                        Files
                                        { business.files.map((file) => {
                                            return (
                                                <div className="return-file">
                                                    <a href={file} className="fileStyle" target="_blank" rel="noreferrer">
                                                        <FaFile /> 
                                                        <span className="filename-style">{file.split('/').pop()}</span>
                                                    </a>
                                                </div>
                                            );
                                        })
                                        }
                                    </div>
                                </div>
                                <div className="panel-right" style={{ flex: 1 }}>
                                    <div className="filesDiv">
                                        Action
                                        <div className="payout-style">
                                            Total Dividend: ${formatNumber(business.totalDividends)}
                                        </div>
                                        <div className="buttons-panel">
                                            { 
                                                sufficientAmount ? 
                                                    (
                                                        <button className="approve-button-style" onClick={() => approveReturn(business.currentValuation._id)}>
                                                            Approve
                                                        </button>
                                                    )
                                                : (
                                                    <button className="disapprove-button-style" onClick={() => insufficientFunds(business._id)}>
                                                        Insufficient Funds - Send Notification
                                                    </button>
                                                )    
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    //Button to approve the return
    const approveReturn = async (returnId) => {

        const response = await adminAPI.approveReturn(returnId);

        if (response.status === 200) {

            const getReturns = async () => {
                const response = await adminAPI.getReturns(currentUser.id);
                setBusinesses(response.data.businesses);
            }
    
            getReturns();
        }
    }

    //Button to let Business Owner know that there are insufficient funds
    const insufficientFunds = async (businessId) => {

        const response = await adminAPI.insufficientFunds(businessId);

        if (response.status === 200) {
                
            Swal.fire({
                title: 'Notification Sent',
                text: 'The Business Owner has been notified that there are insufficient funds to pay the dividend.',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        }
    }

    //Panel to display content depending on if the user prefers the IPO Panel or Dashboard Panel
    function ContentPanel() {

        return (
            <div className="dashboard">
                <Menu />
                <Content /> 
            </div>
        );
    }

    //Content Panels
    function Content() {

        return (
            <div className="dashboard-content">
                { panelTitle() }
                <div className="dashboard-content-panel">
                    { returnsPanel() }
                </div>
            </div>            
        );
      }

    if ((currentUser !== null) && (currentUser !== undefined)) {

        if (currentUser.role === 'admin') {
            return (
                <div>
                    <ContentPanel />
                </div>
            );
        }
        else {
            navigate('/');
        }
    }
    else {
        return (
            <Loading title="Loading QR Codes" />
        )
    }
}

export default ApproveMonthly;