//Loading
import ReactLoading from 'react-loading';

function Loading(props) {

    //For the Loading icon
    const pageStyle = {
        backgroundSize: 'cover',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    return (
        <div style={pageStyle}>
            <div className='formPanel'>
                <ReactLoading type="balls" color="#000" height={'15rem'} width={'15rem'}/>
                <h1 style={{color: 'black'}}>{props.content}</h1>
            </div>
        </div>
    )
}

export default Loading;
