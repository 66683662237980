import { DataGrid } from '@mui/x-data-grid';

import '../../css/dashBoard.css'

function partnerBoard (props) {

    //Get the Scanned QR Count
    const data = props.data;

    //Get the Toggle
    const toggle = props.toggle;

    var partners = [];
    
    function getPartnerScans() {

        if (toggle === "AllTime") {
            
            const partnerNameMap = data.reduce((acc, promo) => {
                acc[promo.promoPartner] = promo.promoPartnerName;
                return acc;
            }, {});

            const promoScannedTotal = data.reduce((acc, promo) => {
                acc[promo.promoPartner] = (acc[promo.promoPartner] || 0) + 1;
                return acc;
            }, {});
    
            //Map the promoPartner to the number of total promos scanned, also adding in the name from the promoPartnerName field
            partners = Object.keys(promoScannedTotal).map(promoPartner => {
                return { promoPartner, promoPartnerName: partnerNameMap[promoPartner], totalScanned: promoScannedTotal[promoPartner] };
            });
    
            //Sort the partners array based on totalScanned
            partners.sort((a, b) => b.totalScanned - a.totalScanned);
        }

        else {

            //Go through the promoScanned array, sum up the total value of the promos scanned grouped by promoPartner for this month alone
            const promoScannedThisMonth = data.filter(promo => new Date(promo.promoDateUsed).getMonth() === new Date().getMonth());

            const partnerNameMapThisMonth = promoScannedThisMonth.reduce((acc, promo) => {
                acc[promo.promoPartner] = promo.promoPartnerName;
                return acc;
            }, {});

            const promoScannedTotalThisMonth = promoScannedThisMonth.reduce((acc, promo) => {
                acc[promo.promoPartner] = (acc[promo.promoPartner] || 0) + 1;
                return acc;
            }, {});

            //Map the promoPartner to the number of total promos scanned
            var partners = Object.keys(promoScannedTotalThisMonth).map(promoPartner => {
                return { promoPartner, promoPartnerName: partnerNameMapThisMonth[promoPartner], totalScanned: promoScannedTotalThisMonth[promoPartner] };
            });

            //Sort the partners array based on totalScanned
            partners.sort((a, b) => b.totalScanned - a.totalScanned);
        }

        return partners;
    }

    const columns = [
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'visits', headerName: 'Visits', width: 130 },
    ];
    
    const rows = getPartnerScans().map((partner, index) => {
        return { id: index, name: partner.promoPartnerName, visits: partner.totalScanned };
    });
    
    return (
            <DataGrid 
                columns={columns} 
                rows={rows}
                rowHeight={30}
                pageSizeOptions={[10]}
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                }}
                sx={{
                    border: 0,
                    color: 'white',
                    fontWeight: 'bold',
                    '&, [class^=MuiDataGrid]': { border: 'none' },
                }} 
                localeText={{noRowsLabel: 'No Partners Currently'}} // Change "No rows" to "No notifications"
           />
    );
}

export default partnerBoard;