import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import '../../../css/dashBoard.css'

//isMobile
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
        },
    },
});

function DataTable (props) {

    const navigate = useNavigate();
    const classes = useStyles();

    const investments = props.investments;

    let columns;

    if (isMobile) {
        
        columns = [
            { field: 'name', headerName: 'Business Name' },
            { field: 'valuation', headerName: 'Current Valuation' },
            { field: 'dividend', headerName: 'Current Dividend' }
        ];
    }
    else {

        columns = [
            { field: 'name', headerName: 'Business Name', width: 200 },
            { field: 'valuation', headerName: 'Current Valuation', width: 125 },
            { field: 'dividend', headerName: 'Current Dividend', width: 125 }
        ];
    }
    
    const rows = investments.map((investment) => {

        return {
            id: investment.businessId,
            name: investment.name,
            valuation: '$' + investment.curValuation.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2}),
            dividend: '$' + investment.curDividend.toFixed(2)
        }
    });
      
    return (
        <DataGrid 
            className={classes.root}
            columns={columns} 
            rows={rows}
            pageSizeOptions={[10]}
            onRowClick={(row) => { navigate(`/stock/${row.id}`); }}
            sx={{
                border: 0,
                color: 'white',
                fontWeight: 'bold',
                '&, [class^=MuiDataGrid]': { border: 'none' },
            }} 
            localeText={{noRowsLabel: 'No Investments'}} // Change "No rows" to "No notifications"
        />
    )
}

export default DataTable;