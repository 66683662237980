import React, { useState, useEffect } from "react";
import { smpAPI } from '../../api/smpAPI';

import '../../css/dashBoard.css';
import '../../css/admin.css';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { tokenLoginUser } from '../../redux/userActions'; // Import actions

//Navigate
import { useNavigate, Link } from 'react-router-dom';

//Menu Component
import Menu from '../userComponent/menuComponent/menuComponent';
import Loading from "../userComponent/loadingComponent/loadingComponent";

//Icons
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { FaFile } from "react-icons/fa";

//API
import { adminAPI } from '../../api/adminAPI';

//SweelAlert
import Swal from 'sweetalert2';

//Date Picker
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

function SetIPO() {

    //Variables
    const [businesses, setBusinesses] = useState([]);
    const [ipoDate, setIPODate] = useState('');

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    //Navigate
    const navigate = useNavigate();

    useEffect(() => {

        const getUser = async () => {
            const token = localStorage.getItem('AlliesAccessToken');
            if (token) {
                
                //Check the token
                dispatch(tokenLoginUser(token));
            }
            else {
                navigate('/login');
            }
        }

        //If the user is not loaded, load the user
        if (currentUser === null) {
        
            const fetchUser = async () => {
                await getUser();
            };

            fetchUser();
        } else {

            //Get the returns
            const getReturns = async () => {
                const response = await adminAPI.getCompanies();
                setBusinesses(response.data.businesses);
            }

            getReturns();
        }
    }, [currentUser]);

    //Navigation Title Panel
    const panelTitle = () => {
        return (
            <div className="dashboard-titlePanel">
                <span>
                    <IoIosArrowDroprightCircle />
                </span>
                <span className="dashboard-title">
                    Set IPO Dates
                </span>
            </div>
        );
    }

    //Returns Panel
    function returnsPanel() {

        return (
            <div className="allreturns-panel">
                { businesses.map((business) => {

                    return (
                        <div className="individual-returns-panel">
                            <div className="return-business-name">
                                { business.name }
                            </div>
                            <div className="return-business-name">
                                { business.location.address }
                            </div>
                            <hr />
                            <DatePicker inputStyle={{width: '100%'}} value={ipoDate} onChange={(date) => setIPODate(date)} />
                            <div className="buttons-panel">
                                <button className="approve-button-style" onClick={() => setDate(business.id)}>
                                    Set Date
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    //Button to approve the return
    const setDate = async (businessID) => {

        const response = await adminAPI.setIPO(businessID, ipoDate);

        if (response.status === 200) {

            const getBusinesses = async () => {
                const response = await adminAPI.getCompanies();
                setBusinesses(response.data.businesses);
            }
    
            getBusinesses();
        }
    }

    //Panel to display content depending on if the user prefers the IPO Panel or Dashboard Panel
    function ContentPanel() {

        return (
            <div className="dashboard">
                <Menu />
                <Content /> 
            </div>
        );
    }

    //Content Panels
    function Content() {

        return (
            <div className="dashboard-content">
                { panelTitle() }
                <div className="dashboard-content-panel">
                    { returnsPanel() }
                </div>
            </div>            
        );
      }

    if ((currentUser !== null) && (currentUser !== undefined)) {

        if (currentUser.role === 'admin') {
            return (
                <div>
                    <ContentPanel />
                </div>
            );
        }
        else {
            navigate('/');
        }
    }
    else {
        return (
            <Loading title="Set IPO" />
        )
    }
}

export default SetIPO;