import React, { useState, useEffect } from 'react';

import { smpAPI } from '../../../api/smpAPI';

//Styling
import '../../../css/search.css';

//Components
import UserTable from './userTable';

//Icons
import { IoIosArrowDroprightCircle } from "react-icons/io";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FaSearch } from "react-icons/fa";

import Swal from 'sweetalert2';
import { Button } from 'reactstrap';

const UserSearch = (props) => {

    const business = props.business;
   
    const businessId = business._id;
    const ipoEventDate = business.ipo.ipoEvent.ipoEventDate;
    const eventConfirmations = business.ipo.ipoEvent.confirmations;

    const [searchValue, setSearchValue] = useState('');
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            await findCustomers();
        };

        fetchUsers();
    }, []);

    const handleChange = (event) => {
        setSearchValue(event.target.value);
    };

    const executeSearch = async () => {

        findCustomers();
    }

    async function findCustomers() {
        
        if (searchValue.length <= 0) {
            
            const response = await smpAPI.searchUsersByProximity(businessId);

            if (response.status === 200) {
                
                //Check the messages 
                if (response.data.message === 'Found') {
                    setUsers(response.data.users);
                }
                else {
                    setUsers([]);
                }
            }
        }

        else {
            
            const response = await smpAPI.searchUsers(searchValue, businessId);

            if (response.status === 200) {
                
                //Check the messages 
                if (response.data.message === 'User not found') {
                    Swal.fire({
                        icon: 'error',
                        title: 'User not found',
                        text: 'No such user found in our platform.'
                    });
                    setUsers([]);
                }
                else {
                    setUsers(response.data.users);
                }
            }
        }
    }

    return (
        <div className="searchLayout">
            <div className='dashboard-search'>
                <TextField
                    placeholder="Search by Name or Email"
                    fullWidth
                    value={searchValue}
                    onChange={handleChange}
                    onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                        executeSearch();
                        ev.preventDefault();
                        }
                    }}
                />
                <Button className="searchButton" onClick={executeSearch}>Search</Button>
            </div>
            <UserTable users={users} business={business} />
        </div>
    );
}

export default UserSearch;