import * as React from 'react';
import { useState, useRef, useEffect } from "react";
import TextField from '@mui/material/TextField';

//Images
import Background from '../../../assets/images/neighbourhood.jpg';
import UploadProfile from '../../../assets/images/profileUpload.png';

//Styling
import '../../../css/onBoard.css'

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { onboardUser } from '../../../redux/userActions'; // Import actions

//Images
import Logo from '../../../assets/images/logo.png';

//Tag Input
import { WithContext as ReactTags } from 'react-tag-input';

//SweetAlert
import Swal from 'sweetalert2';

//Loading
import ReactLoading from 'react-loading';

//Portfolio Component
import Portfolio from '../marketComponents/portfolioComponent';

const KeyCodes = {
    comma: 188,
    enter: 13
};

function OnBoard() {

    //Variables
    const [password, setPassword] = React.useState('');
    const [name, setName] = React.useState('');

    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    //Loading after button pressed
    const [loading, setLoading] = useState(false);

    //Tags
    const [tags, setTags] = React.useState([{ id: 'Burger_Joint', text: 'Burger Joint' }]);
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    //Profile pic
    const [image, setImage] = React.useState('');
    const [preview, setPreview] = useState(null);

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    //Google Places API
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
        types: ['geocode'],
        componentRestrictions: { country: ["ca","us"] }
    };

    const pageStyle = {
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(document.getElementById('home_address'), options);
        autoCompleteRef.current.addListener('place_changed', handlePlaceSelect);
    }, []);

    const handlePlaceSelect = () => {
        const addressObject = autoCompleteRef.current.getPlace();
        const address = addressObject.address_components;

        if (address) {

            //Get the city and state
            for (let i = 0; i < address.length; i++) {
                if (address[i].types.includes('locality')) {
                    setCity(address[i].long_name);
                }
                if (address[i].types.includes('administrative_area_level_1')) {
                    setState(address[i].long_name);
                }
                if (address[i].types.includes('country')) {
                    setCountry(address[i].long_name);
                }
            }

            setLatitude(addressObject.geometry.location.lat());
            setLongitude(addressObject.geometry.location.lng());
        }
    }

    //Handle the image change
    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
        setPreview(URL.createObjectURL(e.target.files[0]));
    };

    /** Tag Input **/
    const handleTagDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleTagAddition = tag => {
        setTags([...tags, tag]);
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();
    
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
    
        // re-render
        setTags(newTags);
    };

    //When the user clicks on the Submit button
    const handleSubmit = (e) => {
        e.preventDefault();
        
        //Make sure home address is filled in
        if (autoCompleteRef.current.getPlace() === undefined) {

            Swal.fire({
                icon: 'error',
                title: 'Address error',
                text: 'Please enter your home address to match with nearby businesses',
            });

            return;
        }

        //If Password is not present on user, then it needs to be filled in
        else if (!currentUser.password && password.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Password error',
                text: 'Please enter your password',
            });

            return;
        }

        //If Name is not present on user, then it needs to be filled in
        else if (currentUser.name.length < 1 && name.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Name error',
                text: 'Please enter your name',
            });

            return;
        }

        //If profile image is not present on user, then it needs to be filled in
        else if (currentUser.profileImage.length < 1 && image.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Profile image error',
                text: 'Please upload a profile image, this can be updated.',
            });

            return;
        }

        else {  
            //Set Loading
            setLoading(true);

            dispatch(onboardUser(currentUser._id, name, password, image, city, state, country, latitude, longitude, tags));
        }
        
    };

    if ((!loading) && (currentUser.coordinates === undefined)) {
        return (
            <div style={pageStyle}>
                <div className='formPanel'>
                    <div className='title'>Welcome to CommUnity Owners</div>
                    <div className='subTitle'>Customize your settings to help us match you with the best opportunities in your area.</div>
                    {/* Ask user to upload profile picture */}
                    { currentUser.profileImage.length < 1 ? 
                        <div className='inputBox'>
                            <input
                                type="file"
                                id="profilePic"
                                accept="image/*"
                                onChange={handleImageChange}
                                style={{display: 'none'}} // Hide the default file input
                            />
                        
                            {preview ? 
                                    <label htmlFor="profilePic" style={{cursor: 'pointer'}}>
                                        <div className='profileImagePanel'>
                                            <img src={preview} alt="preview" className="profileImage" />
                                            <span className="profileText">Upload a profile image (Click on the image above)</span>
                                        </div>
                                    </label>
                                :   <label htmlFor="profilePic" style={{cursor: 'pointer'}}>
                                        <div className='profileImagePanel'>
                                            <img className="profileImage" src={UploadProfile} /> 
                                            <span className="profileText">Upload a profile image (Click on the image above)</span>
                                        </div>
                                    </label>
                            }

                        </div>
                        : null
                    }
                    {/* Need to set Password, because it was a Google registrant */}
                    { (!currentUser.password) || (currentUser.password.length < 1) ? 
                        <div className='inputBox'>
                            <TextField
                                id="password"
                                label="Set Password"
                                color='secondary'
                                className='input'
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        : null
                    }
                    {/* Need to fill in name, as it was a non-Google registrant */}
                    { currentUser.name.length < 1 ? 
                        <div className='inputBox'>
                            <TextField
                                id="name"
                                label="Set First and Last Name"
                                color='secondary'
                                className='input'
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        : null
                    }
                    <div className='inputBox'>
                        <TextField
                            id="home_address"
                            label="Set Home Address"
                            color='secondary'
                            className='input'
                            inputRef={inputRef}
                        />
                    </div>
                    <div className='interestBox'>
                        <ReactTags
                            className="inputBox"
                            tags={tags}
                            delimiters={delimiters}
                            handleDelete={handleTagDelete}
                            handleAddition={handleTagAddition}
                            handleDrag={handleDrag}
                            inputFieldPosition="top"
                            placeholder='What businesses would you like to invest in? (ex. Burger Joint, Coffee Shop, etc.)'
                        />
                    </div>
                    <div className='buttonDiv'>
                        <button className='button' onClick={handleSubmit}>Complete</button>
                    </div>
                </div>
            </div>
        );
    } 
    else if ((loading) && (currentUser.coordinates === undefined)) {

        return (
            <div style={pageStyle}>
                <div className='formPanel'>
                    <img src={Logo} className='image'/>
                    <ReactLoading type="balls" color="#000" height={'15rem'} width={'15rem'}/>
                    <h1>Updating your profile</h1>
                </div>
            </div>
        );
    }
    else {

        return (
            <Portfolio />
        ); 
    }
}

export default OnBoard;
