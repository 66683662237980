import { api } from "./config/axiosConfig"

export const adminAPI = {

    getReturns: async function (userId) {

        const response = await api.request({
          url: `/admin/getReturns`,
          method: "POST",
          data: {
            userId: userId,
          },
        })
        
        // returning the product returned by the API
        return response
    },

    approveReturn: async function (returnId) {

        const response = await api.request({
          url: `/admin/approveReturn`,
          method: "POST",
          data: {
            returnId: returnId,
          },
        })
        
        // returning the product returned by the API
        return response
    },

    insufficientFunds: async function (businessId) {

        const response = await api.request({
          url: `/admin/NSFDividends`,
          method: "POST",
          data: {
            businessId: businessId,
          },
        })
        
        // returning the product returned by the API
        return response
    },

    getCompanies: async function () {

        const response = await api.request({
          url: `/admin/IPOBusiness`,
          method: "GET",
        })
        
        // returning the product returned by the API
        return response
    },

    setIPO: async function (businessId, ipoDate) {

        const response = await api.request({
          url: `/admin/setIPODate`,
          method: "POST",
          data: {
            businessId: businessId,
            ipoDate: ipoDate,
          },
        })
        
        // returning the product returned by the API
        return response
    },
}