import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Swal from 'sweetalert2';

//Styling
import '../../../css/businessPanel.css';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { setBusinessObject } from '../../../redux/businessActions'; // Import actions
import { setCurrentUser } from '../../../redux/userActions'; // Import actions


//Icons
import { IoIosArrowDroprightCircle } from "react-icons/io";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FaSearch } from "react-icons/fa";

//API
import { smpAPI } from '../../../api/smpAPI';

const useStyles = makeStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: 'none',
        },
        '&:hover fieldset': {
          border: 'none',
        },
        '&.Mui-focused fieldset': {
          border: 'none',
        },
      },
    },
});

const TradeInput = (props) => {

    const [placeholder, setPlaceHolder] = useState('');
    const [amount, setAmount] = useState('');

    //Props
    const business = props.business;
    const tradeType = props.tradeType;
    const userId = props.userId;
    const setBusiness = props.setBusiness;
    const setOpen = props.setOpen;

    const businessName = business.name;
    const businessId = business._id;

    //Redux Object
    const dispatch = useDispatch();

    const classes = useStyles();

    /**
     * Set the placeholder based on the trade type
     */
    useEffect(() => {
        if (tradeType === 'Buy') {
            setPlaceHolder('Set the maximum price you are willing to pay for a share');
        }
        else {
            setPlaceHolder('Set the minimum price you are willing to sell your share');
        }
    }, [tradeType]);

    /**
     * Networking
     */
    const buyShares = async (businessId, userId, amount) => {
        try {
            const response = await smpAPI.buyShares(businessId, userId, amount);
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    const sellShares = async (businessId, userId, amount) => {
        try {
            const response = await smpAPI.sellShares(businessId, userId, amount);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const handleChange = (event) => {
        
        setAmount(event.target.value);
    };

    const handleSubmit = async () => {

        if (amount === '') {
            Swal.fire({
                icon: 'error',
                title: 'Price error',
                text: 'Price field is empty, please enter a price',
            });
            return;
        }
        else if (amount < 0) {
            Swal.fire({
                icon: 'error',
                title: 'Price error',
                text: 'Price field is less than 0, please enter a positive price or 0 to cancel an existing order',
            });
            return;
        }
    
        //Buy Shares
        if (tradeType === 'Buy') {
            const data = await buyShares(businessId, userId, amount);

            if (data.message === "Insufficient Funds") {
                Swal.fire({
                    icon: 'error',
                    title: 'Insufficient Funds',
                    text: 'You do not have enough funds to buy shares for ' + businessName + ' please deposit money into your account'
                });
                setOpen(false);
            }
            else if (data.message === "Buy Order") {
                const transaction = data.transaction;

                //Just to confirm
                if (transaction.type === "buyOrder") {
                    const updatedBusiness = {
                        ...business,
                        openBuyOrders: [...business.openBuyOrders, transaction]
                    };
                    setBusiness(updatedBusiness);
                    setOpen(false);
                }

                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'You have successfully placed a buy order for ' + businessName + ' for the amount of: $' + new Number(amount).toFixed(2),
                });
            }
            else if (data.message === "Buy Order Updated") {
                const transaction = data.transaction;

                //find the existing transaction in the openBuyOrders array and update it
                const updatedBuyOrders = business.openBuyOrders.map((buyOrder) => {
                    if (buyOrder._id === transaction._id) {
                        return transaction;
                    }
                    else {
                        return buyOrder;
                    }
                });

                const updatedBusiness = {
                    ...business,
                    openBuyOrders: updatedBuyOrders
                };

                setBusiness(updatedBusiness);
                setOpen(false);

                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'You have successfully updated your buy order for ' + businessName + ' to the amount of: $' + new Number(amount).toFixed(2),
                });
            }
            else if (data.message === "Buy Order Cancelled") {
                const transaction = data.transaction;

                //Remove the transaction from the openBuyOrders array
                const updatedOpenBuyOrders = business.openBuyOrders.filter((buyOrder) => {
                    return buyOrder._id !== transaction._id;
                });

                const updatedBusiness = {
                    ...business,
                    openBuyOrders: updatedOpenBuyOrders
                };

                setBusiness(updatedBusiness);
                setOpen(false);


                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'You have successfully cancelled your buy order for ' + businessName,
                });
            }
            else if (data.message === "No Buy Order to Cancel") {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'You do not have a buy order to cancel for ' + businessName,
                });

                setOpen(false);
            }
            else if (data.message === "IPO Buy Order") {
                
                const business = data.business;

                dispatch(setBusinessObject(business));

                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'You have successfully placed an IPO buy order for ' + businessName + ' for the amount of: $' + new Number(amount).toFixed(2),
                });

                setOpen(false);
            }
            else if (data.message === "Trade Completed") {
                
                const business = data.business;
                const user = data.user;
                const transaction = data.transaction;

                //Remove the transaction from the openSellOrders array
                const updatedOpenSellOrders = business.openSellOrders.filter((sellOrder) => {
                    return sellOrder._id !== transaction._id;
                });

                const updatedBusiness = {
                    ...business,
                    openSellOrders: updatedOpenSellOrders
                };

                setBusiness(updatedBusiness);

                setOpen(false);

                dispatch(setCurrentUser(user));
                dispatch(setBusinessObject(business));

                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'You have successfully bought a share in ' + businessName,
                });
            }
        }
        else {
            const data = await sellShares(businessId, userId, amount);

            if (data.message === "Sell Order") {
                const transaction = data.transaction;

                //Just to confirm
                if (transaction.type === "sellOrder") {
                    const updatedBusiness = {
                        ...business,
                        openSellOrders: [...business.openSellOrders, transaction]
                    };
                    setBusiness(updatedBusiness);
                }

                setOpen(false);

                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'You have successfully placed a sell order for ' + businessName + ' for the amount of: $' + new Number(amount).toFixed(2),
                });
            }
            else if (data.message === "Sell Order Updated") {
                const transaction = data.transaction;

                //Just to confirm
                if (transaction.type === "sellOrder") {

                    //find the existing transaction in the openSellOrders array and update it
                    const updatedSellOrders = business.openSellOrders.map((sellOrder) => {
                        if (sellOrder._id === transaction._id) {
                            return transaction;
                        }
                        else {
                            return sellOrder;
                        }
                    });

                    const updatedBusiness = {
                        ...business,
                        openSellOrders: updatedSellOrders
                    };
                    setBusiness(updatedBusiness);
                }

                setOpen(false);

                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'You have successfully updated your sell order for ' + businessName + ' to the amount of: $' + new Number(amount).toFixed(2),
                });
            }
            else if (data.message === "Trade completed") {
                const business = data.business;
                const user = data.user;
                const transaction = data.transaction;

                //Remove the transaction from the openBuyOrders array
                const updatedOpenBuyOrders = business.openBuyOrders.filter((buyOrder) => {
                    return buyOrder._id !== transaction._id;
                });                

                const updatedBusiness = {
                    ...business,
                    openBuyOrders: updatedOpenBuyOrders
                };

                setOpen(false);

                dispatch(setCurrentUser(user));
                dispatch(setBusinessObject(business));

                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'You have successfully sold a share in ' + businessName + ' for the amount of: $' + new Number(amount).toFixed(2),
                })
            }
        }
    }

    return (
        <React.Fragment>
            <div className='tradeInputBox'>
                <TextField
                    id="price"
                    label={placeholder}
                    color='secondary'
                    className={classes.root}
                    onChange={handleChange}
                    type="number"
                    variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        style: { 
                            borderRadius: '1rem', 
                            backgroundColor: 'white', 
                            width: '100%', 
                            padding: '0.7rem',
                            boxShadow: '0px 3px 6px black' 
                        }
                    }}
                />
            </div>
            <div className='tradeButtonPanel'>
                <button className='tradeButton' onClick={handleSubmit}>{tradeType === 'Buy' ? 'Buy' : 'Sell'}</button>
            </div>
        </React.Fragment>
    );
}

export default TradeInput;