import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';

import '../../../css/dashBoard.css'

import { createTheme, ThemeProvider } from '@mui/material/styles';

//Networking API
import { smpAPI } from '../../../api/smpAPI';

//Sweet Alert
import Swal from 'sweetalert2';

//Is Mobile
import { isMobile } from 'react-device-detect';

// Create a custom theme with black text for the pagination component
const theme = createTheme({
    components: {
      MuiTablePagination: {
        styleOverrides: {
          root: {
            color: 'black !important',
          },
        },
      },
    },
});

function UserLookupTable (props) {

    //Get the search term
    const users = props.users;

    const business = props.business;

    const eventConfirmations = business.ipo.ipoEvent.confirmations;
    const businessId = business._id;
    const ipoEventDate = business.ipo.ipoEvent.ipoEventDate;
    
    /**
     * Handles the click event for the buy button
     * @param {*} businessId 
     */
    const handleBuyClick = async (row) => {

        //Double Confirmation for user
        const { isConfirmed } = await Swal.fire({
            title: 'Are you sure?',
            text: 'You are inviting this user to the Launch Party!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Invite!',
            cancelButtonText: 'No, Cancel'
        });

        if (!isConfirmed) {
            return;
        }
        else {

            const response = await smpAPI.sendBuyNotification(businessId, row.row.id);

            if (response.status === 200) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Invited User to purchase a Share!',
                    icon: 'success'
                });
            }
            else {
                Swal.fire({
                    title: 'Error!',
                    text: response.data.message,
                    icon: 'error'
                });
            }
        }
    }

    /**
     * Handles the click event for the event button
     * @param {*} businessId 
     */
    const handleEventClick = async (row) => {

        //Double Confirmation for user
        const { isConfirmed } = await Swal.fire({
            title: 'Are you sure?',
            text: 'You are inviting this user to the Launch Party!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Invite!',
            cancelButtonText: 'No, Cancel'
        });

        if (!isConfirmed) {
            return;
        }
        else {
            const response = await smpAPI.sendEventNotification(businessId, row.row.id);

            if (response.status === 200) {

                if (response.data.message === "User is already attending the event") {
                    Swal.fire({
                        title: 'Error!',
                        text: 'User already invited to event!',
                        icon: 'error'
                    });
                    return;
                }
                else {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Invited User to Launch Party!',
                        icon: 'success'
                    });
                }
            }
            else {
                Swal.fire({
                    title: 'Error!',
                    text: response.data.message,
                    icon: 'error'
                });
            }
        }
    }

    const columns = [
        { field: 'image', headerName: '', width: isMobile ? null : 150, renderCell: (params) => (
            <img src={params.value} style={{ width: 30, height: 30, borderRadius: 50}} />
        )},
        { field: 'name', headerName: 'Name', width: isMobile ? null : 200 },
        { field: 'distance', headerName: 'Distance from Business', width: isMobile ? null : 200 },
        {
            field: 'actions',
            headerName: '',
            sortable: false,
            width: 400,
            disableClickEventBubbling: true,
            renderCell: (params) => {

                if ((ipoEventDate === null) || (ipoEventDate === undefined) || (new Date(ipoEventDate) <= new Date())) {
                    return (
                        <div className="cellButtonPanel">
                            <button onClick={() => handleBuyClick(params)} className='buyButton'>Invite to Buy</button>
                        </div>
                    );
                }
                else if (eventConfirmations.some(event => event.partnerId === params.row.id)) {
                    return (
                        <div className="cellButtonPanel">
                            <button onClick={() => handleBuyClick(params)} className='buyButton'>Invite to Buy</button>
                        </div>
                    );
                }
                else if (business.partnerships.some(partnership => partnership.partnerId === params.row.id)) {
                    return (
                        <div className="cellButtonPanel">
                            <button onClick={() => handleEventClick(params)} className='eventButton'>Invite to Event</button>
                        </div>
                    );
                }
                else {
                    return (
                        <div className="cellButtonPanel">
                            <button onClick={() => handleEventClick(params)} className='eventButton'>Invite to Event</button>
                            <button onClick={() => handleBuyClick(params)} className='buyButton'>Invite to Buy</button>
                        </div>
                    );
                }
            },
        },
    ];
    
    //Create the rows
    const rows = users.map((customer) => {
        return { id: customer._id, 
                name: customer.name, 
                image: customer.image, 
                distance: customer.distance + " km" };
    });
    
    return (
        <ThemeProvider theme={theme}>
            <DataGrid 
                columns={columns} 
                rows={rows}
                rowHeight={40}
                pageSizeOptions={[10]}
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                }}
                sx={{
                    border: 0,
                    color: 'black',
                    fontWeight: 'bold',
                    '&, [class^=MuiDataGrid]': { border: 'none' },
                    '& .MuiDataGrid-cell': {
                        color: 'black',
                    },
                    '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-root .MuiTablePagination-root': {
                        color: 'black',
                    },
                }} 
                localeText={{noRowsLabel: 'No Partners Currently'}} // Change "No rows" to "No notifications"
           />
        </ThemeProvider>
    );
}

export default UserLookupTable;