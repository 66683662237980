import * as React from 'react';
import { useState, useEffect, useRef } from "react";

import '../../css/businessMarket.css';
import '../../css/settings.css';

//Navigate
import { useNavigate } from 'react-router-dom';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { tokenLoginUser } from '../../redux/userActions'; // Import actions
import { getBusiness, updateBusiness } from '../../redux/businessActions';

//Menu Component
import Menu from '../userComponent/menuComponent/menuComponent';
import Loading from '../userComponent/loadingComponent/loadingComponent'
import SettingsInput from './settingsInputComponent/settingsInput';

//Icons
import { IoIosArrowDroprightCircle, IoMdPerson } from "react-icons/io";

//Images
import UploadProfile from '../../assets/images/profileUpload.png';

//Partnership Leaderboard
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';

//SweetAlert
import Swal from 'sweetalert2';

function BusinessSettings() {

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);
    const currentBusiness = useSelector((state) => state.business.currentBusiness);

    const navigate = useNavigate();

    //Variables
    const [name, setName] = React.useState('');
    const [address, setAddress] = React.useState('');

    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    const [description, setDescription] = React.useState('');
    const [story, setStory] = React.useState('');
    const [website, setWebsite] = React.useState('');

    const [numEmployees, setNumEmployees] = React.useState('');
    const [promoExpense, setPromoExpense] = React.useState('');
    const [promoDescription, setPromoDescription] = React.useState('');

    //Profile pic
    const [image, setImage] = React.useState('');
    const [preview, setPreview] = useState(null);

    var promoSettings;

    //Google Places API
    const autoCompleteRef = useRef();
    const inputRef = useRef();

    const options = {
        types: ['geocode'],
        componentRestrictions: { country: ["ca","us"] }
    };

    useEffect(() => {

        const getUser = async () => {
            const token = localStorage.getItem('AlliesAccessToken');
            if (token) {
                
                //Check the token
                dispatch(tokenLoginUser(token));
            }
            else {
                navigate('/login');
            }
        }

        //If the user is not loaded, load the user
        if (currentUser === null) {
        
            const fetchUser = async () => {
                await getUser();
            };

            fetchUser();
        } else {
            
            if (currentBusiness === null) {
                //Get the Business
                dispatch(getBusiness(currentUser.ownership));    
            }

            if (currentBusiness !== null && currentBusiness.logo !== null) {
                promoSettings = currentBusiness.promoSettings;
                setPreview(currentBusiness.logo);
            }

            if (currentBusiness !== null && promoSettings !== undefined) {
                setNumEmployees(currentBusiness.numEmployees[currentBusiness.numEmployees.length - 1].num);

                if (promoSettings.length > 0) {
                    setPromoExpense(promoSettings[promoSettings.length - 1].promoValue);
                    setPromoDescription(promoSettings[promoSettings.length - 1].description);
                }

                setName(currentBusiness.name);

                setDescription(currentBusiness.description);
                setStory(currentBusiness.ownerStory);
                setWebsite(currentBusiness.website);
                setAddress(currentBusiness.location.address);
                setCity(currentBusiness.location.city);
                setState(currentBusiness.location.state);
                setCountry(currentBusiness.location.country);

                autoCompleteRef.current = new window.google.maps.places.Autocomplete(document.getElementById('business_address'), options);
                autoCompleteRef.current.addListener('place_changed', handlePlaceSelect);
            }
        }
    }, [currentUser, currentBusiness, promoSettings, autoCompleteRef.current]);

    //Navigation Title Panel
    const panelTitle = () => {
        return (
            <div className="dashboard-titlePanel">
                <span>
                    <IoIosArrowDroprightCircle />
                </span>
                <span className="dashboard-title">
                    { currentBusiness.name } Settings
                </span>
            </div>
        );
    }

    const handleButtonClick = (e) => {
        const logoInput = document.getElementById('logo');
        if (logoInput) {
            logoInput.click();
        }
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
        setPreview(URL.createObjectURL(e.target.files[0]));
    };

    //Update Business
    function submitChanges () {

        //Get the Business ID
        const bid = currentBusiness._id;

        //Make sure Current Number of Employees is a number
        if (numEmployees.length > 0 && isNaN(numEmployees)) {

            Swal.fire({
                icon: 'error',
                title: 'Current Number of Employees must be a number',
                text: 'Please enter a valid number of employees',
            });

            return;
        }
        else {
            
            //Send details to server
            dispatch(updateBusiness(bid, name, address, city, state, country, latitude, longitude, description, story, website, numEmployees, promoExpense, promoDescription, image, navigate));
        }
    };

    const handlePlaceSelect = () => {

        const addressObject = autoCompleteRef.current.getPlace();
        const address = addressObject.address_components;

        if (address) {

            //console.log(addressObject.formatted_address);
            //Set the address
            setAddress(addressObject.formatted_address);

            //Get the city and state
            for (let i = 0; i < address.length; i++) {
                if (address[i].types.includes('locality')) {
                    setCity(address[i].long_name);
                }
                if (address[i].types.includes('administrative_area_level_1')) {
                    setState(address[i].long_name);
                }
                if (address[i].types.includes('country')) {
                    setCountry(address[i].long_name);
                }
            }

            setLatitude(addressObject.geometry.location.lat());
            setLongitude(addressObject.geometry.location.lng());
        }

        /*const place = autoCompleteRef.current.getPlace();
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
    
        setLatitude(latitude);
        setLongitude(longitude);*/
    };

    //Settings Panel
    function Settings () {

        return (
            <div className="settings-panel">
                {/* Get Business Logo */}
                <div className='logo-panel'>
                    <input
                        type="file"
                        id="logo"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{display: 'none'}} // Hide the default file input
                    />
                
                    {preview ? 
                            <div style={{cursor: 'pointer'}} className='logo-layout' onClick={handleButtonClick}>
                                <img src={preview} alt="preview" className="profileImage" />
                                <div className="logoButtonWrapper">
                                    <Button className="profileTextButton">Update Business Logo</Button>
                                </div>
                            </div>
                        :   <div style={{cursor: 'pointer'}} className='logo-layout' onClick={handleButtonClick}>
                                <img className="profileImage" src={UploadProfile} /> 
                                <div className="logoButtonWrapper">
                                    <Button className="profileTextButton">Update Business Logo</Button>
                                </div>
                            </div>
                    }  
                </div>

                {/* Business Name */}
                <div className='settingsInputBox'>
                    <SettingsInput name="name" label="Business Name" value={name} setProperty={setName} />
                </div>

                {/* Business Address */}
                <div className='settingsInputBox'>
                    <TextField
                        id='business_address'
                        label="Business Address"
                        color='secondary'
                        className='input'
                        inputRef={inputRef}
                        defaultValue={address}
                    />
                </div>

                {/* City */}
                <div className='settingsInputBox'>
                    <SettingsInput name="city" label="City" value={city} setProperty={setCity} />
                </div>

                {/* State */}
                <div className='settingsInputBox'>
                    <SettingsInput name="state" label="State" value={state} setProperty={setState} />
                </div>

                {/* Country */}
                <div className='settingsInputBox'>
                    <SettingsInput name="country" label="Country" value={country} setProperty={setCountry} />
                </div>

                {/* Business Website */}
                <div className='settingsInputBox'>
                    <SettingsInput name="website" label="Website" value={website} setProperty={setWebsite} />
                </div>

                {/* Business Description */}
                <div className='settingsInputBox'>
                    <SettingsInput name="description" label="Business Description" value={description} setProperty={setDescription} />
                </div>

                {/* Business Story */}
                <div className='settingsInputBox'>
                    <SettingsInput name="story" label="Owner's Story" value={story} setProperty={setStory} />
                </div>

                <hr className='hr-style' />

                {/* Current Number of Employees */}
                <div className='settingsInputBox'>
                    <SettingsInput name="employees" label="Current Number of Employees" value={numEmployees} setProperty={setNumEmployees} />
                </div>

                {/* Promo Settings */}
                <div className='settingsInputBox'>
                    $<SettingsInput name="promo_expense" label="Promotion Expense" value={promoExpense} setProperty={setPromoExpense} />
                </div>

                {/* Promo Settings*/} 
                <div className='settingsInputBox'>
                    <SettingsInput name="promo_description" label="Promotion Description" value={promoDescription} setProperty={setPromoDescription} />
                </div>
                <div className='settingsButtonPanel'>
                    <Button variant="contained" className="settingsButton" onClick={submitChanges}>Update Business</Button>
                </div>
            </div>
        );
    }

    //Panel to display content depending on if the user prefers the IPO Panel or Dashboard Panel
    function ContentPanel() {

        return (
            <div className="dashboard">
                <Menu />
                <Content />
            </div>
        );
    }

    //Content Panels
    function Content() {

        return (
            <div className="dashboard-content">
                { panelTitle() }
                <div className="dashboard-content-panel">
                    { Settings() }
                </div>
               
            </div>            
        );
      }

    if (currentBusiness !==null && autoCompleteRef.current !== null) {

        return (
            <div>
                <ContentPanel />
            </div>
        );
    }
    else {
        return (
            <Loading title="Loading Business Settings" />
        )
    }
}

export default BusinessSettings;