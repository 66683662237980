import * as React from 'react';
import { useState, useEffect } from "react";
import Swal from 'sweetalert2';

import '../../css/businessIPO.css';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { approveIPOPartner } from '../../redux/businessActions';

//Icons
import { IoIosArrowDroprightCircle, IoMdPerson } from "react-icons/io";
import { ImCheckmark2 } from "react-icons/im";

import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { MdOutlineLeaderboard } from "react-icons/md";

//API
import { smpAPI } from '../../api/smpAPI';

//Search Components
import UserSearch from './userLookupComponent/userSearch';

import Loading from '../userComponent/loadingComponent/loadingComponent'

//isMobile
import { isMobile } from 'react-device-detect';

//Google Anayltics
import ReactGA from 'react-ga4';

function AcceptedIPOPartners({ contentPanel }) {

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);
    const currentBusiness = useSelector((state) => state.business.currentBusiness);

    const [pendingOffers, setPendingOffers] = useState([]);
    const [ipoPartners, setIPOPartners] = useState([]);
    const [status, setStatus] = useState('loading');

    useEffect(() => {

        //Google Analytics
        ReactGA.send({
            hitType: 'pageview',
            title: 'IPO Page',
        })

        const fetchIPO = async () => {

            //Get Business Categories
            try {
                const response = await smpAPI.getIPODetails(currentBusiness._id);
                return response.data;
            } catch (error) {
                console.error(error);
            }
        }

        const fetchData = async () => {
            const {pendingOffers, ipoPartners} = await fetchIPO();

            //Set the Pending Offers
            setPendingOffers(pendingOffers);

            //Set the IPO Partners
            setIPOPartners(ipoPartners);

            //Set status to complete
            setStatus('complete');
        };

        fetchData();

    }, []); 

    //Navigation Title Panel
    const panelTitle = () => {
        return (
            <div className="dashboard-titlePanel">
                <span>
                    <IoIosArrowDroprightCircle />
                </span>
                <span className="dashboard-title">
                    <span style={{cursor: 'pointer'}} onClick={() => contentPanel(false)}>
                        {currentBusiness.name}
                    </span>
                    {`> IPO Partners`}
                </span>
            </div>
        );
    }


    /** Offers from Partners **/
    const offersFromPartners = () => {

        return (
            <div className="background-panel">
                <div className='background-panel-title'>
                    Offers from Potential Partners
                </div>
                {pendingOffers.map((partner, index) => (
                    <div key={index} className='ipolist-panel'>
                        <div className='partnerPanel'>
                            <img src={partner.partnerAvatar} className='partnerAvatar'/>
                            <div className='partnerInfo'>
                                <span className='partnerName'>{ partner.partnerName }</span>
                                <span className='Date'>{ partner.partnerDistance } km from business</span>
                                <span className='Date'>{ new Date(partner.offerDate).toLocaleDateString() }</span>
                            </div>
                            <div className='partnerButtonsPanel' >
                                <button className='button' style={{backgroundColor: 'green'}} onClick={() => handleApprove(partner.partnerId)}>
                                    Approve
                                </button>
                                <button className='button' style={{backgroundColor: 'red'}}>
                                    Deny
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )

    };

    /** Confirmed Partners **/
    const confirmedPartners = () => {
        
        return (
            <div className="background-panel">
                <div className='background-panel-title'>
                    Confirmed IPO Partners
                </div>
                {ipoPartners.map((partner, index) => (
                    <div key={index} className='ipolist-panel'>
                        <div className='partnerPanel'>
                            <img src={partner.partnerAvatar} className='partnerAvatar'/>
                            <div className='partnerInfo'>
                                <span className='partnerName'>{ partner.partnerName }</span>
                                <span className='Date'>{ new Date(partner.confirmDate).toLocaleDateString() }</span>
                            </div>
                            <div className='partnerButtonsPanel' >
                                <button className='button' style={{backgroundColor: 'green'}}>
                                    <ImCheckmark2 /> Approved
                                </button>
                                {currentBusiness.partnerships.some(p => p.partnerId === partner.partnerId && p.status === 'pending') && (
                                    <button className='button' style={{backgroundColor: 'red'}}>
                                        Revoke
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    };

    /** User Lookup Panel **/
    const userLookupPanel = () => {

        return (
            <div className="background-panel">
                <div className='background-panel-title'>
                    User Lookup
                </div>
                <div className='ipolist-panel'>
                    <div className='partnerPanel'>
                        <UserSearch business={currentBusiness} />
                    </div>
                </div>
            </div>
        )
    }


    /** Handles the submission to the server, taking in the partnerId and businessId */
    const handleApprove = async (partnerId) => {

        dispatch(approveIPOPartner(currentBusiness._id, partnerId));
    };

    if (status === 'complete') {
        return (
            <div className="dashboard-content">
                { panelTitle() }
                { offersFromPartners() }
                { confirmedPartners() }
                { userLookupPanel() }
            </div>            
        );
    }
    else {
        return (
            <div className="dashboard-content">
                 <Loading title="Loading Business Dashboard" />
            </div>
           
        )
    }
}

export default AcceptedIPOPartners;