import * as React from 'react';
import { useState, useEffect } from "react";
import { smpAPI } from '../../../api/smpAPI';

import '../../../css/dashBoard.css';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { tokenLoginUser, cancelNotification } from '../../../redux/userActions'; // Import actions

//Navigate
import { useNavigate } from 'react-router-dom';

//Menu Component
import Menu from '../menuComponent/menuComponent';

//Supporting Components
import MixedGraph from './mixedGraphComponent';
import DataTable from './dataTableComponent';
import Notifications from './notificationTableComponent';
import Events from './eventsTableComponent';
import IPOStatus from './ipoStatusTableComponent';
import OpenOrders from './openOrdersTableComponent';
import PaymentModal from '../../paymentComponent/paymentModal';
import Loading from '../loadingComponent/loadingComponent';

//Icons
import { IoIosArrowDroprightCircle, IoMdPerson } from "react-icons/io";
import { GiPayMoney, GiReceiveMoney, GiMoneyStack } from "react-icons/gi";
import { FaHandshake } from "react-icons/fa";
import { BsPiggyBank } from "react-icons/bs";
import { BsFillPiggyBankFill } from "react-icons/bs";
import { AiOutlineGlobal } from "react-icons/ai";
import { MdOutlineLeaderboard } from "react-icons/md";
import { GrTransaction } from "react-icons/gr";

//Material UI Pagination
import TablePagination from '@material-ui/core/TablePagination';
import Swal from 'sweetalert2';

//Mobile?
import {isMobile} from 'react-device-detect';

//Google Analytics
import ReactGA from 'react-ga4';

function Portfolio(props) {

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    // Add these state variables at the top of your component
    const itemsPerPage = 5;  
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    //UseEffect to catch the modal props
    useEffect(() => {

        ReactGA.send({
            hitType: 'pageview',
            page: "/dashboard",
            title: 'Portfolio',
        })

        const getUser = async () => {
            const token = localStorage.getItem('AlliesAccessToken');
            if (token) {
                
                //Check the token
                dispatch(tokenLoginUser(token));
            }
            else {
                navigate('/login');
            }
        }

        //If the user is not loaded, load the user
        if (currentUser === null) {
        
            const fetchUser = async () => {
                await getUser();
            };

            fetchUser();
        } else {
            //Cancel Notifications
            if (props.notify) {
                dispatch(cancelNotification(props.notify));

                //redirect to stop re-rendering
                navigate('/');
            }
            if (props.modal) {

                if (props.modal === 'success') {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Deposit Successful',
                    }).then(() => {
                        navigate('/'); 
                    });

                }
                else if (props.modal === 'failed') {
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed',
                        text: 'Deposit Failed',
                    }).then(() => {
                        navigate('/'); 
                    });
                }
                else if (props.modal === 'deposit') {
                    //Open the Modal
                    handleOpenModal();
                }
                else {
                    //Remove the Notification
                    //notification();
                    dispatch(cancelNotification(props.modal));

                    //Navigate to the Business
                    navigate(`/deposit`);
                }
            }
        }
    }, [currentUser]);

    //Navigation Title Panel
    const panelTitle = () => {
        
        return (
            <div className="dashboard-titlePanel">
                <span>
                    <IoIosArrowDroprightCircle />
                </span>
                <span className="dashboard-title">
                    Portfolio
                </span>
            </div>
        );
    }

    //Dashboard Wallet
    const wallet = () => {

        return (
            <div className="dashboard-balance-content">
                <div className="dashboard-balance-content-item-amount">
                    ${ currentUser.balance.toFixed(2) }
                </div>
                <div className="dashboard-balance-content-item-title">
                    Current Balance
                </div>
                <div className='dashboard-balance-content-item-buttons'>
                    <button className='dashboard-balance-content-item-deposit-button' onClick={makeStripePayment}>
                        Deposit
                    </button>
                    <button className='dashboard-balance-content-item-withdraw-button'>
                        Withdraw
                    </button>
                </div>
            </div>
        );
    }

    /******************************  Stripe *************************************/
    const makeStripePayment = async() => {

        handleOpenModal();
    }

    function handlePaymentInputChange(event) {
        console.log('Input changed:', event.target.value);
    }

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    
    /**************************** End Stripe ***********************************/

    //Dashboard Investment Stats
    const investStats = () => {

        /** Current Valuation and Divident */
        //Iterate throught currentUser.investments and calculate the total investment from each curValuation field
        var curValuation = 0;
        var curDividends = 0;

        currentUser.investments.forEach((investment) => {
            if (investment.isActive) {
                curValuation += investment.curValuation;
                curDividends += investment.curDividend;
            }
        });
        
        /** Lifetime Dividend earnings */
        //Filter through the currentUser.orders for type is "dividend" and sum all the "prices"
        var allTimeDividends = currentUser.orders.filter((order) => order.type === "dividend").reduce((acc, order) => acc + order.price, 0);

        /** Lifetime Profit earnings, including Dividends */
        //Calulate the profit of each investment by going through the currentUser.order array and looking for the type "sell", then reversing the array to find any "buy" orders with the corresponding similar businessId
        var profit = allTimeDividends;

        //Clone the orders array
        var clonedOrders = currentUser.orders.slice();

        //Get the fee order
        var feeOrder = clonedOrders.find((order) => order.type === "fee");

        for (let i = 0; i < clonedOrders.length; i++) {
            if (clonedOrders[i].type === "sell") {
                for (let j = i; j > -1; j--) {
                    //console.log(clonedOrders[j]);
                    if ((clonedOrders[j].type === "buy" || clonedOrders[j].type === "ipo") && clonedOrders[j].businessId === clonedOrders[i].businessId) {
                        profit += clonedOrders[i].price - clonedOrders[j].price - feeOrder.price;
                        break;
                    }
                }
            }
        }

        /*clonedOrders.filter((order) => order.type === "sell").reverse().forEach((order) => {
            var buyOrder = currentUser.orders.slice().find((buyOrder) => (buyOrder.type === "buy" || buyOrder.type === "ipo") && buyOrder.businessId === order.businessId);
            var feeOrder = currentUser.orders.slice().find((buyOrder) => (buyOrder.type === "fee") && buyOrder.businessId === order.businessId);

            if (buyOrder) {
                console.log("Set");
                console.log(buyOrder);
                console.log(order);
                console.log(feeOrder);
                profit += order.price - buyOrder.price - feeOrder.price;

                //Remove the buyOrder from the clonedOrders array
                clonedOrders = clonedOrders.filter((order) => order._id !== buyOrder._id);

                //Remove the feeOrder from the clonedOrders array
                clonedOrders = clonedOrders.filter((order) => order._id !== feeOrder._id);

                //Remove the sellOrder from the clonedOrders array
                clonedOrders = clonedOrders.filter((order) => order._id !== order._id);
            }
        });*/

        return (
            <div className="dashboard-balance-content">
                <div className="dashboard-stats-content-item-amount">
                    ${ curValuation.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                </div>
                <div className="dashboard-stats-content-item-title" style={{color: "#D30C7B" }}>
                    Current Valuation
                </div>
                <div className="dashboard-stats-content-item-amount">
                    ${ curDividends.toFixed(2) }
                </div>
                <div className="dashboard-stats-content-item-title" style={{color: "#574D68" }}>
                    Current Dividends recieving
                </div>
                <div className="dashboard-stats-content-panel-sub">
                    <div className='dashboard-stats-content-panel-sub-sub'>
                        <div className="dashboard-stats-content-item-amount-sub" style={{textAlign: 'left'}}>
                            <span>${ profit.toFixed(2) }</span>
                        </div>
                        <div className="dashboard-stats-content-item-title-sub" style={{color: "#D30C7B", textAlign: 'left'}}>
                            Lifetime Profit
                        </div>
                    </div>
                    <div className='dashboard-stats-content-panel-sub-sub'>
                        <div className="dashboard-stats-content-item-amount-sub" style={{textAlign: 'right'}}>
                            ${ allTimeDividends.toFixed(2) }
                        </div>
                        <div className="dashboard-stats-content-item-title-sub" style={{color: "#574D68", textAlign: 'right'}}>
                            Lifetime Dividend Earnings
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    //Dashboard Investment Stats Continued
    const investStatsContd = (title, orders, investments) => {
        
        var orders_stats = [];
        var investments_stats = [];

        if (title === 'Monthly Stats') {

            //From the orders array, only get the orders with orderDate from this Month
            orders_stats = orders.filter((order) => {
                var orderDate = new Date(order.orderDate);
                return orderDate.getMonth() === new Date().getMonth();
            });

            //From the investments array, only get the investments with isActive set as true
            investments_stats = investments.filter((investment) => investment.isActive);
        } else {
            orders_stats = orders;
            investments_stats = investments;
        }

        /** Calculate Number of Investments */
        //Get all the elements in order_stats where the type is "buy" or "ipo" and count them
        const investment_quantity = orders_stats.filter((order) => (order.type === "buy" || order.type == "ipo")).length;

        /** Calculate Number of Dividend Payouts */
        //Get all the elements in order_stats where the type is "dividend" and count them
        const dividend_quantity = orders_stats.filter((order) => order.type === "dividend").length;

        /** Calculate Shortest Investment Period */
        var shortestTime = 0;

        currentUser.orders.filter((order) => order.type === "sell").forEach((order) => {
            var buyOrder = currentUser.orders.slice().reverse().find((buyOrder) => (buyOrder.type === "buy" || buyOrder.type === "ipo") && buyOrder.businessId === order.businessId);
            if (buyOrder) {
                
                //Calculate the time different in Days, Hours and Minutes
                const sellDate = new Date(order.orderDate);
                const buyDate = new Date(buyOrder.orderDate);

                var diffMs = Math.abs(sellDate - buyDate); // difference in milliseconds
                const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24)); // convert to days
                diffMs -= diffDays * (1000 * 60 * 60 * 24);

                const diffHours = Math.floor(diffMs / (1000 * 60 * 60)); // convert remaining milliseconds to hours
                diffMs -= diffHours * (1000 * 60 * 60);

                const diffMinutes = Math.floor(diffMs / (1000 * 60)); // convert remaining milliseconds to minutes
                
                // Create a string to display the result
                const timeDifference = `${diffDays} days, ${diffHours} hours, ${diffMinutes} minutes`;
            
                if (shortestTime === 0) {
                    shortestTime = timeDifference;
                } else {
                    if (timeDifference < shortestTime) {
                        shortestTime = timeDifference;
                    }
                }
            }
        });

        /** Calculate the Highest Dividend Payout */
        //Get all the elements in order_stats where the type is "dividend" and find the highest price field
        const dividend_quality = orders_stats.filter((order) => order.type === "dividend").reduce((acc, order) => {
            if (order.price > acc) {
                return order.price;
            }
            return acc;
        }, 0);

        /** Get the number of owner repurchases */
        //Get all the elements in order_stats where the type is "owner_repurchase" and count them
        const owner_repurchase_quantity = orders_stats.filter((order) => order.type === "owner_repurchase").length;

        /** Get the number of promotions scanned */
        //Iterate through the investments_stats array and count the number of scans
        var scans_quantity = 0;
        
        if (title === 'Monthly Stats') {
            scans_quantity = investments_stats.reduce((sum, investment) => sum + investment.numPromotionsThisMonth, 0);
        } else {
            //Keep track of the all the businessId so we do not duplicate the count
            var businessId = [];

            //Iterate through the investments_stats array and count the number of scans
            investments_stats.forEach((investment) => {
                if (!businessId.includes(investment.businessId)) {
                    scans_quantity += investment.numPromotionsAllTime;
                    businessId.push(investment.businessId);
                }
            });            
        }

        return (
            <div className="dashboard-balance-content">
                <div className="dashboard-stats-content-panel-sub">
                    <div className="dashboard-stats-content-panel-sub-title">
                        { title }
                    </div>
                </div>
                <div className="dashboard-stats-content-panel-sub">
                    <div className='dashboard-stats-content-panel-sub-sub'>
                        <div className="dashboard-stats-content-item-amount-sub" style={{textAlign: 'left'}}>
                            { investment_quantity }
                        </div>
                        <div className="dashboard-stats-content-item-title-sub" style={{color: "#6F1A07", textAlign: 'left'}}>
                            # of Investments Made
                        </div>
                    </div>
                    <div className='dashboard-stats-content-panel-sub-sub'>
                        <div className="dashboard-stats-content-item-amount-sub" style={{textAlign: 'right'}}>
                            { dividend_quantity}
                        </div>
                        <div className="dashboard-stats-content-item-title-sub" style={{color: "#077187", textAlign: 'right'}}>
                            # of Dividend Payouts
                        </div>
                    </div>
                </div>
                <div className="dashboard-stats-content-panel-sub">
                    <div className='dashboard-stats-content-panel-sub-sub'>
                        <div className="dashboard-stats-content-item-amount-sub" style={{textAlign: 'left'}}>
                            { shortestTime }
                        </div>
                        <div className="dashboard-stats-content-item-title-sub" style={{color: "#6F1A07", textAlign: 'left'}}>
                            Shortest Investment Period
                        </div>
                    </div>
                    <div className='dashboard-stats-content-panel-sub-sub'>
                        <div className="dashboard-stats-content-item-amount-sub" style={{textAlign: 'right'}}>
                            ${ dividend_quality }
                        </div>
                        <div className="dashboard-stats-content-item-title-sub" style={{color: "#077187", textAlign: 'right'}}>
                            Highest Dividend Payout
                        </div>
                    </div>
                </div>
                <div className="dashboard-stats-content-panel-sub">
                    <div className='dashboard-stats-content-panel-sub-sub'>
                        <div className="dashboard-stats-content-item-amount-sub" style={{textAlign: 'left'}}>
                            { owner_repurchase_quantity }
                        </div>
                        <div className="dashboard-stats-content-item-title-sub" style={{color: "#6F1A07", textAlign: 'left'}}>
                            # of Owner Repurchases
                        </div>
                    </div>
                    <div className='dashboard-stats-content-panel-sub-sub'>
                        <div className="dashboard-stats-content-item-amount-sub" style={{textAlign: 'right'}}>
                            { scans_quantity }
                        </div>
                        <div className="dashboard-stats-content-item-title-sub" style={{color: "#077187", textAlign: 'right'}}>
                            # Promo Scanned
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    //Dashboards with settings 
    const settings = (url, icon) => {

        if (icon === 'leaderboards') {
            return (
                <div className='dashboard-settings-panel'>
                    <div>
                        <MdOutlineLeaderboard className='iconSizing'/>
                    </div>
                    <div className='dashboard-settings-title'>
                        Leaderboards
                    </div>
                </div>
            );
        }
        else if (icon === 'settings') {
            return (
                <div className='dashboard-settings-panel' onClick={() => navigate('/userSettings')}>
                    <div>
                        <IoMdPerson className='iconSizing' style={{color: '#255F85'}}/>
                    </div>
                    <div className='dashboard-settings-title'>
                        Profile Settings
                    </div>
                </div>
            );
        }
    }

    //Pagination
     const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    //Pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 5));
        setPage(0);
    };

    //Dashboard Transaction History
    const transactionHistory = () => {

        const history = currentUser.orders.slice().reverse().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, index, array) => {

            var backgroundColor = '';
            var typeLabel = '';

            //Set the background color based on the order type
            if (order.type === 'sell') {
                backgroundColor = 'rgba(194, 234, 189, 0.6)';
                typeLabel = 'Membership Sell';
            } 
            else if (order.type === 'buy') {
                backgroundColor = 'rgba(102, 63, 70, 0.2)';
                typeLabel = 'Membership Purchase';
            }
            else if (order.type === 'dividend') {
                backgroundColor = 'rgba(229, 211, 82, 0.4)';
                typeLabel = 'Dividend Issued';
            }
            else if (order.type === 'ipo') {
                backgroundColor = 'rgba(80, 216, 215, 0.4)';
                typeLabel = 'Bought IPO Share';
            }
            else if (order.type === 'fee') {
                backgroundColor = 'rgba(59, 142, 165, 0.35)';
                typeLabel = 'Transaction Fee';
            }
            else if (order.type === 'deposit') {
                backgroundColor = 'rgba(32, 191, 85, 0.35)';
                typeLabel = 'Deposit';
            }
            else if (order.type === 'withdrawal') {
                backgroundColor = 'rgba(146, 20, 12, 0.2)';
                typeLabel = 'Withdrawal';
            }
            else if (order.type === 'owner_repurchase') {
                backgroundColor = 'rgba(194, 234, 189, 0.6)';
                typeLabel = 'Owner Repurchase';
            }

            return (
                <div key={order._id} style={{ backgroundColor: backgroundColor, borderRadius: '1rem', paddingTop: '1px', margin: '0 1rem' }}>
                    <div className='dashboard-history-content-date'>
                        { 
                            //Format Date
                            new Date(order.orderDate).toDateString()
                        }
                    </div>
                    <div className="dashboard-history-content-item" >
                        <div className="dashboard-history-content-item-org-icon">
                            { 
                                order.type === "sell" || order.type === "owner_repurchase" ? <GiReceiveMoney /> :
                                order.type === "buy" ? <GiPayMoney /> :
                                order.type === "dividend" ? <GiMoneyStack /> :
                                order.type === "ipo" ? <FaHandshake /> :
                                order.type === "fee" ? <GrTransaction /> :
                                order.type === "deposit" ? <BsFillPiggyBankFill /> :
                                order.type === "withdrawal" ?  <BsPiggyBank /> :
                                <AiOutlineGlobal />
                            }
                        </div>
                        <div className="dashboard-history-content-item-org-col">
                            <div className="dashboard-history-content-item-name">
                                { (order.businessName !== null && order.businessName !== undefined) ? order.businessName : 'None' }
                            </div>
                            <div className="dashboard-history-content-item-type">
                                { typeLabel }
                            </div>
                        </div>
                        <div className="dashboard-history-content-item-transaction-col">
                            <div className="dashboard-history-content-transaction-title">
                                Transaction Amount:
                            </div>
                            {
                                (order.type === "buy" || order.type === "withdrawal" || order.type === "fee" || order.type === 'ipo') ? 
                                <div className="dashboard-history-content-transaction-amount-debit">
                                    ${ order.price.toFixed(2) }
                                </div> : 
                                <div className="dashboard-history-content-transaction-amount-credit">
                                    ${ order.price.toFixed(2) }
                                </div>
                            }
                        </div>
                    </div>
                    <hr className='hr-style' />
                </div>
            );
        });

        return (
            <React.Fragment>
                { history }
                <TablePagination
                        component="div"
                        count={currentUser.orders.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]} // Only one option for rows per page
                />
               
            </React.Fragment>
        );
    }

    //Dashboard Top Section with Balance and Macro View with Settings
    const topPanel = () => {
    
        if (isMobile) {
            return (
                <div>
                    <div className='dashboard-two-panel'>
                        <div className='dashboard-balance-section'>
                            { wallet() }
                        </div>
                        <div className='dashboard-balance-section'>
                            { investStats() }
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <React.Fragment>
                    <div className='dashboard-balance-section'>
                        { wallet() }
                    </div>
                    <div className='dashboard-balance-section'>
                        { investStats() }
                    </div>
                    <div className='dashboard-balance-section'>
                        { investStatsContd('Monthly Stats', currentUser.orders, currentUser.investments) }
                    </div>
                    <div className='dashboard-balance-section'>
                        { investStatsContd('All-Time Stats', currentUser.orders, currentUser.investments) }
                    </div>
                    <div>
                        <div className="dashboard-balance-section">
                            { settings('','settings') }
                        </div>
                        <div className="dashboard-balance-section">
                            { settings('','leaderboards') }
                        </div>
                        
                    </div>
                </React.Fragment>
            );
        }
    }

    //Dashboard Current Investments
    const curInvestments = () => {

        var unreadNotifications = [];
        
        if (currentUser.notifications) {
        
            //Filter currentUser.notifications where the read is false
            unreadNotifications = currentUser.notifications.filter((notification) => notification.read === false);
        }

        if (isMobile) {
            return (
                <div className="dashboard-content-section-valuations">
                    <div className="dashboard-content-section-title">
                        Current Investments
                    </div>
                    <div className='dashboard-content-section-valuations-bottom'>
                        <div className='dashboard-content-section-valuations-graph'>
                            <MixedGraph investments={currentUser.investments} orders={currentUser.orders}/>
                        </div>
                        <div className='dashboard-content-section-valuations-table'>
                            <DataTable investments={currentUser.investments.filter(investment => investment.isActive === true)}/>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <React.Fragment>
                        <div className="dashboard-content-section-valuations">
                            <div className="dashboard-content-section-title">
                                Current Investments
                            </div>
                            <div className='dashboard-content-section-valuations-bottom'>
                                <div className='dashboard-content-section-valuations-graph'>
                                    <MixedGraph investments={currentUser.investments} orders={currentUser.orders}/>
                                </div>
                                {/* Vertical Line separator */}
                                <div className="vertical-line"></div>
                                <div className='dashboard-content-section-valuations-table'>
                                    <DataTable investments={currentUser.investments.filter(investment => investment.isActive === true)}/>
                                </div>
                            </div>
                        </div>
                        <div className='dashboard-content-section-notifications'>
                            <div className="dashboard-content-section-notification-title">
                                Notifications
                            </div>
                            <Notifications data={unreadNotifications}/>
                        </div>
                </React.Fragment>
            );
        }
    }

    //IPO Party acceptances, IPO Status and Open orders
    const miscPanels = () => {

        if (isMobile) {
            return (
                <div className='top-portion-panel'>
                    <div className='dashboard-content-section-notifications'>
                        <div className="dashboard-content-section-notification-title">
                            Events
                        </div>
                        <Events events={currentUser.events.filter(event => event.attending === true && new Date(event.eventDate) > new Date())} />
                    </div>
                    <div className='dashboard-content-section-notifications' style={{ margin: '1rem', backgroundColor: '#A53860'}}>
                        <div className="dashboard-content-section-notification-title">
                            Open Orders
                        </div>
                        <OpenOrders openOrders={currentUser.openOrders} />
                    </div>
                    <div className='dashboard-content-section-notifications' style={{ backgroundColor: '#4C3B4D' }}>
                            <div className="dashboard-content-section-notification-title">
                                IPO Statuses
                            </div>
                            <IPOStatus ipo={currentUser.ipoStatuses.filter(ipo => ipo.status !== 'rejected' && ipo.status !== 'cancelled')} />
                        </div>
                </div>
            )
        }
        else {

            return (
                <div className='top-portion-panel'>
                    <div className='dashboard-content-section-notifications'>
                        <div className="dashboard-content-section-notification-title">
                            Events
                        </div>
                        <Events events={currentUser.events.filter(event => event.attending === true && new Date(event.eventDate) > new Date())} />
                    </div>
                    <div className='dashboard-content-section-notifications' style={{ margin: '0.5rem 0 0.5rem 1rem', backgroundColor: '#A53860'}}>
                        <div className="dashboard-content-section-notification-title">
                            Open Orders
                        </div>
                        <OpenOrders openOrders={currentUser.openOrders} />
                    </div>
                    <div className='dashboard-content-section-notifications' style={{ backgroundColor: '#4C3B4D' }}>
                            <div className="dashboard-content-section-notification-title">
                                IPO Statuses
                            </div>
                            <IPOStatus ipo={currentUser.ipoStatuses.filter(ipo => ipo.status !== 'rejected' && ipo.status !== 'cancelled')} />
                        </div>
                </div>
            )
        }
    }



    //Bottom Dashboard with Transaction History and Misc
    const bottomPanel = () => {

        return (
            <React.Fragment>
                <div className="dashboard-history-section">
                    <div className="dashboard-history-title">
                        Transaction History
                    </div>
                    <div className="dashboard-history-content">
                        { transactionHistory() }
                    </div>
                </div>
                <div className="dashboard-misc-section">
                    { miscPanels() }
                </div>
            </React.Fragment>
        );
    }

    function Dashboard() {

        if (isMobile) {
            
            var unreadNotifications = [];
        
            if (currentUser.notifications) {
            
                //Filter currentUser.notifications where the read is false
                unreadNotifications = currentUser.notifications.filter((notification) => notification.read === false);
            }

            return (
                <div className="dashboard">
                    <Menu />
                    <div className="dashboard-content">
                        { panelTitle() }
                        <div className='dashboard-content-section-notifications'>
                            <div className="dashboard-content-section-notification-title">
                                Notifications
                            </div>
                            <Notifications data={unreadNotifications}/>
                        </div>
                        <div className='dashboard-header-section'> 
                            <div className='dashboard-two-panel'>
                                <div className='dashboard-balance-section'>
                                    { wallet() }
                                </div>
                                <div className='dashboard-balance-section'>
                                    { investStats() }
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-content-section">
                            { curInvestments() }
                        </div>
                        <div className='dashboard-two-panel'>
                            <div className='dashboard-balance-section'>
                                { investStatsContd('Monthly Stats', currentUser.orders, currentUser.investments) }
                            </div>
                            <div className='dashboard-balance-section'>
                                { investStatsContd('All-Time Stats', currentUser.orders, currentUser.investments) }
                            </div>
                        </div>
                        <div className="dashboard-misc-section">
                            { miscPanels() }
                        </div>
                        <div className="dashboard-history-section">
                            <div className="dashboard-history-title">
                                Transaction History
                            </div>
                            <div className="dashboard-history-content">
                                { transactionHistory() }
                            </div>
                        </div>
                        <div className="dashboard-terms-privacy-section">
                            <a href="https://invest.co-owners.ca/terms_of_service.html">Terms of Service</a>
                            <a href="https://invest.co-owners.ca/privacy_policy.html">Privacy Policy</a>
                        </div>
                    </div>
                    <div>
                        <PaymentModal show={showModal} onHide={handleCloseModal} />
                    </div>
                </div>
            );
        }
        else {

            return (
            <div className="dashboard">
                <Menu />
                <div className="dashboard-content">
                    { panelTitle() }
                    <div className='dashboard-header-section'>
                        { topPanel() }
                    </div>
                    <div className="dashboard-content-section">
                        { curInvestments() }
                    </div>
                    <div className="dashboard-footer-section">
                        { bottomPanel()}
                    </div>
                    <div className="dashboard-terms-privacy-section">
                        <a href="https://invest.co-owners.ca/terms_of_service.html">Terms of Service</a>
                        <a href="https://invest.co-owners.ca/privacy_policy.html">Privacy Policy</a>
                    </div>
                </div>
                <div>
                    <PaymentModal show={showModal} onHide={handleCloseModal} />
                </div>
            </div>
            );
        }
      }

    if (currentUser !== null) {
        return (
            <div>
                <Dashboard />
            </div>
        );
    }
    else {
        return (
            <Loading content="Loading User"/>
        );
    }
}

export default Portfolio;