import * as React from 'react';
import { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { makeStyles } from '@material-ui/core/styles';

import '../../css/businessMarket.css';

import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import { Dropzone, FileMosaic } from "@files-ui/react";

//Redux
import { useDispatch } from 'react-redux';
import { updateBusinessMonthEnd } from '../../redux/businessActions'; 

//Is Mobile
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
}));

function Reporting(props) {

    const [grossRevenue, setGrossRevenue] = React.useState('');
    const [expenses, setExpenses] = React.useState('');
    const [files, setFiles] = React.useState([]);
    const businessId = props.businessId;

    //Redux Object
    const dispatch = useDispatch();

    const classes = useStyles();

    const updateFiles = (incommingFiles) => {
        //do something with the files
        //console.log("incomming files", incommingFiles);
        setFiles(incommingFiles);
        //even your own upload implementation
    };
      
    const removeFile = (id) => {
        setFiles(files.filter((x) => x.id !== id));
    };

    //When the user clicks on the Login button
    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (grossRevenue === '') {
            Swal.fire({
                icon: 'error',
                title: 'Revenue error',
                text: 'Revenue field is empty, please enter your email',
            });
            return;
        }
        else if (expenses === '') {
            Swal.fire({
                icon: 'error',
                title: 'Expenses error',
                text: 'Expenses field is empty, please enter your password',
            });
            return;
        }
        else {
            dispatch(updateBusinessMonthEnd(businessId, grossRevenue, expenses, files));
        }
    };

    // Get Month in String
    const getMonth = () => {
        const d = new Date();
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        const n = monthNames[d.getMonth()];
        return n;
    }

    //Render
    return (
        <div className='dashboard-company-reporting'>
            <div className='dashboard-company-valuation-title'>
                Report Required for the month of {getMonth()}
            </div> 
            <div className='dashboard-valuation-panels'>
                <TextField
                    id="gross_revenue"
                    label="Set Gross Revenue for this month"
                    className='reporting_input'
                    value={grossRevenue}
                    onChange={(e)=> setGrossRevenue(e.target.value)}
                    sx={{ 
                        input: { color: 'white' },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white',
                            fontSize: isMobile ? '0.8rem' : '1rem',
                        },
                    }}
                />
                <TextField
                    id="expenses"
                    label="Set Expenses for this month"
                    className='reporting_input'
                    value={expenses}
                    onChange={(e)=> setExpenses(e.target.value)}
                    sx={{ 
                        input: { color: 'white' },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white',
                            fontSize: isMobile ? '0.8rem' : '1rem',
                        },
                    }}
                />
            </div>
            <div>
                <Dropzone
                    onChange={updateFiles}
                    value={files}
                    maxFiles={3}
                    color="white"
                    label={"Monthly Revenue Statement (picture or PDF)"}
                    style={{ width: "70%", margin: "auto", marginTop: "20px", fontSize: isMobile ? '0.8rem' : '1rem'}}
                    //accept="image/*"
                    >
                    {files.map((file) => (
                        <FileMosaic key={file.id} {...file} onDelete={removeFile} info />
                    ))}
                </Dropzone>
            </div>
            <div className='buttonDiv'>
                <button className='button' onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
}

export default Reporting;
