import * as React from 'react';
import { useState, useRef, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

//Images
import Background from '../../assets/images/admin_bg.png';
import UploadProfile from '../../assets/images/profileUpload.png';

//Styling
import '../../css/onBoard.css'

//Redux
import { useDispatch, useSelector } from 'react-redux';
import {  } from '../../redux/userActions'; // Import actions

//Navigate
import { useNavigate } from 'react-router-dom';

//SweetAlert
import Swal from 'sweetalert2';

//API
import { smpAPI } from '../../api/smpAPI';

const KeyCodes = {
    comma: 188,
    enter: 13
};

function OnBoard() {

    //Variables
    const [categories, setCategories] = React.useState([]);
    const [partners, setPartners] = React.useState([]);

    const [name, setName] = React.useState('');
    const [address, setAddress] = React.useState('');

    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    const [category, setCategory] = React.useState('Food & Dining');
    const [description, setDescription] = React.useState('');
    const [story, setStory] = React.useState('');
    const [website, setWebsite] = React.useState('');

    const [revenue, setRevenue] = React.useState('');
    const [numEmployees, setNumEmployees] = React.useState('');
    const [expenses, setExpenses] = React.useState('');

    const [ownerName, setOwnerName] = React.useState('');
    const [partnerId, setPartnerId] = React.useState(''); 

    //Loading after button pressed
    const [loading, setLoading] = useState(false);

    //Tags
    const [tags, setTags] = React.useState([{ id: 'Burger_Joint', text: 'Burger Joint' }]);
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    //Profile pic
    const [image, setImage] = React.useState('');
    const [preview, setPreview] = useState(null);

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    //Google Places API
    const autoCompleteRef = useRef();
    const inputRef = useRef();

    //Navigate
    const navigate = useNavigate();

    const options = {
        types: ['geocode'],
        componentRestrictions: { country: ["ca","us"] }
    };

    const pageStyle = {
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    useEffect(() => {

        const fetchCategories = async () => {
            //Get Business Categories
            try {
                const response = await smpAPI.getBusinessCategories();
                return response.data;
            } catch (error) {
                console.error(error);
            }
        }

        const fetchData = async () => {
            const { categories, partners } = await fetchCategories();

            setCategories(categories);
            setPartners(partners);
        };

        fetchData();

        autoCompleteRef.current = new window.google.maps.places.Autocomplete(document.getElementById('business_address'), options);
        autoCompleteRef.current.addListener('place_changed', handlePlaceSelect);
        
    }, []);

    /*useEffect(() => {
        // This code will run whenever `address` changes
        console.log(address);
    }, [address]);*/

    //Upload to server
    const createBusiness = async () => {
        
        try {
            const response = await smpAPI.createBusiness(
                partnerId,
                name,
                image,
                address,
                city,
                state,
                country,
                latitude,
                longitude,
                website,
                description,
                story,
                category,
                numEmployees,
                revenue,
                expenses
            );

            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Business Created',
                    text: 'Your business has been created successfully',
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/');
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handlePlaceSelect = () => {
        const addressObject = autoCompleteRef.current.getPlace();
        const address = addressObject.address_components;

        if (address) {

            //Set the address
            setAddress(addressObject.formatted_address);

            //Get the city and state
            for (let i = 0; i < address.length; i++) {
                if (address[i].types.includes('locality')) {
                    setCity(address[i].long_name);
                }
                if (address[i].types.includes('administrative_area_level_1')) {
                    setState(address[i].long_name);
                }
                if (address[i].types.includes('country')) {
                    setCountry(address[i].long_name);
                }
            }

            setLatitude(addressObject.geometry.location.lat());
            setLongitude(addressObject.geometry.location.lng());
        }
    }

    //Handle the image change
    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
        setPreview(URL.createObjectURL(e.target.files[0]));
    };


    //When the user clicks on the Submit button
    const handleSubmit = (e) => {
        e.preventDefault();
        
        //Make sure home address is filled in
        if (autoCompleteRef.current.getPlace() === undefined) {

            Swal.fire({
                icon: 'error',
                title: 'Address error',
                text: 'Please enter your home address to match with nearby businesses',
            });
        }

        //If Name is not present on user, then it needs to be filled in
        else if (name.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Name error',
                text: 'Please enter your name',
            });
        }

        //If profile image is not present on user, then it needs to be filled in
        else if (image.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Profile image error',
                text: 'Please upload a profile image, this can be updated.',
            });
        }

        //If Number of Employees is not present on user, then it needs to be filled in
        else if (numEmployees.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Number of Employees error',
                text: 'Please add a number of employees amount',
            });
        }

        //If Gross Revenue is not present on user, then it needs to be filled in
        else if (revenue.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Revenue error',
                text: 'Please add a revenue amount',
            });
        }

        //If expenses is not present on user, then it needs to be filled in
        else if (expenses.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Expenses error',
                text: 'Please add a expense amount',
            });
        }

        //If no partner is selected, then it needs to be filled in
        else if (ownerName.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Owner Name error',
                text: 'Please enter the owner name',
            });
        }

        else {
            //From the partners array object, get the id field of the selected partner based on their name
            const partnerObj = partners.find(partner => partner.name === ownerName);
            setPartnerId(partnerObj.id);

            //Set Loading
            setLoading(true);
            
            //Create the Business
            createBusiness();
        }

    };

    return (
        <div style={pageStyle}>
            <div className='formPanel' style={{height: '100%'}}>
                <div className='title'>Add a Business</div>
                <div className='subTitle'>Create a link a business with a user account</div>

                {/* Get Business Logo */}
                <div className='inputBox'>
                    <input
                        type="file"
                        id="logo"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{display: 'none'}} // Hide the default file input
                    />
                
                    {preview ? 
                            <label htmlFor="logo" style={{cursor: 'pointer'}}>
                                <div className='profileImagePanel'>
                                    <img src={preview} alt="preview" className="profileImage" />
                                    <span className="profileText">Upload Business Logo</span>
                                </div>
                            </label>
                        :   <label htmlFor="logo" style={{cursor: 'pointer'}}>
                                <div className='profileImagePanel'>
                                    <img className="profileImage" src={UploadProfile} /> 
                                    <span className="profileText">Upload the business logo</span>
                                </div>
                            </label>
                    }

                </div>
                   
                {/* Business Owner Name */}
                <div className='inputBox'>
                    <Autocomplete
                        id="autoComplete-name"
                        freeSolo
                        options={partners.map((option) => option.name)}
                        onChange={(event, newValue) => {
                            setOwnerName(newValue);
                        }}
                        renderInput={(params) => 
                            <TextField
                                {...params}
                                id="ownerName"
                                label="Owner Name"
                                color='secondary'
                                className='input'
                            />}
                    />
                </div>

                {/* Business Name */}
                <div className='inputBox'>
                    <TextField
                        id="name"
                        label="Business Name"
                        color='secondary'
                        className='input'
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                    
                <div className='inputBox'>
                    <TextField
                        id="business_address"
                        label="Set Business Address"
                        color='secondary'
                        className='input'
                        inputRef={inputRef}
                    />
                </div>

                {/* Business Website */}
                <div className='inputBox'>
                    <TextField
                        id="website"
                        label="Website"
                        color='secondary'
                        className='input'
                        onChange={(e) => setWebsite(e.target.value)}
                    />
                </div>

                {/* Business Description */}
                <div className='inputBox'>
                    <TextField
                        id="name"
                        label="Business Description"
                        color='secondary'
                        className='input'
                        multiline
                        rows={4}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>

                {/* Business Story */}
                <div className='inputBox'>
                    <TextField
                        id="name"
                        label="Owner's Story"
                        color='secondary'
                        className='input'
                        multiline
                        rows={4}
                        onChange={(e) => setStory(e.target.value)}
                    />
                </div>

                {/* Business Categories Dropdown */}
                <div className='inputBox'>
                    <Select 
                        id="category"
                        color='secondary'
                        className='input'
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        displayEmpty
                    >
                        {categories.map((category) => (
                            <MenuItem value={category}>{category}</MenuItem>
                        ))}
                    </Select>
                </div>
                    
                <div className="financialsPanel">

                    {/* Revenue */}
                    <div className='inputBox'>
                        <TextField
                            id="employees"
                            label="# of Employees"
                            color='secondary'
                            className='input'
                            onChange={(e) => setNumEmployees(e.target.value)}
                        />
                    </div>

                    {/* Revenue */}
                    <div className='inputBox'>
                        <TextField
                            id="revenue"
                            label="Monthly Gross Revenue"
                            color='secondary'
                            className='input'
                            onChange={(e) => setRevenue(e.target.value)}
                        />
                    </div>

                    {/* Revenue */}
                    <div className='inputBox'>
                        <TextField
                            id="expenses"
                            label="Monthly Expenses"
                            color='secondary'
                            className='input'
                            onChange={(e) => setExpenses(e.target.value)}
                        />
                    </div>

                </div>

                <div className='buttonDiv'>
                    <button className='button' onClick={handleSubmit}>Register</button>
                </div>
            </div>
        </div>
    );
}

export default OnBoard;
