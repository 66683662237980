import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

import '../../../css/dashBoard.css'

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { cancelOpenOrder } from '../../../redux/userActions'; // Import actions

//SweetAlert
import Swal from 'sweetalert2';

const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
        },
    },
});

function IPOStatus (props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    //Props
    const openOrders = props.openOrders;

    const columns = [
        { field: 'name', headerName: 'Company', width: 150, renderCell: (params) => (
            <Tooltip title="Click here to cancel this order">
                <span>{params.value}</span>
            </Tooltip>
        )},
        { field: 'status', headerName: 'Status', width: 150, renderCell: (params) => (
            <Tooltip title="Click here to cancel this order">
                <span>{params.value}</span>
            </Tooltip>
        )},
        { field: 'amount', headerName: 'Amount', width: 150},
        { 
            field: 'date', 
            headerName: 'Date', 
            width: 100,  
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            ),
        },
    ];
    
    const rows = openOrders.map((openOrder) => {

        //switch statement to determine the status of the IPO
        let status = '';
        switch (openOrder.type) {
            case 'buyOrder':
                status = 'Buy Order';
                break;
            case 'sellOrder':
                status = 'Sell Order';
                break;
        }

        return {
            id: openOrder._id,
            name: openOrder.businessName,
            status: status,
            amount: "$" + openOrder.amount.toFixed(2),
            date: new Date(openOrder.tradeDate).toLocaleString()
        }
    });
      
    //Function to dispatch cancelling an order
    const handleCancelOrder = (order) => {

        //Give the user a double confirmation before continuing
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to cancel this order?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(cancelOpenOrder(currentUser.id, order.id));
            }
        });
    }

    return <DataGrid 
                className={classes.root}
                columns={columns} 
                rows={rows}
                pageSizeOptions={[10]}
                onRowClick={(row) => { handleCancelOrder(row) }}
                sx={{
                    border: 0,
                    color: 'white',
                    fontWeight: 'bold',
                    '&, [class^=MuiDataGrid]': { border: 'none' },
                }} 
                localeText={{noRowsLabel: 'No Events'}} // Change "No rows" to "No notifications"
           />;
}

export default IPOStatus;