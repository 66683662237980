import * as React from 'react';
import Chart from "react-apexcharts";

/* isMobile */
import { isMobile } from 'react-device-detect';

function ValuationGraph(props) {

  var data = props.data;

  //Remove all the array elements where validate is false
  data = data.filter((item) => item.validated === true);

  //Create the label by getting month and year of each entry
  data.forEach((item) => {
    var date = new Date(item.valDate);
    item.label = date.toLocaleString('default', { month: 'short' }) + " " + date.getFullYear();
    //item.label = date.getMonth() + 1 + "/" + date.getFullYear();
  });

  var colors = [ "#E97451", "blue", "red"];

  var options = {
      series: [
        {
          name: 'Valuation',
          type: 'column',
          data: data.map((item) => item.valuation)
        },
        {
        name: 'Price',
        type: 'line',
        data: data.map((item) => item.pricePerShare)
      }, {
        name: 'Dividends',
        type: 'line',
        data: data.map((item) => item.dividendPerShare)
      }],
      chart: {
      height: 350,
      type: 'line',
    },
    stroke: {
      width: [0, 3]
    },
    title: {
      text: 'Valuation',
      style: {
        color: '#fff',
        fontSize: '25px'
      }
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
      formatter: function (val) {
        return "$" + Number(val).toFixed(2);
      }
    },
    colors: colors,
    labels: data.map((item) => item.label),
    legend: {
      labels: {
        colors: '#fff'
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: true,
        style: {
            colors: '#fff',
            fontSize: '0.8rem',
            cssClass: 'apexcharts-xaxis-label',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Finances ($)',
        style: {
          color: '#fff'
        }
      },
      labels: {
        show: true,
        formatter: function (val) {
          return "$" + Number(val).toFixed(2);
        },
        style: {
            colors: '#fff',
            fontSize: '0.8rem',
            cssClass: 'apexcharts-xaxis-label',
        },
      },
    }
  };

  if (isMobile) {

    return (
      <div className="mixed-chart">
          <Chart
              options={options}
              series={options.series}
              type="line"
              width="100%"
              height={window.innerHeight / 2}
          />
      </div>
    );
  }
  else {

    return (
          <div className="mixed-chart">
              <Chart
                  options={options}
                  series={options.series}
                  type="line"
                  width="90%"
              />
          </div>
    );
  }
}

export default ValuationGraph;