import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';

import '../../../css/dashBoard.css'

import { createTheme, ThemeProvider } from '@mui/material/styles';

//Redux
import { useDispatch } from 'react-redux';
import { buyBackShares } from '../../../redux/businessActions';

//Sweet Alert
import Swal from 'sweetalert2';

//Is Mobile
import { isMobile } from 'react-device-detect';

// Create a custom theme with black text for the pagination component
const theme = createTheme({
    components: {
      MuiTablePagination: {
        styleOverrides: {
          root: {
            color: 'black !important',
          },
        },
      },
    },
});

function UserRevokeTable (props) {

    const business = props.business;
    const partners = props.partners;

    //Get the partners
    const businessId = business._id;
    
    //Redux Object
    const dispatch = useDispatch();

    /**
     * Handles the click event for the event button
     * @param {*} businessId 
     */
    const handleEventClick = async (row) => {

        //Double Confirmation for user
        const { isConfirmed } = await Swal.fire({
            title: 'Are you sure?',
            text: 'You are buying back shares from this user, you will be charged a processing fee!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Buyback!',
            cancelButtonText: 'No, Cancel'
        });

        if (!isConfirmed) {
            return;
        }
        else {
            dispatch(buyBackShares(businessId, row.row.id));
        }
    }

    const columns = [
        { field: 'image', headerName: '', width: isMobile ? null : 150, renderCell: (params) => (
            <img src={params.value} style={{ width: 30, height: 30, borderRadius: 50}} />
        )},
        { field: 'name', headerName: 'Name', width: isMobile ? null : 200 },
        {
            field: 'actions',
            headerName: '',
            sortable: false,
            width: 400,
            disableClickEventBubbling: true,
            renderCell: (params) => {

                return (
                    <div className="cellButtonPanel">
                        <button onClick={() => handleEventClick(params)} className='eventButton'>Buyback Share</button>
                    </div>
                );
            },
        },
    ];
    
    //Create the rows
    const rows = partners.map((partner) => {
        return { id: partner.partnerId, 
                name: partner.name, 
                image: partner.image, 
                };
    });
    
    return (
        <ThemeProvider theme={theme}>
            <DataGrid 
                columns={columns} 
                rows={rows}
                rowHeight={40}
                pageSizeOptions={[10]}
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                }}
                sx={{
                    border: 0,
                    color: 'black',
                    fontWeight: 'bold',
                    '&, [class^=MuiDataGrid]': { border: 'none' },
                    '& .MuiDataGrid-cell': {
                        color: 'black',
                    },
                    '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-root .MuiTablePagination-root': {
                        color: 'black',
                    },
                }} 
                localeText={{noRowsLabel: 'No Partners Currently'}} // Change "No rows" to "No notifications"
           />
        </ThemeProvider>
    );
}

export default UserRevokeTable;