import * as React from 'react';
import { useState, useEffect } from "react";
import Swal from 'sweetalert2';

import '../../../css/businessMarket.css';
import '../../../css/businessPanel.css';

import Modal from '@mui/material/Modal';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { tokenLoginUser, setCurrentUser, confirmAttendance } from '../../../redux/userActions'; // Import actions
import { getBusiness } from '../../../redux/businessActions';

//Menu Component
import Menu from '../menuComponent/menuComponent';
import ValuationGraph from './valuationGraphComponent';
import TradeInput from './tradeInputComponent';
import ActiveOrders from './activeBuyOrdersTableComponent';
import Comments from './businessCommentsComponent';
import AddComment from './addComment';

import Loading from '../loadingComponent/loadingComponent'

//Icons
import { IoIosArrowDroprightCircle } from "react-icons/io";
import IconButton from '@mui/material/IconButton';
import { FaWindowClose } from "react-icons/fa";

//Partnership Leaderboard
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import { smpAPI } from '../../../api/smpAPI';
import { current } from '@reduxjs/toolkit';

//isMobile
import { isMobile } from 'react-device-detect';

function Company(props) {

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    //Get the business
    const [business, setBusiness] = useState(null);

    //Trade Type
    const [tradeType, setTradeType] = useState("Buy");

    //Open and Close the Trade Panel
    const [open, setOpen] = useState(false);
    const [modalHighlight, setModalHighlight] = useState('');

    //Potential Comment
    const [comment, setComment] = useState('');

    useEffect(() => {

        const getUser = async () => {
            const token = localStorage.getItem('AlliesAccessToken');
            if (token) {
                
                //Check the token
                dispatch(tokenLoginUser(token));
            }
        }

        //If the user is not loaded, load the user
        if (currentUser === null) {
        
            const fetchUser = async () => {
                await getUser();
            };

            fetchUser();
        } else {

            const fetchBusiness = async () => {

                //Get Business 
                try {
                    var userId = currentUser.id;
                    
                    if ((currentUser.id === undefined) || (currentUser.id === null)) {
                        userId = currentUser._id;
                    }

                    const response = await smpAPI.getBusinessForInvestor(props.bid, userId, props.notify);

                    return response.data;
                } catch (error) {
                    console.error(error);
                }
            }
    
            const fetchData = async () => {
                const { business } = await fetchBusiness();

                setBusiness(business);

                //Set the user id or _id
                if ((currentUser._id === undefined) || (currentUser._id === null)) {
                    currentUser._id = currentUser.id;
                }

                //Set the User
                setCurrentUser(currentUser);
            };
    
            fetchData(); 
        }
    }, [currentUser]);

    React.useEffect(() => {

        if ((props.modalType === 'buy') || (props.modalType === 'event')) {
            setOpen(true);
        }

        setModalHighlight(props.modalType);
    }, []); // Empty dependency array means this effect runs once on mount

    /***********  Navigation Title Panel ***********/
    const panelTitle = () => {

        return (
            <div className="dashboard-titlePanel">
                <span>
                    <IoIosArrowDroprightCircle />
                </span>
                <span className="dashboard-title">
                    { business.name }
                </span>
            </div>
        );
    }

    /******************************* Left Side *******************************/
    //Last admin confirmed valuation
    function findLastValidated(arr) {
        if (arr.length === 0) {
            return null;
        }
    
        const lastElement = arr[arr.length - 1];
    
        if (lastElement.validated) {
            return lastElement;
        } else {
            return findLastValidated(arr.slice(0, -1));
        }
    }

    //Dashboard Company Vitals
    const vitalsPanel = () => {
    
        return (
            <React.Fragment>
                <div className='dashboard-company-valuation'>
                    { company_vitals() }
                </div>
            </React.Fragment>
        );
    }

    //Dashboard Company Valuation
    const company_vitals = () => {

        const lastValidated = findLastValidated(business.valuation);

        //Get the valuation from array element just before the lastValidated array element
        const lastValidatedIndex = business.valuation.indexOf(lastValidated);
        const previousElement = lastValidatedIndex > 0 ? business.valuation[lastValidatedIndex - 1] : lastValidated;

        //Calculate revenue growth and format as a percentage
        const at_rev_growth = ((lastValidated.gross_revenue - business.valuation[0].gross_revenue)
                            / (business.valuation[0].gross_revenue) * 100).toFixed(2);
    
        //Month to Month growth
        let mtm_rev_growth = 0;

        if (business.valuation.length > 1) {
            mtm_rev_growth = ((lastValidated.gross_revenue - previousElement.gross_revenue)
                            / (previousElement.gross_revenue) * 100).toFixed(2);
        } else {
            mtm_rev_growth = at_rev_growth;
        }

        if (isMobile) {
            return (
                <div>
                    <div className='dashboard-company-valuation-title'>
                        Company Details
                    </div>
                    <div className='company-vital-panels'>
                        <div className='company-panel-detail' style={{flex: '1'}}>
                            <img src={business.logo} className='company-panel-logo' />
                            <div className='company_address'>
                                {business.location.address}
                            </div>
                        </div>
                        <div className='company-panel-detail' style={{flex: '1.5'}}>
                            <div className='dashboard-valuation-panel-title'>
                                Description
                            </div>
                            <div className='company_description'>
                                { business.description }
                            </div>
                        </div>
                        <div className='company-panel-detail' style={{flex: '2.5'}}>
                            <div className='dashboard-valuation-panel-title'>
                                Owner's Story
                            </div>
                            <div className='owner_story'>
                                { business.story }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <>
                    <div className='dashboard-company-valuation-title'>
                        Company Details
                    </div>
                    <div className='company-vital-panels'>
                        <div className='company-panel-detail' style={{flex: '1'}}>
                            <img src={business.logo} className='company-panel-logo' />
                            <div className='company_address'>
                                {business.location.address}
                            </div>
                        </div>
                        <div className='company-panel-detail' style={{flex: '1.5'}}>
                            <div className='dashboard-valuation-panel-title'>
                                Description
                            </div>
                            <div className='company_description'>
                                { business.description }
                            </div>
                        </div>
                        <div className='company-panel-detail' style={{flex: '2.5'}}>
                            <div className='dashboard-valuation-panel-title'>
                                Owner's Story
                            </div>
                            <div className='owner_story'>
                                { business.story }
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }

    //Calulate Changes as a percentage from month to month
    const calculateChange = (current, previous) => {

        const change = ((current - previous) / previous) * 100;

        if (change > 0) {
            return (
                <div className='stats-panel-value-change-positive'>
                    ( + { change.toFixed(2) }% )
                </div>
            );
        } else {
            return (
                <div className='stats-panel-value-change-negative'>
                    ( { change.toFixed(2) }% )
                </div>
            );
        }
    }

    //Company Graph Panel
    const graphPanel = () => {
    
        const lastValidated = findLastValidated(business.valuation);

        const lastValidatedIndex = business.valuation.findIndex(valuation => valuation === lastValidated);
        const priorToLastValidated = lastValidatedIndex > 0 ? business.valuation[lastValidatedIndex - 1] : null;

        console.log("Last Validated");
        console.log(priorToLastValidated);

        if (isMobile) {
            return (
                <div className='dashboard-company-valuation'>
                    <div className='chart'>
                        <ValuationGraph data={business.valuation} />
                    </div>
                    <div className='dashboard-company-valuation-graph-panel'>
                        <div className='stats'>
                            <div className='stats-panel'>
                                <div className='stats-panel-value'>
                                    ${ lastValidated.valuation.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                                </div>
                                {
                                    priorToLastValidated ? 
                                        calculateChange(lastValidated.valuation, priorToLastValidated.valuation)  :
                                        null
                                }
                                <div className='stats-panel-title'>
                                    Current Valuation
                                </div>
                            </div>
                            <div className='stats-panel'>
                                <div className='stats-panel-value'>
                                    ${ lastValidated.dividendPerShare.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                                </div>
                                {
                                    priorToLastValidated ? 
                                        calculateChange(lastValidated.dividendPerShare, priorToLastValidated.dividendPerShare)  :
                                        null
                                }
                                <div className='stats-panel-title'>
                                    Current Dividend
                                </div>
                            </div>
                            <div className='stats-panel'>
                                <div className='stats-panel-value'>
                                    ${ lastValidated.pricePerShare.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                                </div>
                                {
                                    priorToLastValidated ? 
                                        calculateChange(lastValidated.pricePerShare, priorToLastValidated.pricePerShare)  :
                                        null
                                }
                                <div className='stats-panel-title'>
                                    Deed Price
                                </div>
                            </div>
                            <div className='stats-panel'>
                                <div className='stats-panel-value'>
                                    { business.numEmployees[business.numEmployees.length - 1].num }
                                </div>
                                <div className='stats-panel-title'>
                                    No. Employees
                                </div>
                            </div>
                            <div className='stats-panel'>
                                <div className='stats-panel-value'>
                                    { business.valuation.length  }
                                </div>
                                <div className='stats-panel-title'>
                                    Months Active
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className='dashboard-company-valuation'>
                    <div className='dashboard-company-valuation-graph-panel'>
                        <div className='chart'>
                            <ValuationGraph data={business.valuation} />
                        </div>
                        <div className='stats'>
                            <div className='stats-panel'>
                                <div className='stats-panel-value'>
                                    ${ lastValidated.valuation.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                                </div>
                                {
                                    priorToLastValidated ? 
                                        calculateChange(lastValidated.valuation, priorToLastValidated.valuation)  :
                                        null
                                }
                                <div className='stats-panel-title'>
                                    Current Valuation
                                </div>
                            </div>
                            <div className='stats-panel'>
                                <div className='stats-panel-value'>
                                    ${ lastValidated.dividendPerShare.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                                </div>
                                {
                                    priorToLastValidated ? 
                                        calculateChange(lastValidated.dividendPerShare, priorToLastValidated.dividendPerShare)  :
                                        null
                                }
                                <div className='stats-panel-title'>
                                    Current Dividend
                                </div>
                            </div>
                            <div className='stats-panel'>
                                <div className='stats-panel-value'>
                                    ${ lastValidated.pricePerShare.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                                </div>
                                {
                                     priorToLastValidated ? 
                                        calculateChange(lastValidated.pricePerShare, priorToLastValidated.pricePerShare)  :
                                        null
                                }
                                <div className='stats-panel-title'>
                                    Deed Price
                                </div>
                            </div>
                            <div className='stats-panel'>
                                <div className='stats-panel-value'>
                                    { business.numEmployees[business.numEmployees.length - 1].num }
                                </div>
                                <div className='stats-panel-title'>
                                    No. Employees
                                </div>
                            </div>
                            <div className='stats-panel'>
                                <div className='stats-panel-value'>
                                    { business.valuation.length  }
                                </div>
                                <div className='stats-panel-title'>
                                    Months Active
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    //Refresh Page with new comments
    const handleRefresh = async () => {

        //Refresh this current page
        const response = await smpAPI.getBusinessForInvestor(business._id, currentUser._id, false);

        setBusiness(response.data.business);
    }

    //Comments Panel
    const commentsPanel = () => {

        return (
            <div className='dashboard-company-valuation'>
                <div className='dashboard-company-valuation-title'>
                    Comments
                </div>
                <div className='commentsPanel'>
                    <Comments comments={business.comments} />
                </div>
                <AddComment businessId={business._id} authorId={currentUser._id} refreshComments={handleRefresh}/>
            </div>
        );
    }

  

    /******************************* Right Side *******************************/
    //IPO Attendance Confirmation
    const executeConfirmAttendance = async () => {

        //Double Confirmation
        const confirm = await Swal.fire({
            title: 'Confirm Attendance',
            text: 'Are you sure you want to confirm your attendance to the IPO Launch Event?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        });

        if (confirm.isConfirmed) {
            dispatch(confirmAttendance(currentUser._id, business._id));
        }
    }

    //IPO Event Panel
    const ipoEventPanel = () => {

        var ipoConfirmed = false;

        //Check to see if the user has already confirmed attendance
        if (currentUser.events.length > 0) {

            ipoConfirmed = currentUser.events.some((event) => {
                return event.businessId === business._id;
            });
        }

        //Make sure the date is not null
        if ((business.ipoPartyDate !== null) && (business.ipoPartyDate !== undefined)) {

            //Check to see if the date has elapsed
            if (new Date(business.ipoPartyDate) < new Date()) {
                return null;
            }
            else {
                return (
                    <div className='dashboard-company-valuation'>
                        <div className='dashboard-company-valuation-title'>
                            Launch Event
                        </div>
                        <div className='ipoConfirmation'>
                            Event Date: { new Date(business.ipoPartyDate).toLocaleString() }
                        </div>
                        { business.ipoPartyConfirmed > 0 ? 
                            <div className='ipoConfirmation'>
                                Confirmations: { business.ipoPartyConfirmed }
                            </div> : null 
                        }
                        { ipoConfirmed ? 
                            <div className='ipoConfirmation'>
                                You have are confirmed guest to this event
                            </div> : 
                             <button className='attendingButton' onClick={executeConfirmAttendance}>
                                Attend
                            </button>
                        }
                    </div>
                );
            }
        }
        else {
            return null;
        }
    }


    //Buy and Sell Panel
    const tradePanel = () => {

        let shares = false;

        //Check user to see if they already own shares in the business, which then they are not able to buy anymore
        //If they don't have any shares in the business, they can't sell any shares
        const partnerShares = () => {
            return currentUser.investments.some((investment) => {
                if ((business._id !== undefined) || (business._id !== null)) {
                    return investment.businessId === business._id && investment.isActive;
                } else {
                    return investment.businessId === business.id && investment.isActive;
                }
            });
        };

        shares = partnerShares();

        //Toggle between Buy and Sell
        if (shares) {
            setTradeType("Sell");
        }

        //Check to see if the user is the owner of the business
        if (business.ownerId === currentUser._id) {
            return null;
        }
        //No Promotions set
        else if (!business.ipoDate) {
            return null;
        }
        else {
            return (
                <>
                    <div className="dashboard-company-valuation">
                        <div className='dashboard-company-valuation-title'>
                            Trade
                        </div>   
                        <div>
                            <div className="buttonGroupContainer">
                                <ButtonGroup 
                                    variant="outlined"
                                    sx={{ 
                                        color: 'white',
                                        ".MuiButtonGroup-grouped": {
                                            borderColor: "white",
                                            borderWidth: 2,
                                            "&:hover": {
                                            borderColor: "#E97451"
                                            }
                                        },
                                    }}>
                                    <Button 
                                        sx={{ color: "#FFF", fontWeight: 'bold', fontFamily: '"Roboto", "Arial", "sans-serif"', backgroundColor: tradeType === "Buy" ? "#E97451" : "transparent"}}
                                        onClick={ ()=>setTradeType("Buy")} 
                                        disabled={shares ? true : false} 
                                        >Buy</Button>
                                    <Button
                                        sx={{ color: "#FFF", fontWeight: 'bold', fontFamily: '"Roboto", "Arial", "sans-serif"', backgroundColor: tradeType === "Sell" ? "#E97451" : "transparent"}}
                                        onClick={ ()=>setTradeType("Sell") }
                                        disabled={shares ? false : true} 
                                        >Sell</Button>
                                </ButtonGroup>
                            </div>
                            <div>
                                <TradeInput tradeType={tradeType} business={business} userId={currentUser._id} setBusiness={setBusiness} setOpen={setOpen}/>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }

    //Available Buy and Sell Orders Panel
    const tradeOrdersPanel = () => {

        return (
            <div className='dashboard-company-valuation'>
                <div className='dashboard-company-valuation-title'>
                    Open Orders
                </div>
                <div className='openOrdersPanel'>
                    <div className='trade-orders-panel-title'>
                        <ActiveOrders transactions={business.openBuyOrders} columnName="Buy Orders"/>
                    </div>
                    <div className='trade-orders-panel-title'>
                        <ActiveOrders transactions={business.openSellOrders} columnName="Sell Orders"/>
                    </div>
                </div>
            </div>
        );
    }

    //Panel to display content depending on if the user prefers the IPO Panel or Dashboard Panel
    function ContentPanel() {

        return (
            <div className="dashboard">
                <Menu />
                <Content />
            </div>
        );
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //Content Panels
    function Content() {

        if (isMobile) {
            return (
                <div className="dashboard-content">
                    { panelTitle() }
                    <div className="dashboard-content-panel">
                            { vitalsPanel() }
                            { graphPanel() }
                            { ipoEventPanel() }
                            { tradePanel() }
                            { tradeOrdersPanel() }
                            { commentsPanel() }
                    </div>
                </div>            
            );
        }
        else {
            return (
                <div className="dashboard-content">
                    { panelTitle() }
                    <div className="dashboard-content-panel">
                        <div className="dashboard-left-panel">
                            { vitalsPanel() }
                            { graphPanel() }
                            { commentsPanel() }
                        </div>
                        <div className="dashboard-right-panel">
                            { ipoEventPanel() }
                            { tradePanel() }
                            { tradeOrdersPanel() }
                        </div>
                    </div>
                </div>            
            );
        }
      }

    if (business !== null) {
        
        if (modalHighlight === 'buy') {

            return (
                <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                    >
                        <div className="openModalPanel">
                            <IconButton 
                                aria-label="close" 
                                onClick={handleClose} 
                                sx={{ 
                                    position: 'absolute', 
                                    right: 8, 
                                    top: 8, 
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <FaWindowClose />
                            </IconButton>
                            <div className='modal-title'>
                                Trade
                            </div>   
                            <div>
                                <div className="buttonGroupContainer">
                                    <ButtonGroup 
                                        variant="outlined"
                                        sx={{ 
                                            color: 'white',
                                            ".MuiButtonGroup-grouped": {
                                                borderColor: "white",
                                                borderWidth: 2,
                                                "&:hover": {
                                                borderColor: "#E97451"
                                                }
                                            },
                                        }}>
                                        <Button 
                                            sx={{ color: "#FFF", fontWeight: 'bold', fontFamily: '"Roboto", "Arial", "sans-serif"', backgroundColor: tradeType === "Buy" ? "#E97451" : "transparent"}}
                                            >Buy</Button>
                                        <Button
                                            sx={{ color: "#FFF", fontWeight: 'bold', fontFamily: '"Roboto", "Arial", "sans-serif"', backgroundColor: tradeType === "Sell" ? "#E97451" : "transparent"}}
                                            disabled={true} 
                                            >Sell</Button>
                                    </ButtonGroup>
                                </div>
                                <div>
                                    <TradeInput tradeType={tradeType} business={business} userId={currentUser._id} setBusiness={setBusiness} setOpen={setOpen}/>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <ContentPanel />
                </div>
            )
        }

        else if (modalHighlight === 'event') {

            var ipoConfirmed = false;

            //Check to see if the user has already confirmed attendance
            if (currentUser.events.length > 0) {

                ipoConfirmed = currentUser.events.some((event) => {
                    return event.businessId === business._id;
                });
            }

            return (
                <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                    >
                        <div className="openModalPanel">
                            <IconButton 
                                aria-label="close" 
                                onClick={handleClose} 
                                sx={{ 
                                    position: 'absolute', 
                                    right: 8, 
                                    top: 8, 
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <FaWindowClose />
                            </IconButton>
                            <div className='modal-title'>
                                Launch Event
                            </div>   
                            <div className="centerPartyModalPanel">
                                <div className='ipoConfirmation'>
                                    Event Date: { new Date(business.ipoPartyDate).toLocaleString() }
                                </div>
                                { business.ipoPartyConfirmed > 0 ? 
                                    <div className='ipoConfirmation'>
                                        Confirmations: { business.ipoPartyConfirmed }
                                    </div> : null 
                                }
                                { ipoConfirmed ? 
                                    <div className='ipoConfirmation'>
                                        You have are confirmed guest to this event
                                    </div> : 
                                    <button className='attendingButton' onClick={executeConfirmAttendance}>
                                        Attend
                                    </button>
                                }
                            </div>
                        </div>
                    </Modal>
                    <ContentPanel />
                </div>
            )
        }

        else {
            return (
                <div>
                    <ContentPanel />
                </div>
            );
        }
    }
    else {
        return (
            <Loading title="Loading Business" />
        )
    }
}

export default Company;