import * as React from 'react';
import { useState, useEffect } from "react";

//Styling
import '../../../css/search.css';

import { smpAPI } from '../../../api/smpAPI';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { tokenLoginUser } from '../../../redux/userActions'; // Import actions

//Icons
import { IoIosArrowDroprightCircle } from "react-icons/io";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FaSearch } from "react-icons/fa";

//Loading
import Loading from '../loadingComponent/loadingComponent';

//Menu
import Menu from '../../userComponent/menuComponent/menuComponent';

//SearchBar
import SearchBar from './searchBarComponent';

//Material UI Pagination
import TablePagination from '@material-ui/core/TablePagination';

import { Link } from 'react-router-dom';

//isMobile
import { isMobile } from 'react-device-detect';

function SearchBusiness() {

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    const [businesses, setBusinesses] = useState(null);
    
    const [location, setLocation] = useState('');
    
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    let setPages;

    if (isMobile) {
        setPages = 1;
    } else {
        setPages = 5;
    }   


    useEffect(() => {

        const getUser = async () => {
            const token = localStorage.getItem('AlliesAccessToken');
            if (token) {
                
                //Check the token
                dispatch(tokenLoginUser(token));
            }
        }

        const fetchLocalBusinesses = async () => {
            //Get Business Categories
            try {

                const response = await smpAPI.getLocalBusinesses(currentUser.id);
                return response.data;
            } catch (error) {
                console.error(error);
            }
        }

        const fetchData = async () => {
            const { businessData } = await fetchLocalBusinesses();

            setBusinesses(businessData);
        };

        const fetchLocalBusinessesByCoords = async () => {
            //Get Business Categories
            try {
                const response = await smpAPI.getLocalBusinessesByCoords(location.lat, location.lng);
                return response.data;
            } catch (error) {
                console.error(error);
            }
        }

        const fetchDataByCoords = async () => {
            const { businessData } = await fetchLocalBusinessesByCoords();

            setBusinesses(businessData);
        };

        //If the user is not loaded, load the user
        if (currentUser === null) {
        
            const fetchUser = async () => {
                await getUser();
            };

            fetchUser();
        } else {

            if (location !== '') {
                fetchDataByCoords();
            }
            else {
                fetchData();
            }
        }
    }, [currentUser, location]);

    //Pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    //Pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, setPages));
        setPage(0);
    };

    //Navigation Title Panel
    const panelTitle = () => {
        return (
            <div className="dashboard-titlePanel">
                <span>
                    <IoIosArrowDroprightCircle />
                </span>
                <span className="dashboard-title">
                    Search Companies
                </span>
            </div>
        );
    }

    // Helper function to limit a string to a certain number of words
    const limitWords = (str, numWords) => {
        return str.split(" ").splice(0, numWords).join(" ");    
    }

    //Local Businesses Panel
    const LocalBusinesses = () => {

        //Check the array size of businesses
        if ((businesses === null) || (businesses === undefined)) {
            return (
                <div className="no-results">
                    No Local Businesses Found
                </div>
            );
        }
        else if (((businesses !== null) && (businesses !== undefined)) && (businesses.length <= 0)) {
            return (
                <div className="no-results">
                    No Local Businesses Found
                </div>
            );
        }
        else {

            //Pagination
            const pagedItems = businesses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

            return (
                <div>
                    {pagedItems.map((business) => (
                        <Link to={`/stock/${business._id}`}>
                            <div key={business._id} className="business-card">
                                <img src={business.logo} />
                                <div className="business-name">
                                    {business.name}
                                </div>
                                <div className="business-details">
                                    {business.location.address}
                                </div>
                                <div className="business-details">
                                    {limitWords(business.description, 50)}  {/** Limit the description to 50 words */}
                                </div>
                                <div className="business-website">
                                    <a href={business.website} target="_blank" rel="noopener noreferrer">
                                        {business.website}
                                    </a>
                                </div>
                            </div>
                        </Link>
                    ))}
                    <TablePagination
                        component="div"
                        count={businesses.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]} // Only one option for rows per page
                    />
                </div>
            );
            
            //Iterate through the businesses
            /*return businesses.map((business) => {
                return (
                    <div key={business._id} className="business-card">
                        <img src={business.logo} />
                        <div className="business-name">
                            {business.name}
                        </div>
                        <div className="business-details">
                            {business.location.address}
                        </div>
                        <div className="business-details">
                            {limitWords(business.description, 50)}  
                        </div>
                        <div className="business-website">
                            <a href={business.website} target="_blank" rel="noopener noreferrer">
                                {business.website}
                            </a>
                        </div>
                    </div>
                );
            });*/
        }
    }


    //Panel to display content of the page
    function ContentPanel() {

        return (
            <div className="dashboard">
                <Menu />
                <Content />
            </div>
        );
    }

    //Content Panels
    function Content() {

        return (
            <div className="dashboard-content">
                { panelTitle() }
                <div className="dashboard-content-panel">
                    <SearchBar coordinates={setLocation}/>
                </div>
                <div className="dashboard-history-section">
                    <div className="dashboard-history-title">
                        Local Businesses
                    </div>
                    { LocalBusinesses()}
                </div>
            </div>            
        );
    }

    if (currentUser !== null) {
        return (

            <div>
                <ContentPanel />
            </div>
        );
    }
    else {
        return (
            <Loading content="Loading Businesses"/>
        );
    }
   
}

export default SearchBusiness;