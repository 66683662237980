import * as React from 'react';
import { useState } from "react";

import '../../../css/businessMarket.css';
import '../../../css/settings.css';

//Navigate
import { useNavigate } from 'react-router-dom';

//Redux
import { useDispatch, useSelector } from 'react-redux';

import { debounce } from 'lodash';

//Icons
import { IoIosArrowDroprightCircle, IoMdPerson } from "react-icons/io";
//Images
import UploadProfile from '../../../assets/images/profileUpload.png';

//Partnership Leaderboard
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const options = {
    types: ['geocode'],
    componentRestrictions: { country: ["ca","us"] }
};

function SettingsInput(props) {

    const name = props.name;
    const label = props.label;

    const[value, setValue] = useState(props.value);

    const handleBlur = () => {
        props.setProperty(value);
    };

    if (props.setPassword) {

        return (
            <TextField
                id={name}
                label={label}
                color='secondary'
                className='input'
                value={value}
                type="password"
                onChange={(e) => {
                    setValue(e.target.value)
                }}
                onBlur={handleBlur}
            />
        );
    }
    else {
        return (
            <TextField
                id={name}
                label={label}
                color='secondary'
                className='input'
                value={value}
                onChange={(e) => {
                    setValue(e.target.value)
                }}
                onBlur={handleBlur}
            />
        );
    }
}

export default SettingsInput;