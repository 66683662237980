import React, { useState } from 'react';

//Styling
import '../../../css/comments.css';

//MUI
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

//API
import { smpAPI } from '../../../api/smpAPI';

//SweetAlert
import Swal from 'sweetalert2';

//Navigate
import { useNavigate } from 'react-router-dom';

//isMobile  
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles({
    root: {
        textAlign: 'left', // Add this line
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
            width: isMobile ? '90%' : '50vw',
            color: 'white',
        },
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
            borderColor: 'white',
            },
            '&:hover fieldset': {
            borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
            borderColor: 'white',
            },
        },
        '& .MuiInputBase-input': {
            color: 'white', // text color
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid white', // underline color when not focused
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid white', // underline color when hovered
        },
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid white', // underline color when focused
        },
        '& .MuiInputBase-root': {
            '&::placeholder': {
            color: 'lightgray', // placeholder color
            },
        },
        '& .MuiInputLabel-root': {
            color: 'white', // label color
        },
    },
});

function AddComment(props) {

    const [comment, setComment] = useState('');

    //Get the props
    const { businessId, authorId } = props;

    const classes = useStyles();

    const navigate = useNavigate();

    //Submit comment
    const submitComment = async () => {

        if (comment === '') {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Comment cannot be empty!',
            });
            return;
        } 
        else {
            const response = await smpAPI.addComment(businessId, authorId, comment);

            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Comment added successfully!',
                });

                props.refreshComments();
            }
        }
    }

    return (
        <div className='commentPanel'>
            <TextField
                className={classes.root}
                value={comment}
                label="Add a comment..."
                onChange={(event) => setComment(event.target.value)}
                variant="outlined"
                onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                        submitComment();
                        ev.preventDefault();
                    }
                }}
                multiline
                maxRows={4}
            />
            <div className='commentWrapper'>
                <button type="submit" className='commentButton' onClick={submitComment}>
                    Submit
                </button>
            </div>
        </div>
    )
}

export default AddComment;