// src/reducers/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentBusiness: null,
  loading: false, // Flag to indicate user data is being fetched
  error: null, // Store any error that might occur during user actions
};

const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setBusiness(state, action) {
      state.currentBusiness = action.payload;
    },
    setBusinessLoading(state, action) {
      state.loading = action.payload;
    },
    setBusinessError(state, action) {
      state.error = action.payload;
    },
  },
});

export default businessSlice.reducer;
export const { setBusiness, setBusinessLoading, setBusinessError } = businessSlice.actions;