import { smpAPI } from '../api/smpAPI';

//SweetAlert
import Swal from 'sweetalert2';

//User Slice
import { setUser, setLoading, setError } from './reducers/userSlice';

//Business Slice
import { setBusiness, setBusinessLoading, setBusinessError } from './reducers/businessSlice';

/*********************** User Redux *************************/
export const registerUser = (access_token, name, email, password, profilePic) => async (dispatch) => {
    try {
        dispatch(setLoading(true)); // Set loading state to true
        
        smpAPI.register(access_token, name, email, password, profilePic).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 201) {
               
                dispatch(setUser(response.data.user)); // Dispatch action to set current user

                //Store the token in the local storage
                localStorage.setItem('AlliesAccessToken', response.data.tokens.refresh.token);
            }
        }).catch((error) => {

            Swal.fire({
                icon: 'error',
                title: 'Login error',
                text: error.response.data.message,
            });
            //console.log(error.response.data.message);
        });
    } catch (error) {
      dispatch(setError(error.message)); // Dispatch action to store error
    } finally {
      dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};

/**
 * Login User
 * @param {*} email 
 * @param {*} password 
 * @returns 
 */
export const loginUser = (email, password) => async (dispatch) => {
    try {
        dispatch(setLoading(true)); // Set loading state to true

        smpAPI.login(email, password).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 200) {
                    
                dispatch(setUser(response.data.user)); // Dispatch action to set current user
            
                //Store the token in the local storage
                localStorage.setItem('AlliesAccessToken', response.data.tokens.refresh.token);
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Login error',
                text: error.response.data.message,
            });
            //console.log(error.response.data.message);
        });
    } catch (error) {
        dispatch(setError(error.message)); // Dispatch action to store error
    } finally {
            dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};
  
/**
 * Check Browser Storage to auto login user
 * @param {*} token 
 * @returns 
 */
export const tokenLoginUser = (token) => async (dispatch) => {
    try {
        dispatch(setLoading(true)); // Set loading state to true

        smpAPI.checklogintoken(token).then((response) => {
            
            //Check to see if there are any Hubs
            if (response.status === 200) {
                    
                const user = response.data.user;

                if (user._id !== undefined) {
                    user.id = user._id;
                }
                else if (user.id !== undefined) {
                    user._id = user.id;
                }
                
                dispatch(setUser(response.data.user)); // Dispatch action to set current user
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Login error',
                text: error.response.data.message,
            });
            //console.log(error.response.data.message);
        });
    } catch (error) {
        dispatch(setError(error.message)); // Dispatch action to store error
    } finally {
            dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};

/**
 * This function obtains the user's data through a Google login
 * @param {*} email
 */
export const googleLoginUser = (email) => async (dispatch) => {

    try {
        dispatch(setLoading(true)); // Set loading state to true

        smpAPI.checkGoogleLogin(email).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 200) {
                    
                dispatch(setUser(response.data.user)); // Dispatch action to set current user
            
                //Store the token in the local storage
                localStorage.setItem('AlliesAccessToken', response.data.tokens.refresh.token);
            }
        }).catch((error) => {    
            //console.log(error.response.data.error);
            //console.log(error.response.data.message);

            Swal.fire({
                icon: 'error',
                title: 'Login error',
                text: error.response.data.error,
            });
        });
    }
    catch (error) {
        dispatch(setError(error.response.data.error)); // Dispatch action to store error
    }
    finally {
        dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};

/**
 * Onboarding user with more fields to fill in 
 * @param {*} id 
 * @param {*} name 
 * @param {*} password 
 * @param {*} image 
 * @param {*} city 
 * @param {*} state 
 * @param {*} country 
 * @param {*} latitude 
 * @param {*} longitude 
 * @param {*} interests 
 * @returns 
 */
//Onboard User
export const onboardUser = (id, name, password, image, city, state, country, latitude, longitude, interests) => (dispatch) => {

    try {
        dispatch(setLoading(true)); // Set loading state to true

        smpAPI.onboard(id, name, password, image, city, state, country, latitude, longitude, interests).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 200) {
                
                dispatch(setUser(response.data.user)); // Dispatch action to set current user
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Onboarding error',
                text: error.response.data.message,
            });
            //console.log(error.response.data.message);
        });
    } catch (error) {
        dispatch(setError(error.message)); // Dispatch action to store error
    } finally {
            dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};

export const logoutUser = () => (dispatch) => {
    // Implement logout logic (dispatch actions as needed)
};

/********************************* Update User *********************************/
/**
 * Update User
 * @param {*} id
 * @param {*} name
 * @param {*} email
 * @param {*} password
 * @param {*} image
 * @param {*} city
 * @param {*} state
 * @param {*} country
 * @param {*} latitude
 * @param {*} longitude
 * @returns
 **/
export const updateUser = (id, name, email, city, state, country, latitude, longitude, old_password, new_password, image) => (dispatch) => {

    try {
        dispatch(setLoading(true)); // Set loading state to true

        smpAPI.updateUser(id, name, email, city, state, country, latitude, longitude, old_password, new_password, image).then((response) => {
                
                //Check to see if there are any Hubs
                if (response.status === 200) {
                    
                    dispatch(setUser(response.data.user)); // Dispatch action to set current user

                    Swal.fire({
                        title: 'Success',
                        text: 'User updated Successfully',
                        icon: 'success',
                    });
                }
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Update User error',
                    text: error.response.data.message,
                });
                //console.log(error.response.data.message);
            }
        );
    }
    catch (error) {
        dispatch(setError(error.message)); // Dispatch action to store error
    }
    finally {
        dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};

/******************************** Get Portfolio **************************/
export const getPortfolio = (id) => {
    
    return async dispatch => {

        try {
            dispatch(setLoading(true)); // Set loading state to true
            
            const response = await smpAPI.getPortfolio(id);

            //Check to see if there are any Hubs
            if (response.status === 200) {
                
                const data = await response.data;
                dispatch(setUser(response.data.user)); // Dispatch action to set current user
            }
            else {   
                Swal.fire({
                    icon: 'error',
                    title: 'Portfolio error',
                    text: response.data.message,
                });
                //console.log(error.response.data.message);
            }
        }
        catch (error) {
            dispatch(setError(error.message)); // Dispatch action to store error
        }
        finally {
            dispatch(setLoading(false)); // Set loading state to false (optional)
        }
    }
}

/********************************* Cancel Open Order *********************************/
/**
 * Cancel an open order
 */
export const cancelOpenOrder = (userId, orderId) => (dispatch) => {

    try {
        dispatch(setLoading(true)); // Set loading state to true

        smpAPI.cancelOpenOrder(userId, orderId).then((response) => {
                
                //Check to see if there are any Hubs
                if (response.status === 200) {
                    
                    dispatch(setUser(response.data.user)); // Dispatch action to set current user
                }
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Cancel Order error',
                    text: error.response.data.message,
                });
                //console.log(error.response.data.message);
            }
        );
    }
    catch (error) {
        dispatch(setError(error.message)); // Dispatch action to store error
    }
    finally {
        dispatch(setLoading(false)); // Set loading state to false (optional)
    }
}

/********************************* Cancel Open Order *********************************/
/**
 * Cancel an open order
 */
export const cancelIPOOrder = (userId, businessId) => (dispatch) => {

    try {
        dispatch(setLoading(true)); // Set loading state to true

        smpAPI.cancelIPOOrder(businessId, userId).then((response) => {
                
                //Check to see if there are any Hubs
                if (response.status === 200) {
                    
                    dispatch(setUser(response.data.user)); // Dispatch action to set current user
                }
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Cancel IPO Order error',
                    text: error.response.data.message,
                });
                //console.log(error.response.data.message);
            }
        );
    }
    catch (error) {
        dispatch(setError(error.message)); // Dispatch action to store error
    }
    finally {
        dispatch(setLoading(false)); // Set loading state to false (optional)
    }
}

/********************************* Cancel Event Attendance *********************************/
/**
 * Cancel an open order
 */
export const cancelAttendance = (userId, eventId) => (dispatch) => {

    try {
        dispatch(setLoading(true)); // Set loading state to true

        smpAPI.cancelAttendance(userId, eventId).then((response) => {
                
                //Check to see if there are any Hubs
                if (response.status === 200) {
                    
                    dispatch(setUser(response.data.user)); // Dispatch action to set current user
                }
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Cancel RSVP error',
                    text: error.response.data.message,
                });
                //console.log(error.response.data.message);
            }
        );
    }
    catch (error) {
        dispatch(setError(error.message)); // Dispatch action to store error
    }
    finally {
        dispatch(setLoading(false)); // Set loading state to false (optional)
    }
}

/** 
 * Sets the current user redux object
 */
export const setCurrentUser = (user) => (dispatch) => {

    try {
        dispatch(setLoading(true)); // Set loading state to true

        dispatch(setUser(user)); // Dispatch action to set current user

    } catch (error) {
        dispatch(setError(error.message)); // Dispatch action to store error
    }
    finally {
        dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};

/**
 * User confirms Event Attendance
 */
export const confirmAttendance = (userId, businessId) => (dispatch) => {

    try {
        dispatch(setLoading(true)); // Set loading state to true

        smpAPI.confirmAttendance(userId, businessId).then((response) => {
                
                //Check to see if there are any Hubs
                if (response.status === 200) {

                    if (response.data.message === "Event Confirmed") {
                        dispatch(setUser(response.data.user)); // Dispatch action to set current user

                        if (response.status === 200) {
                            Swal.fire({
                                title: 'Success',
                                text: 'You have confirmed your attendance to the IPO Launch Event',
                                icon: 'success',
                            });
                        } 
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'You have already confirmed this event.',
                            text: response.data.message,
                        });
                    }
                }
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Confirm RSVP error',
                    text: error.response.data.message,
                });
                //console.log(error.response.data.message);
            }
        );
    }
    catch (error) {
        dispatch(setError(error.message)); // Dispatch action to store error
    }
    finally {
        dispatch(setLoading(false)); // Set loading state to false (optional)
    }
}

/**
 * Cancel a Notification
 */
export const cancelNotification = (notificationId) => (dispatch) => {

    try {
        dispatch(setLoading(true)); // Set loading state to true

        smpAPI.cancelNotification(notificationId).then((response) => {
                
                //Check to see if there are any Hubs
                if (response.status === 200) {
                    
                    dispatch(setUser(response.data.user)); // Dispatch action to set current user

                }
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Cancel Notification error',
                    text: error.response.data.message,
                });
                //console.log(error.response.data.message);
            }
        );
    }
    catch (error) {
        dispatch(setError(error.message)); // Dispatch action to store error
    }
    finally {
        dispatch(setLoading(false)); // Set loading state to false (optional)
    }
}
  