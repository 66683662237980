import * as React from 'react';
import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';

import '../../../css/dashBoard.css';
import '../../../css/menu.css';

//Icons
import { VscGraphLine } from "react-icons/vsc";
import { LiaStoreSolid } from "react-icons/lia";
import { HiGlobeAmericas } from "react-icons/hi2";
import { MdAdminPanelSettings } from "react-icons/md";
import { BiQrScan } from "react-icons/bi";
import { IoQrCodeOutline } from "react-icons/io5";
import { GrSupport } from "react-icons/gr";

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

//Mobile?
import {isMobile} from 'react-device-detect';

function Menu() {

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    //Hamburger Menu
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    if (isMobile) {

        return (
            <div className="dashboard-sidebar">
                <button className="hamburger-menu" onClick={toggleMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </button>
                {isMenuOpen && (
                    <div className="dashboard-sidebar-menu is-open">
                        { currentUser.role === 'admin' ?
                            <>
                                <div className="dashboard-sidebar-menu-item">
                                    <Link to="/admin">
                                        <span>
                                            <MdAdminPanelSettings/>
                                        </span>
                                        Admin
                                    </Link>
                                </div> 
                                <hr className='hr-style'/>
                            </> : null
                        }
                        <div className="dashboard-sidebar-menu-item">
                            <Link to="/login">
                                <span>
                                    <VscGraphLine style={{ strokeWidth: "1.5"}}/>
                                </span>
                                Portfolio
                            </Link>
                        </div>
                        <hr className='hr-style'/>
                        <div className="dashboard-sidebar-menu-item">
                            <Link to="/QRList">
                                <span>
                                <IoQrCodeOutline style={{ strokeWidth: "1.5"}}/>
                                </span>
                                QR Codes
                            </Link>
                        </div>
                        <hr className='hr-style'/>
                        { currentUser.ownership ?
                            <>
                                <div className="dashboard-sidebar-menu-item">
                                    <Link to="/market">
                                        <span>
                                            <HiGlobeAmericas style={{ strokeWidth: "1.5"}}/>
                                        </span>
                                        My Business
                                    </Link>
                                </div> 
                                <hr className='hr-style'/>
                                <div className="dashboard-sidebar-menu-item">
                                    <Link to="/QRScanner">
                                        <span>
                                            <BiQrScan style={{ strokeWidth: "1.5"}}/>
                                        </span>
                                        Scan QR
                                    </Link>
                                </div>
                                <hr className='hr-style'/>
                            </>
                            : null
                        }
                        <div className="dashboard-sidebar-menu-item">
                            <Link to="/businessSearch">
                                <span>
                                    <LiaStoreSolid style={{ strokeWidth: "1.5"}}/>
                                </span>
                                Businesses
                            </Link>
                        </div>
                        <hr className='hr-style'/>
                        <div className="dashboard-sidebar-menu-item">
                            <a href="mailto:ptang@co-owners.ca">
                                <span>
                                    <GrSupport style={{ strokeWidth: "1.5"}}/>
                                </span>
                                Support
                            </a>
                        </div>
                    </div>
                )}
                {/* rest of your component */}
            </div>
        );
    }
    else {
        return (
            <div className="dashboard-sidebar">
                <div className="dashboard-sidebar-user">
                    <img className="dashboard-sidebar-user-avatar" src={currentUser.profileImage} />
                    <div className="dashboard-sidebar-user-name">{currentUser.name}</div>
                </div>
                <div className="dashboard-sidebar-menu">
                    { currentUser.role === 'admin' ?
                        <div className="dashboard-sidebar-menu-item">
                            <Link to="/admin">
                                <span>
                                    <MdAdminPanelSettings/>
                                </span>
                                Admin
                            </Link>
                        </div> : null
                    }
                    <div className="dashboard-sidebar-menu-item">
                        <Link to="/login">
                            <span>
                                <VscGraphLine style={{ strokeWidth: "1.5"}}/>
                            </span>
                            Portfolio
                        </Link>
                    </div>
                    <div className="dashboard-sidebar-menu-item">
                        <Link to="/QRList">
                            <span>
                            <IoQrCodeOutline style={{ strokeWidth: "1.5"}}/>
                            </span>
                            QR Codes
                        </Link>
                    </div>
                    { currentUser.ownership ?
                        <>
                            <div className="dashboard-sidebar-menu-item">
                                <Link to="/market">
                                    <span>
                                        <HiGlobeAmericas style={{ strokeWidth: "1.5"}}/>
                                    </span>
                                    My Business
                                </Link>
                            </div> 
                            <div className="dashboard-sidebar-menu-item">
                                <Link to="/QRScanner">
                                    <span>
                                        <BiQrScan style={{ strokeWidth: "1.5"}}/>
                                    </span>
                                    Scan QR
                                </Link>
                            </div>
                        </>
                        : null
                    }
                    <div className="dashboard-sidebar-menu-item">
                        <Link to="/businessSearch">
                            <span>
                                <LiaStoreSolid style={{ strokeWidth: "1.5"}}/>
                            </span>
                            Businesses
                        </Link>
                    </div>
                    <div className="dashboard-sidebar-menu-item">
                        <a href="mailto:ptang@co-owners.ca">
                            <span>
                                <GrSupport style={{ strokeWidth: "1.5"}}/>
                            </span>
                            Support
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Menu;