import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

//MUI
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/core/styles';

//API
import { smpAPI } from '../../api/smpAPI';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//Stripe
import {loadStripe} from '@stripe/stripe-js';

//Styling
import '../../css/paymentModal.css';

//isMobile
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles({
    root: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
        width: isMobile ? '100%' : '25vw',
        color: 'white',
      },
      '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
      },
      '& .MuiInputBase-input': {
        color: 'white', // text color
      },
      '& .MuiInput-underline:before': {
        borderBottom: '1px solid white', // underline color when not focused
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: '2px solid white', // underline color when hovered
      },
      '& .MuiInput-underline:after': {
        borderBottom: '2px solid white', // underline color when focused
      },
      '& .MuiInputBase-root': {
        '&::placeholder': {
          color: 'lightgray', // placeholder color
        },
      },
      '& .MuiInputLabel-root': {
        color: 'white', // label color
      },
    },
});

function PaymentModal ({show, onHide}) {
  const [amount, setAmount] = useState(0);
  const [processingFee, setProcessingFee] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);

  const classes = useStyles();

  const navigate = useNavigate();

  //Redux Object
  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    // Calculate processing fee as 2.9% + 30 cents
    const fee = (amount * 0.029) + 0.30;
    setProcessingFee(fee.toFixed(2));

    // Calculate final amount
    const final = amount - fee;
    setFinalAmount(final.toFixed(2));
  }, [amount]);

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  //Cancel Button
  const handleCancel = () => {
    onHide(); // close the modal
    navigate('/'); // redirect to root
  };

  //Submit Payment
  const submitPayment = async (event) => {

    event.preventDefault();

    if ((amount) && (amount > 0)) {

      const stripe = await loadStripe('pk_live_yZaLt5pmQJvvShB5fZKSf01q');

      const response = await smpAPI.sendPayment(currentUser._id, amount);

      if (response.status === 200) {
          const session = response.data;

          const result = await stripe.redirectToCheckout({
              sessionId: session.id,
          });
      } else {
          console.log('Error making payment');
      }
    }
  }

  return (
    <Modal isOpen={show} onRequestClose={onHide} className="modalContent">  
        <div className='modal-title'>
            Enter Deposit Amount
        </div>
        <form className="form-modalPanel" onSubmit={submitPayment}>
            <div className='depositBox'>
                <TextField
                    className={classes.root}
                    id="depositAmount"
                    label="$ Deposit Amount"
                    type="number" // restrict input to numbers only
                    onChange={handleAmountChange}
                />
            </div>
          <div className="modal-label">
            <span>Processing Fee: </span>
            <span className="processingFeeValue">$ {processingFee}</span>
          </div>
  
          <div className="modal-label">
            <span>Deposited Amount: </span>
            <span className="finalAmountValue">$ {finalAmount}</span>
          </div>
          <div className="buttonWrapper">
            <button type="submit" onClick={submitPayment} className="depositButton">Deposit</button>
            <button onClick={handleCancel} className="cancelButton">Cancel</button>
          </div>
      </form>
    </Modal>
  );

};

export default PaymentModal;