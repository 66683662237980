import * as React from 'react';
import { useState, useEffect, useRef } from "react";

import '../../../css/businessMarket.css';
import '../../../css/settings.css';

//Navigate
import { useNavigate } from 'react-router-dom';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { tokenLoginUser, updateUser } from '../../../redux/userActions'; // Import actions

//Menu Component
import Menu from '../menuComponent/menuComponent';
import Loading from '../loadingComponent/loadingComponent'
import SettingsInput from '../../businessComponent/settingsInputComponent/settingsInput';

//Icons
import { IoIosArrowDroprightCircle, IoMdPerson } from "react-icons/io";

//Images
import UploadProfile from '../../../assets/images/profileUpload.png';

//Partnership Leaderboard
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';

//SweelAlert
import Swal from 'sweetalert2';
import { GiConsoleController } from 'react-icons/gi';

function UserSettings() {

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    const navigate = useNavigate();

    //Variables
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    //Profile pic
    const [image, setImage] = useState('');
    const [preview, setPreview] = useState(null);

    //Password
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');

    var promoSettings;

    //Google Places API
    const autoCompleteRef = useRef();
    const inputRef = useRef();

    const options = {
        types: ['geocode'],
        componentRestrictions: { country: ["ca","us"] }
    };

    useEffect(() => {

        const getUser = async () => {
            const token = localStorage.getItem('AlliesAccessToken');
            if (token) {
                
                //Check the token
                dispatch(tokenLoginUser(token));
            }
            else {
                navigate('/login');
            }
        }

        //If the user is not loaded, load the user
        if (currentUser === null) {
        
            const fetchUser = async () => {
                await getUser();
            };

            fetchUser();
        } else {
            
            setPreview(currentUser.profileImage);

            setName(currentUser.name);
            setEmail(currentUser.email);
            setCity(currentUser.location.city);
            setState(currentUser.location.state);
            setCountry(currentUser.location.country);

            autoCompleteRef.current = new window.google.maps.places.Autocomplete(document.getElementById('address'), options);
            autoCompleteRef.current.addListener('place_changed', handlePlaceSelect);
        }
    }, [currentUser, autoCompleteRef.current]);

    //Navigation Title Panel
    const panelTitle = () => {
        return (
            <div className="dashboard-titlePanel">
                <span>
                    <IoIosArrowDroprightCircle />
                </span>
                <span className="dashboard-title">
                    { currentUser.name } Settings
                </span>
            </div>
        );
    }

    const handleButtonClick = (e) => {
        const logoInput = document.getElementById('logo');
        if (logoInput) {
            logoInput.click();
        }
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
        setPreview(URL.createObjectURL(e.target.files[0]));
    };

    //Update Business
    function submitChanges () {

        //Get the User ID
        const id = currentUser._id;

        if (newPassword !== confirmPassword) {
            
            Swal.fire({
                icon: 'error',
                title: 'Passwords do not match',
                text: 'Please ensure that the new password and confirm password fields match'
            });
            return;
        }
        //Check Email contains the '@' symbol
        if (email.length > 0 && !email.includes('@')) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Email',
                text: 'Please enter a valid email address'
            });
            return;
        }
        else {
            
            console.log('Updating User');
            console.log('ID: ' + currentUser._id);

            //Send details to server
            dispatch(updateUser(id, name, email, city, state, country, latitude, longitude, oldPassword, newPassword, image));
        }
    };

    const handlePlaceSelect = () => {

        const addressObject = autoCompleteRef.current.getPlace();
        const address = addressObject.address_components;

        if (address) {

            //console.log(addressObject.formatted_address);
            //Set the address
            setAddress(addressObject.formatted_address);

            //Get the city and state
            for (let i = 0; i < address.length; i++) {
                if (address[i].types.includes('locality')) {
                    setCity(address[i].long_name);
                }
                if (address[i].types.includes('administrative_area_level_1')) {
                    setState(address[i].long_name);
                }
                if (address[i].types.includes('country')) {
                    setCountry(address[i].long_name);
                }
            }

            setLatitude(addressObject.geometry.location.lat());
            setLongitude(addressObject.geometry.location.lng());
        }
    };

    //Settings Panel
    function Settings () {

        return (
            <div className="settings-panel">
                {/* Get Avatar */}
                <div className='logo-panel'>
                    <input
                        type="file"
                        id="logo"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{display: 'none'}} // Hide the default file input
                    />
                
                    {preview ? 
                            <div style={{cursor: 'pointer'}} className='logo-layout' onClick={handleButtonClick}>
                                <img src={preview} alt="preview" className="profileImage" />
                                <div className="logoButtonWrapper">
                                    <Button className="profileTextButton">Update Avatar</Button>
                                </div>
                            </div>
                        :   <div style={{cursor: 'pointer'}} className='logo-layout' onClick={handleButtonClick}>
                                <img className="profileImage" src={UploadProfile} /> 
                                <div className="logoButtonWrapper">
                                    <Button className="profileTextButton">Update Avatar</Button>
                                </div>
                            </div>
                    }  
                </div>

                {/* Name */}
                <div className='settingsInputBox'>
                    <SettingsInput name="name" label="Name" value={name} setProperty={setName} />
                </div>

                 {/* Email */}
                 <div className='settingsInputBox'>
                    <SettingsInput name="email" label="Email" value={email} setProperty={setEmail} />
                </div>

                {/* Address */}
                <div className='settingsInputBox'>
                    <TextField
                        id='address'
                        label="My Updated Address"
                        color='secondary'
                        className='input'
                        inputRef={inputRef}
                        defaultValue={address}
                    />
                </div>

                {/* City */}
                <div className='settingsInputBox'>
                    <SettingsInput name="city" label="City" value={city} setProperty={setCity} />
                </div>

                {/* State */}
                <div className='settingsInputBox'>
                    <SettingsInput name="state" label="State" value={state} setProperty={setState} />
                </div>

                {/* Country */}
                <div className='settingsInputBox'>
                    <SettingsInput name="country" label="Country" value={country} setProperty={setCountry} />
                </div>

                <hr className='hr-style' />

                {/* Current Number of Employees */}
                <div className='settingsInputBox'>
                    <SettingsInput name="old_password" label="Current Password" value={oldPassword} setProperty={setOldPassword} setPassword="true"/>
                </div>

                {/* Promo Settings */}
                <div className='settingsInputBox'>
                    <SettingsInput name="new_password" label="New Password" value={newPassword} setProperty={setNewPassword} setPassword="true"/>
                </div>

                {/* Promo Settings*/} 
                <div className='settingsInputBox'>
                    <SettingsInput name="confirm_password" label="Confirm New Password" value={confirmPassword} setProperty={setConfirmPassword} setPassword="true"/>
                </div>
                <div className='settingsButtonPanel'>
                    <Button variant="contained" className="settingsButton" onClick={submitChanges}>Update Profile</Button>
                </div>
            </div>
        );
    }

    //Panel to display content depending on if the user prefers the IPO Panel or Dashboard Panel
    function ContentPanel() {

        return (
            <div className="dashboard">
                <Menu />
                <Content />
            </div>
        );
    }

    //Content Panels
    function Content() {

        return (
            <div className="dashboard-content">
                { panelTitle() }
                <div className="dashboard-content-panel">
                    { Settings() }
                </div>
               
            </div>            
        );
      }

    if (currentUser !==null && autoCompleteRef.current !== null) {

        return (
            <div>
                <ContentPanel />
            </div>
        );
    }
    else {
        return (
            <Loading title="Loading User Settings" />
        )
    }
}

export default UserSettings;