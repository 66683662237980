import './App.css';

//Login 
import Login from './components/loginComponent/loginComponent';

//Register
import Register from './components/registerComponent/registerComponent';

//Forgot Password
import ForgotPassword from './components/loginComponent/forgotPasswordComponent';

//Forgot Password
import ResetPassword from './components/loginComponent/resetPasswordComponent';

//Client Portal
import Onboard from './components/userComponent/onboardComponent/onboardComponent';

//User Settings
import UserSettings from './components/userComponent/marketComponents/userSettingsComponent';

//Client Dashboard
import Portfolio from './components/userComponent/marketComponents/portfolioComponent';

//Camera for Business Owner
import WebcamScanner from './components/businessComponent/webcamScannerComponent';

//Search Businesses
import SearchBusinesses from './components/userComponent/searchBusinessComponent/searchBusinessComponent';

//Business Stock Page
import Business from './components/userComponent/businessComponent/businessComponent';

//QR Code List
import QRCodeList from './components/userComponent/marketComponents/qrCodeListComponent';

/************** Business Routes */
//Business Portal
import Market from './components/businessComponent/marketComponent';

//Scan QR Code
import ScanQR from './components/scanComponent/scanQRComponent';

//Revoke Partners
import RevokeShares from './components/businessComponent/revokeSharesComponent';

//Business Settings
import BusinessSettings from './components/businessComponent/businessSettingsComponent';

//Managers
import Managers from './components/businessComponent/managersComponent';

/************** Admin Routes */
//Admin Panel
import AdminPanel from './components/adminComponent/adminComponent';

//Business Onboard
import BOnboard from './components/adminComponent/onboardComponent';

//Approve Monthly Returns
import ApproveMonthly from './components/adminComponent/approveMonthlyComponent';

//Set IPO
import SetIPO from './components/adminComponent/setIPOComponent';

//Routing for Reactjst
import { Route, Routes } from 'react-router-dom';
import { useParams } from 'react-router-dom';

//Redux
import { Provider } from 'react-redux';
import store from './redux/store'; // Import your store

//Google Analytics
import ReactGA from 'react-ga4';

const ResetPasswordRoute = () => {
  const { token } = useParams(); // Get the token parameter from the URL

  // You can pass the token to your ResetPassword component or perform any other logic here
  return <ResetPassword token={token} />;
};

/*** Business Panel **/
const BusinessPanel = () => {
  const { bid } = useParams(); // Get the business ID parameter from the URL
  const { modalHightlight } = useParams(); // Get the modal highlight parameter from the URL
  const { notifyType } = useParams(); // Get the notify type parameter from the URL

  if (modalHightlight === undefined) {
    return <Business bid={bid} />;
  }
  else if ((notifyType === undefined) && (modalHightlight !== undefined)) {
    if (modalHightlight === "buy") {
      return <Business bid={bid} modalType={modalHightlight} />;
    }
    else if (modalHightlight === "event") {
      return <Business bid={bid} modalType={modalHightlight} />;
    }
    else {
      return <Business bid={bid} notify={modalHightlight} />;
    }
  }
  else {
    return <Business bid={bid} modalType={modalHightlight} notify={notifyType}/>;
  }
}

/** Portfolio Panel with Modal Open */
const PortfolioModalPanel = () => {
  const { nid } = useParams(); // Get the Notificaiton ID parameter from the URL

  return <Portfolio modal={nid} />;
}

/** Launch Deposit */
const Deposit = () => {
  return <Portfolio modal="deposit" />;
}

/** Successful Deposit */
const DepositSuccess = () => {
  return <Portfolio modal="success" />;
}

/** Failed Deposit */
const DepositFailed = () => {
  return <Portfolio modal="failed" />;
}

/** Portfolio Panel with just notification */
const PortfolioNotification = () => {
  const { nid } = useParams(); // Get the Notificaiton ID parameter from the URL

  return <Portfolio notify={nid} />;
}

/** Scanning QR Code **/
const QRScan = () => {
  const { businessID } = useParams(); // Get the business ID parameter from the URL
  const { partnerID } = useParams(); // Get the partner ID parameter from the URL

  return <ScanQR bid={businessID} pid={partnerID} />;
}

const App = () => {

    ReactGA.initialize('G-LTLPKV0NVV');

    return (
        <>
          <Provider store={store}>
            <Routes>
              <Route path="/" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route path="/reset-password/:token" element={<ResetPasswordRoute />} />
              <Route path="/onboard" element={<Onboard />} />
              <Route path="/userSettings" element={<UserSettings />} />
              <Route path="/dashboard" element={<Portfolio />} />
              <Route path="/deposit/:nid" element={<PortfolioModalPanel />} />
              <Route path="/deposit/" element={<Deposit />} />
              <Route path="/depositsuccess" element={<DepositSuccess />} />
              <Route path="/depositfailed" element={<DepositFailed />} />
              <Route path="/notification/:nid" element={<PortfolioNotification />} />
              
              {/** Business Routes **/}
              <Route path="/market" element={<Market />} />
              <Route path="/QRScanner" element={<WebcamScanner />} />
              <Route path="/repurchaseShares" element={<RevokeShares />} />
              <Route path="/businessSettings" element={<BusinessSettings />} />
              <Route path="/managers" element={<Managers />} />

              <Route path="/businessSearch" element={<SearchBusinesses />} />
              <Route path="/stock/:bid/:modalHightlight/:notifyType" element={<BusinessPanel />} />
              <Route path="/stock/:bid/:modalHightlight/" element={<BusinessPanel />} />
              <Route path="/stock/:bid" element={<BusinessPanel />} />
              <Route path="/scanQR/:businessID/:partnerID" element={<QRScan />} />
              <Route path="/QRList" element={<QRCodeList />} />

              {/** Admin Routes **/}
              <Route path="/admin" element={<AdminPanel />} />
              <Route path="/businessOnboard" element={<BOnboard />} />
              <Route path="/approveMonthly" element={<ApproveMonthly />} />
              <Route path="/setIPO" element={<SetIPO />} />
              <Route path="*" element={<Register />} />
            </Routes>
          </Provider>
        </>
    );
}

export default App;
