import { DataGrid } from '@mui/x-data-grid';

import '../../../css/dashBoard.css'

function activeOrders (props) {

    const orders = props.transactions;
    const columnName = props.columnName;

    const columns = [
        { field: 'price', headerName: columnName, width: 100 },
    ];
    
    const rows = orders.map((order) => {

        return {
            id: order.id,
            price: '$' + order.amount.toFixed(2)
        }
    });
      

    return <DataGrid 
                columns={columns} 
                rows={rows}
                pageSizeOptions={[10]}
                sx={{
                    border: 0,
                    color: 'white',
                    fontWeight: 'bold',
                    '&, [class^=MuiDataGrid]': { border: 'none' },
                }} 
                localeText={{noRowsLabel: 'No Orders'}} // Change "No rows" to "No notifications"
           />;
}

export default activeOrders;