import React, { useState } from 'react';

import axios from 'axios';

//Styling
import '../../../css/search.css';

//Icons
import { IoIosArrowDroprightCircle } from "react-icons/io";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FaSearch } from "react-icons/fa";

const KeyCodes = {
    enter: 13
};

const SearchBar = (props) => {

    const coordinates = props.coordinates;

    const [searchValue, setSearchValue] = useState('');

    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleChange = (event) => {
        setSearchValue(event.target.value);
    };

    const executeSearch = async () => {

        if (searchValue === '') {
            coordinates('');
        }
        else {
            const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    address: searchValue,
                    key: 'AIzaSyCn_0w1J9f-e1m7YKb59DhsRIftV05XU7A'
                }
            });
        
            const location = response.data.results[0].geometry.location;

            coordinates(location);
        }
        //console.log(location); // { lat: ..., lng: ... }
    }

    return (
        <div className='dashboard-search'>
            <TextField
                placeholder="Search City and Country (ex. New York, USA)"
                fullWidth
                value={searchValue}
                onChange={handleChange}
                onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      executeSearch();
                      ev.preventDefault();
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <FaSearch onClick={executeSearch} style={{cursor: 'pointer', width: '1.5rem'}}/>
                        </InputAdornment>
                    ),
                    style: {color: 'black', fontSize: '1.5rem', fontWeight: 'bold' }
                }}
            />
        </div>
    );
}

export default SearchBar;