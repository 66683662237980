import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

import '../../../css/dashBoard.css'

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { cancelIPOOrder } from '../../../redux/userActions'; // Import actions

//SweetAlert
import Swal from 'sweetalert2';

const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
        },
    },
});

function IPOStatus (props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    const ipos = props.ipo;

    const columns = [
        { field: 'name', headerName: 'Company', width: 150 },
        { field: 'status', headerName: 'Status', width: 150},
        { 
            field: 'date', 
            headerName: 'Date', 
            width: '150',  
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            ),
        },
    ];
    
    const rows = ipos.map((ipo) => {

        //switch statement to determine the status of the IPO
        let status = '';
        switch (ipo.status) {
            case 'pending':
                status = 'Pending';
                break;
            case 'accepted':
                status = 'Approved';
                break;
            case 'rejected':
                status = 'Rejected';
                break;
            default:
                status = 'Revoked';
        }

        return {
            id: ipo.businessId,
            name: ipo.businessName,
            status: status,
            date: new Date(ipo.offerDate).toLocaleString()
        }
    });
      

    //Handle Cancel IPO Request
    const handleCancelIPORequest = (ipo) => {

        //Check to see if the ipo.status is pending, otherwise we cannot do anything
        if (ipo.row.status === 'Approved') {
            Swal.fire({
                icon: 'error',
                title: 'Already Approved',
                text: 'You can not cancel this IPO request, consider selling the share instead.',
            });
            return;
        }
        else if (ipo.row.status === 'Revoked') {
            Swal.fire({
                icon: 'error',
                title: 'No longer available',
                text: 'You can no cancel this IPO request, the owner has revoked the offer',
            });
            return;
        }
        else if (ipo.row.status === 'Pending') {

            //Double check with the user
            Swal.fire({
                title: 'Are you sure?',
                text: 'You are about to cancel your IPO request.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(cancelIPOOrder(currentUser._id, ipo.id));

                    Swal.fire({
                        icon: 'success',
                        title: 'IPO Withdrawn',
                        text: 'You have withdrawn your IPO request.',
                    });
                }
            });
            return;
        }
    };

    return <DataGrid 
                className={classes.root}
                columns={columns} 
                rows={rows}
                pageSizeOptions={[5]}
                onRowClick={(row) => { handleCancelIPORequest(row) }}
                sx={{
                    border: 0,
                    color: 'white',
                    fontWeight: 'bold',
                    '&, [class^=MuiDataGrid]': { border: 'none' },
                }} 
                localeText={{noRowsLabel: 'No Events'}} // Change "No rows" to "No notifications"
           />;
}

export default IPOStatus;