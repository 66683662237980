import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import { Tooltip } from '@mui/material';

//SMP API
import { smpAPI } from '../../../api/smpAPI';

//Sweet Alert
import Swal from 'sweetalert2';

//Stripe
import { loadStripe } from '@stripe/stripe-js';

//Redux
import { useSelector } from 'react-redux';

import '../../../css/dashBoard.css'

const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnsContainer': {
            display: 'none !important',
        },
    },
});

function NotificationTable (props) {

    const navigate = useNavigate();
    const classes = useStyles();

    //Redux
    const currentUser = useSelector((state) => state.user.currentUser);

    const notifications = props.data;

    const columns = [
        { 
            field: 'content', 
            headerName: 'Content', 
            flex: 1,
            renderHeader: () => <div style={{ display: 'none' }} />, // Empty header cell 
            cellClassName: 'content-cell', // Apply custom class
            renderCell: (params) => (
                <Tooltip title={params.value}>
                  <div style={{ overflowX: 'auto' }}>{params.value}</div>
                </Tooltip>
            ),
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 0
        },
        {
            field: 'businessId',
            headerName: 'Business ID',
            width: 0
        }
    ];

    const rows = notifications.map((notification) => {

        return {
            id: notification.id,
            content: notification.content,
            type: notification.type,
            businessId: notification.businessId
        }
    });
      
    //Stripe
    const makeStripePayment = async(row) => {

        //Use SweetAlert to allow user to input amount
        const { value: amount } = await Swal.fire({
            title: 'Enter Amount to Deposit',
            input: 'text',
            inputLabel: 'Amount in CAD($)',
            inputPlaceholder: '$ Enter amount to deposit'
        });

        if ((amount) && (amount > 0)) {

            const stripe = await loadStripe('pk_live_yZaLt5pmQJvvShB5fZKSf01q');

            console.log(row);

            const response = await smpAPI.sendPayment(currentUser._id, amount, row.id);

            if (response.status === 200) {
                const session = response.data;

                const result = await stripe.redirectToCheckout({
                    sessionId: session.id,
                });

                alert(result);

            } else {
                console.log('Error making payment');
            }
        }
    }

    return (
        <DataGrid 
                className={classes.root}
                columns={columns}
                rows={rows}
                pageSizeOptions={[10]}
                onRowClick={(row) => { 
                    if(row.row.type === 'IPO') {
                        navigate(`/stock/${row.row.businessId}/IPO`);
                    }
                    else if(row.row.type === 'Sold_Share'){
                        navigate(`/stock/${row.row.businessId}/buy/Sold_Share`);
                    }
                    else if(row.row.type === 'Bought_Share') {
                        navigate(`/stock/${row.row.businessId}/buy/Bought_Share`);
                    }
                    else if(row.row.type === 'BuyInvite') {
                        navigate(`/stock/${row.row.businessId}/buy/BuyInvite`);
                    }
                    //Direct to Stripe page to refill funds
                    else if(row.row.type === 'BuyOrder_NSF' || row.row.type === 'IPO_NSF') {
                        //makeStripePayment(row.row);
                        navigate(`/deposit/${row.row.id}`);
                    }
                    else if(row.row.type === 'EventInvite') {
                        navigate(`/stock/${row.row.businessId}/event/EventInvite`);
                    }
                    else if (row.row.type === 'Dividend_NSF') {
                        navigate(`/deposit/${row.row.id}`);
                    }
                    else if (row.row.type === 'Dividend') {
                        navigate(`/notification/${row.row.id}`);
                    }
                    else {
                        console.log("Error: Invalid type");
                    }
                }}
                columnVisibilityModel={{
                    // Hide columns type
                    type: false,
                    businessId: false
                  }}
                sx={{
                    border: 0,
                    color: 'white',
                    fontWeight: 'bold',
                    '&, [class^=MuiDataGrid]': { border: 'none' },
                }} 
                localeText={{noRowsLabel: 'No Notifications'}} // Change "No rows" to "No notifications"
        />
    );
}

export default NotificationTable;