import { api } from "./config/axiosConfig"

export const smpAPI = {
  login: async function (email, password) {

    const response = await api.request({
      url: `/auth/login`,
      method: "POST",
      data: {
        email: email,
        password: password,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  register: async function (access_token, name, email, password, image) {

    const response = await api.request({
      url: `/auth/register`,
      method: "POST",
      data: {
        access_token: access_token,
        name: name,
        email: email,
        password: password,
        profileImage: image
      },
    })
    
    // returning the product returned by the API
    return response
  },

  // Persistent Login - checking user token
  checklogintoken: async function (token) {

    const response = await api.request({
      url: `/auth/check-token`,
      method: "POST",
      data: {
        token: token,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  // User Login - via Google
  checkGoogleLogin: async function (email) {

    const response = await api.request({
      url: `/auth/check-login`,
      method: "POST",
      data: {
        email: email,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  resetPassword: async function (token, password) {

    const response = await api.request({
      url: `/auth/reset-password`,
      method: "POST",
      params: {
        token: token,
      },
      data: {
        password: password,
      },
    })

    // returning the product returned by the API
    return response
  },

  forgotPassword: async function (email) {

    const response = await api.request({
      url: `/auth/forgot-password`,
      method: "POST",
      data: {
        email: email,
      },
    })

    // returning the product returned by the API
    return response
  },

  //User Details           
  onboard: async function (id, name, password, image, city, state, country, latitude, longitude, interests) {

    const response = await api.request({
      url: `/users/onboardUser`,
      method: "POST",
      data: {
        id: id,
        name: name,
        password: password,
        profileImage: image,
        city: city,
        state: state,
        country: country,
        latitude: latitude,
        longitude: longitude,
        interests: interests
      },
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    })

    // returning the product returned by the API
    return response
  },

  /****************************** Business *********************************/
  createBusiness: async function (ownerId, name, image, address, city, state, country, latitude, longitude, website, description, story, category, numEmployees, revenue, expenses) {

    const response = await api.request({
      url: `/business/createBusiness`,
      method: "POST",
      data: {
        ownerId: ownerId,
        name: name,
        logo: image,
        address: address,
        city: city,
        state: state,
        country: country,
        latitude: latitude,
        longitude: longitude,
        website: website,
        description: description,
        story: story,
        category: category,
        numEmployees: numEmployees,
        revenue: revenue,
        expenses: expenses,
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    // returning the product returned by the API
    return response
  },

  //Business Owner Details
  getBusiness: async function (businessId) {

    const response = await api.request({
      url: `/business/getBusiness`,
      method: "GET",
      params: {
        businessId: businessId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  //Investor Details
  getBusinessForInvestor: async function (businessId, userId, notify) {

    const response = await api.request({
      url: `/business/getBusinessForInvestor`,
      method: "GET",
      params: {
        businessId: businessId,
        userId: userId,
        notify: notify
      },
    })

    // returning the product returned by the API
    return response
  },

  /****************************** Settings *********************************/
  getBusinessCategories: async function () {
      
    const response = await api.request({
      url: `/settings/getCategories`,
      method: "GET",
    })
    
    // returning the product returned by the API
    return response
  },

  uploadBusinessMonthEnd: async function (businessId, grossRevenue, expenses, files) {

    const formData = new FormData();
    formData.append('businessId', businessId);
    formData.append('grossRevenue', grossRevenue);
    formData.append('expenses', expenses);
    
    for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i].file);
    }

    const response = await api.request({
      url: `/business/uploadMonthEnd`,
      method: "POST",
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    // returning the product returned by the API
    return response
  },

  /**************************** IPO ******************************/
  getIPODetails: async function (businessId) {

    const response = await api.request({
      url: `/business/getIPODetails`,
      method: "GET",
      params: {
        businessId: businessId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  approveIPOPartner: async function (businessId, partnerId) {

    const response = await api.request({
      url: `/business/approveIPOPartner`,
      method: "POST",
      data: {
        businessId: businessId,
        partnerId: partnerId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  denyIPOPartner: async function (businessId, partnerId) {

    const response = await api.request({
      url: `/business/denyIPOPartner`,
      method: "POST",
      data: {
        businessId: businessId,
        partnerId: partnerId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  revokeIPOPartner: async function (businessId, partnerId) {

    const response = await api.request({
      url: `/business/revokeIPOPartner`,
      method: "POST",
      data: {
        businessId: businessId,
        partnerId: partnerId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  sellIPOShares: async function (businessId, shares) {

    const response = await api.request({
      url: `/business/sellIPO`,
      method: "POST",
      data: {
        businessId: businessId,
        publicShares: shares
      },
    })
    
    // returning the product returned by the API
    return response
  },
  /************************ Business Search ****************/
  getLocalBusinesses: async function (userId) {

    const response = await api.request({
      url: `/business/getLocalBusinesses`,
      method: "GET",
      params: {
        userId: userId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  getLocalBusinessesByCoords: async function (latitude, longitude) {
      
    const response = await api.request({
      url: `/business/getLocalBusinessesByCoords`,
      method: "GET",
      params: {
        latitude: latitude,
        longitude: longitude,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  /********************** Trades ********************/
  buyShares: async function (businessId, userId, price) {

    const response = await api.request({
      url: `/trade/buyShare`,
      method: "POST",
      data: {
        businessId: businessId,
        userId: userId,
        price: price
      },
    })
    
    // returning the product returned by the API
    return response
  },

  sellShares: async function (businessId, userId, price) {

    const response = await api.request({
      url: `/trade/sellShare`,
      method: "POST",
      data: {
        businessId: businessId,
        userId: userId,
        price: price
      },
    })
    
    // returning the product returned by the API
    return response
  },

  /****************** Cancelling Actions **************/
  cancelOpenOrder: async function (userId, orderId) {

    const response = await api.request({
      url: `/trade/cancelOpenOrder`,
      method: "POST",
      data: {
        userId: userId,
        orderId: orderId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  cancelIPOOrder: async function (businessId, userId) {

    const response = await api.request({
      url: `/trade/cancelIPOOrder`,
      method: "POST",
      data: {
        businessId: businessId,
        userId: userId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  cancelAttendance: async function (userId, eventId) {

    const response = await api.request({
      url: `/trade/cancelAttendance`,
      method: "POST",
      data: {
        userId: userId,
        eventId: eventId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  /**************** User Lookup **************/
  searchUsers: async function (searchTerm, businessId) {

    const response = await api.request({
      url: `/trade/searchUsers`,
      method: "GET",
      params: {
        search: searchTerm,
        businessId: businessId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  searchUsersByProximity: async function (businessId) {

    const response = await api.request({
      url: `/trade/searchUsersByProximity`,
      method: "POST",
      data: {
        businessId: businessId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  /**************** Notications ****************/
  sendBuyNotification: async function (businessId, userId) {

    const response = await api.request({
      url: `/notifications/sendBuyNotification`,
      method: "POST",
      data: {
        businessId: businessId,
        userId: userId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  sendEventNotification: async function (businessId, userId) {

    const response = await api.request({
      url: `/notifications/sendEventNotification`,
      method: "POST",
      data: {
        businessId: businessId,
        userId: userId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  cancelNotification: async function (notificationId) {

    const response = await api.request({
      url: `/notifications/cancelNotification`,
      method: "POST",
      data: {
        notificationId: notificationId,
      },
    })
    
    // returning the product returned by the API
    return response
  },
  
  /****************** Events *******************/
  confirmAttendance: async function (userId, businessId) {

    const response = await api.request({
      url: `/trade/confirmAttendance`,
      method: "POST",
      data: {
        userId: userId,
        businessId: businessId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  /**************** Payments ******************/
  sendPayment: async function (userId, amount, notificationId) {
    const response = await api.request({
      url: `/payment/sendPayment`,
      method: "POST",
      data: {
        userId: userId,
        amount: amount,
        notificationId: notificationId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  /*************** Scan QR Code **************/
  scanQR: async function (businessId, partnerId) {

    const response = await api.request({
      url: `/business/scanQRCode`,
      method: "POST",
      data: {
        businessId: businessId,
        partnerId: partnerId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  /************** Get the QR Code List from User *********/
  getQRCodeList: async function (userId) {

    const response = await api.request({
      url: `/trade/getQRCodes`,
      method: "GET",
      params: {
        userId: userId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  /********************** Add Comment ******************/
  addComment: async function (businessId, authorId, comment) {

    const response = await api.request({
      url: `/business/postComment`,
      method: "POST",
      data: {
        businessId: businessId,
        authorId: authorId,
        comment: comment,
      },
    })
    
    // returning the product returned by the API
    return response
  },
  
  /******************** Get Partnerships *****************/
  getPartnerships: async function (businessId) {

    const response = await api.request({
      url: `/business/getPartnerships`,
      method: "GET",
      params: {
        businessId: businessId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  /****************** Owner Shares Buy back **************/
  buyBackShares: async function (businessId, partnerId) {

    const response = await api.request({
      url: `/business/buyBackShares`,
      method: "POST",
      data: {
        businessId: businessId,
        partnerId: partnerId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  /***************** Update Business *******************/
  updateBusiness: async function (businessId, name, address, city, state, country, latitude, longitude, description, story, website, numEmployees, promoExpense, promoDescription, image) {
    
    const response = await api.request({
      url: `/business/updateBusiness`,
      method: "POST",
      data: {
        businessId: businessId,
        name: name,
        address: address,
        city: city,
        state: state,
        country: country,
        latitude: latitude,
        longitude: longitude,
        description: description,
        story: story,
        website: website,
        numEmployees: numEmployees,
        promoExpense: promoExpense,
        promoDescription: promoDescription,
        logo: image
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    // returning the product returned by the API
    return response
  },

  /***************** Update User *******************/
  updateUser: async function (userId, name, email, city, state, country, latitude, longitude, old_password, new_password, image) {

    const response = await api.request({
      url: `/trade/updateUser`,
      method: "POST",
      data: {
        userId: userId,
        name: name,
        email, email,
        city: city,
        state: state,
        country: country,
        latitude: latitude,
        longitude: longitude,
        old_password: old_password,
        new_password: new_password,
        profileImage: image
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    // returning the product returned by the API
    return response
  },

  /***************** Managers ********************/
  getManagers: async function (businessId) {

    const response = await api.request({
      url: `/business/getManagers`,
      method: "GET",
      params: {
        businessId: businessId,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  addManager: async function (businessId, email, role) {

    const response = await api.request({
      url: `/business/addManager`,
      method: "POST",
      data: {
        businessId: businessId,
        email: email,
        role: role
      },
    })
    
    // returning the product returned by the API
    return response
  },

  removeManager: async function (businessId, managerId) {

    const response = await api.request({
      url: `/business/removeManager`,
      method: "POST",
      data: {
        businessId: businessId,
        userId: managerId
      },
    })
    
    // returning the product returned by the API
    return response
  },

  verifyManager: async function (businessId, managerId) {
      
      console.log("businessId: " + businessId + " managerId: " + managerId);

      const response = await api.request({
        url: `/business/verifyManager`,
        method: "GET",
        params: {
          businessId: businessId,
          userId: managerId
        },
      })
      
      // returning the product returned by the API
      return response
    }
}

