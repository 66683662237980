// src/reducers/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentUser: null,
  loading: false, // Flag to indicate user data is being fetched
  error: null, // Store any error that might occur during user actions
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.currentUser = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export default userSlice.reducer;
export const { setUser, setLoading, setError } = userSlice.actions;