import * as React from 'react';
import Chart from "react-apexcharts";

/* isMobile */
import { isMobile } from 'react-device-detect';

function activationRevenue(props) {

  const partnerships = props.partnerships;
  const promoScanned = props.promoScanned;

  //Group the promoScanned by Month and Year
  const promoScannedGrouped = promoScanned.reduce((r, a) => {
    const date = new Date(a.promoDateUsed);
    const monthYearKey = `${date.toLocaleString('default', { month: 'short' })}-${date.getFullYear()}`;

    r[monthYearKey] = (r[monthYearKey] || 0) + 1; // increment count
    return r;
  }, {});

  //Group the partnerships by Month and Year
  const partnershipsGrouped = partnerships.reduce((r, a) => {
    const activeDate = new Date(a.activeDate);
    const inactiveDate = a.inactiveDate ? new Date(a.inactiveDate) : new Date();
  
    for (let date = activeDate; date.getMonth() <= inactiveDate.getMonth(); date.setMonth(date.getMonth() + 1)) {
      const monthYearKey = `${date.toLocaleString('default', { month: 'short' })}-${date.getFullYear()}`;

      r[monthYearKey] = (r[monthYearKey] || 0) + 1; // increment count
    }

    return r;
  }, {});
  
  //Get the labels for grouping together the keys from both partnershipsGrouped and promoScannedGrouped
  const labels = [...new Set([...Object.keys(promoScannedGrouped), ...Object.keys(partnershipsGrouped)])];

  //Get the data for the partnerships by on the new set of labels
  const partnershipsData = labels.map((label) => {
    return partnershipsGrouped[label] || 0;
  });

  //Get the data for the promoScanned by on the new set of labels
  const promoScannedData = labels.map((label) => {
    return promoScannedGrouped[label] || 0;
  });

  var colors = [ "#E97451", "blue", "green"];

  var options = {
      series: [{
        name: 'Visits',
        type: 'column',
        data: promoScannedData
      }, {
        name: 'Partnerships',
        type: 'line',
        data: partnershipsData
      }],
      chart: {
      height: 350,
      type: 'line',
    },
    stroke: {
      width: [0, 3]
    },
    title: {
      text: 'Growth',
      style: {
        color: '#fff',
        fontSize: '25px'
      }
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1]
    },
    colors: colors,
    //labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
    labels: labels,
    legend: {
      labels: {
        colors: '#fff'
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: true,
        style: {
            colors: '#fff',
            fontSize: '0.8rem',
            cssClass: 'apexcharts-xaxis-label',
        },
      },
    },
    yaxis: [{
      title: {
        text: 'Partnerships',
        style: {
          color: '#fff'
        }
      },
      labels: {
        show: true,
        style: {
            colors: '#fff',
            fontSize: '0.8rem',
            cssClass: 'apexcharts-xaxis-label',
        },
      },
    }, {
      opposite: true,
      title: {
        text: 'Visits',
        style: {
          color: '#fff'
        }
      },
      labels: {
        show: true,
        style: {
            colors: '#fff',
            fontSize: '0.8rem',
            cssClass: 'apexcharts-xaxis-label',
        },
      },
    }]
  };

  if (isMobile) {

    return (
      <div className="mixed-chart">
          <Chart
              options={options}
              series={options.series}
              type="line"
              width="100%"
              height={window.innerHeight / 2}
          />
      </div>
    );
  }
  else {
    return (
          <div className="mixed-chart">
              <Chart
                  options={options}
                  series={options.series}
                  type="line"
                  width="90%"
              />
          </div>
    );
  }
}

export default activationRevenue;