import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

import '../../../css/dashBoard.css'

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { cancelAttendance } from '../../../redux/userActions'; // Import actions

//SweetAlert
import Swal from 'sweetalert2';

const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
        },
    },
});

function Events (props) {

    const events = props.events;
    const classes = useStyles();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    const columns = [
        { field: 'name', headerName: 'Company', width: 150 },
        { 
            field: 'date', 
            headerName: 'Date', 
            width: '150',  
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            ),
        },
    ];
    
    const rows = events.map((event) => {

        return {
            id: event._id,
            name: event.businessName,
            date: new Date(event.eventDate).toLocaleString()
        }
    });
      

    //Handle Cancel Event attendance
    const handleCancelAttendance = (event) => {

        //Prompt a double confirmation before cancelling the attendance
        Swal.fire({
            title: 'Are you sure you want to cancel your RSVP to this event?',
            showCancelButton: true,
            confirmButtonText: `Yes`,
        }).then((result) => {
            if (result.isConfirmed) {
                
                dispatch(cancelAttendance(currentUser._id, event.id));

                Swal.fire({
                    icon: 'success',
                    title: 'RSVP cancelled',
                    text: 'You have withdrawn your RSVP to this event.',
                });
            }
        });
    };


    return <DataGrid 
                className={classes.root}
                columns={columns} 
                rows={rows}
                pageSizeOptions={[10]}
                onRowClick={(row) => { handleCancelAttendance(row) }}
                sx={{
                    border: 0,
                    color: 'white',
                    fontWeight: 'bold',
                    '&, [class^=MuiDataGrid]': { border: 'none' },
                }} 
                localeText={{noRowsLabel: 'No Events'}} // Change "No rows" to "No notifications"
           />;
}

export default Events;