import React, { useState, useEffect } from "react";
import { smpAPI } from '../../api/smpAPI';

import { useDispatch, useSelector } from 'react-redux';
import { tokenLoginUser } from '../../redux/userActions';
import { validateQRCode } from '../../redux//businessActions';


//SweetAlert
import Swal from 'sweetalert2';

//Navigation
import { useNavigate } from 'react-router-dom';

//Icons
import { IoIosArrowDroprightCircle } from "react-icons/io";

//Loading
import Loading from '../userComponent/loadingComponent/loadingComponent';

//Menu
import Menu from '../userComponent/menuComponent/menuComponent';

//Styling
import '../../css/qrcode.css';

function ScanQR(props) {

    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);
    const currentBusiness = useSelector((state) => state.business.currentBusiness);

    //Naviation 
    const navigate = useNavigate();

    const [valid, setValid] = useState(null);

    useEffect(() => {
        const getUser = async () => {
            const token = localStorage.getItem('AlliesAccessToken');

            if (token) {
                
                //Check the token
                dispatch(tokenLoginUser(token));
            }
            else {
                navigate('/login');
            }
        }

        const fetchData = async () => {
            if (!currentUser) {
                getUser();
            } else {
                const businessId = props.bid;
                const response = await smpAPI.verifyManager(businessId, currentUser.id);
    
                if (response.data.message === "Valid") {
                    setValid(true);
                    dispatch(validateQRCode(businessId, props.pid, navigate));
                } else {
                    setValid(false);
                    Swal.fire({
                        title: 'Error!',
                        text: 'You are do not a Manager for this business.',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate('/QRScanner');
                        }
                    });
                }
            }
        };
    
        fetchData();
    }, [currentUser]);
        
    //Navigation Title Panel
    const panelTitle = () => {
        return (
            <div className="dashboard-titlePanel">
                <span>
                    <IoIosArrowDroprightCircle />
                </span>
                <span className="dashboard-title">
                    Scan QR Code
                </span>
            </div>
        );
    }

    //Panel to display content of the page
    function ContentPanel() {

        return (
            <div className="dashboard">
                <Menu />
                <Content />
            </div>
        );
    }

    //Content Panels
    function Content() {
        const lastPromoScanned = currentBusiness.promoScanned[currentBusiness.promoScanned.length - 1];
        const promoPartnerName = lastPromoScanned ? lastPromoScanned.promoPartnerName : '';

        //If Valid is set from a scan and is not null
        if (valid !== null) {

            if (valid === false) {
                return (
                    <div className="dashboard-content">
                        { panelTitle() }
                        <div className="qr-message">
                            Manager not Valid
                        </div>
                    </div>             
                );
            }
            else {
                return (
                    <div className="dashboard-content">
                        { panelTitle() }
                        <div className="qr-message">
                            QR Code scanned successfully
                            <div className="promo-partner-name">
                                {promoPartnerName}
                            </div>
                            <button className="navigateButton" onClick={() => navigate('/market')}>
                                Back to Business
                            </button>
                        </div>
                    </div>            
                );
            }
        }
    }

    if ((currentUser !== null) && (currentUser !== undefined) && (currentBusiness !== null) && (currentBusiness !== undefined)) {

        return (

            <div>
                <ContentPanel />
            </div>
        );
    }
    else {
        return (
            <Loading content="Loading QR Code"/>
        );
    }
}

export default ScanQR;